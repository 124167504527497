<script>
import localSave from '@utils/local-save'
import DataLayer from '@services/data-layer/index'

const DEFAULT_ON_DEMAND_QUALITY = '96'
const DEFAULT_ON_DEMAND_PREMIUM_QUALITY = '128'

export default {
  page() {
    const title = 'Settings - Audio Quality'
    const description = 'Settings - Audio Quality'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  data() {
    return {
      isSubscribed: null,
      options: [
        {
          title: 'Normal',
          subtitle: '',
          value: '96',
          needsSub: false,
        },
        {
          title: 'High - FRISKY Premium only',
          subtitle: 'Great sound quality',
          value: '128',
          needsSub: true,
        },
        {
          title: 'Best - FRISKY Premium only',
          subtitle: 'High-Resolution audio quality',
          value: '320',
          needsSub: true,
        },
      ],
    }
  },

  computed: {
    onDemandQuality() {
      return this.$store.state.settings.onDemandQuality
    }
  },

  mounted() {
    this.validate()
  },

  methods: {
    save(event) {
      if(!this.isSubscribed) return
      this.$store.commit('settings/SET_SETTINGS', { onDemandQuality: event.target.value })

      this.$store.dispatch('appearance/handleNotification', { message: this.$m('AUDIO_QUALITY_UPDATED'), type: 'success' })
    },
    validate() {
      const auth = localSave.get('auth.currentUser')
      const token = auth && auth.token ? auth.token : ''
      DataLayer.channels.validateStream({ token: token || '', station: 'classics', mount: 'mp3_high' })
        .then(res => {
          if(res.body.allowed === true){
            const currentQuality = localSave.get('onDemandQuality') || DEFAULT_ON_DEMAND_PREMIUM_QUALITY
            this.$store.commit('settings/SET_SETTINGS', { onDemandQuality: currentQuality })

            this.isSubscribed = true
          }else{
            this.$store.commit('settings/SET_SETTINGS', { onDemandQuality: DEFAULT_ON_DEMAND_QUALITY })

            this.isSubscribed = false
          }
        })
    }
  },
}
</script>

<template>
  <BaseSection padded>
    <h6 :class="[$style.subtitle, $style.subtitleInfo]">Audio Quality</h6>
    <div :class="$style.qualitySelection">
      <div
        v-for="option of options"
        :class="[$style.quality, !isSubscribed && option.needsSub ? $style.disabled : '']"
        :key="'audioQuality' + option.value">
        <input
          :class="$style.radio"
          :id="'audioQuality' + option.value"
          :value="option.value"
          :disabled="!isSubscribed && option.needsSub"
          :checked="option.value === onDemandQuality"
          type="radio"
          name="audioQuality"
          @input="save">
        <div>
          <label
            :class="[$style.QualityLabel, $style.label]"
            :for="'audioQuality' + option.value">{{ option.title }}</label>
          <label
            :class="[$style.QualityLabel, $style.sublabel]"
            :for="'audioQuality' + option.value">{{ option.subtitle }}</label>
        </div>
      </div>
    </div>
    <div :class="$style.note">Changes to audio quality settings will take effect from the next playback</div>
  </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

.radio {
  @extend %radio;

  margin-right: $s-s;
  cursor: pointer;
}

.label {
  @extend %contentSubtitleM;

  cursor: pointer;
}

.sublabel {
  @extend %contentSubtitleS;

  display: block;
  cursor: pointer;
}

.subtitle {
  margin-top: 0;
  text-align: center;
}

.quality {
  display: flex;
  align-items: top;
  .label, .sublabel {
    color: lighten($c-text-faded, 20%)
  }
  &.disabled {
    cursor: default;
    .label, .sublabel {
      color: $c-text-disabled;
      cursor: default;
    }
    .radio {
      cursor: default;
    }
  }
  ~.quality {
    margin-top: $s-xxl;
  }
  &:hover:not(.disabled) {
    .label, .sublabel {
      color: $c-white;
    }
    .radio {
      background: transparentize($c-highlight, 0.5);
    }
  }
}

.note {
  @extend %contentSubtitleM;

  margin-top: $s-xxxl;
}

@include tablet {
  .subtitle {
    text-align: left;
  }
}
</style>
