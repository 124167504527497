<script>
export default {
  props: {
    isUnderlined: {
      type: Boolean,
      default: false,
    },
    isUnpadded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabs: [],
      activeTab: null,
    }
  },
  created() {
    this.$on('tab:created', this.tabCreated)
  },
  methods: {
    tabCreated(tab) {
      this.tabs.push(tab)
      if (tab.active) this.activeTab = tab
    },
    setActive(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<template>
  <div class="container">
    <BaseTabPanel
      :tabs="tabs"
      :active-tab="activeTab"
      :is-underlined="isUnderlined"
      :is-unpadded="isUnpadded"
      @setActive="setActive"
    />
    <slot/>
  </div>
</template>
