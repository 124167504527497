<script>
import ShowTableTile from '@components/show-table-tile'

export default {
  components: {
    ShowTableTile,
  },
  props: {
    mixes: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div>
    <ShowTableTile
      v-for="(mix, index) in mixes"
      :mix="mix"
      :index="index"
      :key="mix.id + index + new Date()"/>
  </div>
</template>
