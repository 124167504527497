<script>
import RecentList from '@components/search/recentList'
import DataLayer from '@services/data-layer/index'

export default {
  components: {RecentList},
  data() {
    return {
      recentData: []
    }
  },
  computed: {
    orderedRecentData() {
      // eslint-disable-next-line
      return this.recentData.sort((a, b) => b.order - a.order)
    }
  },
  mounted() {
    this.getRecentData()
  },
  methods: {
    clearRecentSearch() {
      this.$dataLayer.search.clearRecent()
      this.recentData = []
    },
    getRecentData() {
      const recent = this.$dataLayer.search.getRecent()
      recent.map((item, i) => {
        const requestType =
        item.type === 'artist' && 'artists' ||
        item.type === 'mix' && 'mixes' ||
        item.type === 'show' && 'shows'
        if(item.type !== 'genre') {
          DataLayer[requestType].getById(item.itemId).then(data => {
            data.body.type = item.type
            data.body.order = i
            this.recentData.push(data.body)
          })
        }else{
          const genre = {
            title: item.itemId.replace(/_/g, ' '),
            type: 'genre',
            order: i
          }
          this.recentData.push(genre)
        }
      })
    }
  }
}
</script>

<template>
  <BaseSection
    v-if="orderedRecentData && orderedRecentData.length"
    :class="$style.recent"
    padded>
    <h4>Recent Searches</h4>
    <RecentList :items="orderedRecentData"/>
    <BaseButton
      :active="true"
      :class="$style.button"
      button-type="secondary"
      @click="clearRecentSearch">clear recent search</BaseButton>
  </BaseSection>
  <BaseSection
    v-else
    :class="$style.waiting">
    <BaseIcon
      :class="[$style.icon, $style.waitingIcon]"
      name="search-with-glare"/>
    <div :class="$style.waitingHead">Search Frisky</div>
    <div :class="$style.waitingSub">
      <span>Find music by artist, genre or show name</span>
    </div>
  </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

.recent {
  @include desktop {
    margin: 0;
  }
}

.icon {
  fill: $c-white;
}

.button {
  text-transform: uppercase
}

.waiting {
  @include center;

  width: 100%;
  text-align: center;

  &Icon {
    width: 64px;
    height: 64px;
    margin-bottom: $spacing-small;
  }

  &Head {
    @extend %h4;

    margin-bottom: $s-xs;
  }

  &Sub {
    @extend %contentTinyHeading;
  }
}
</style>
