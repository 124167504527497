<script>
import ShowScheduleVerticalTile from '@components/show-schedule-vertical-tile'

export default {
  components: {
    ShowScheduleVerticalTile,
  },
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <ShowScheduleVerticalTile
      v-for="resource in resources"
      :key="`${resource.id}${resource.scheduled_start_time}`"
      :playlist-item="resource"
      artwork
    />
  </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.container {
  min-height: 14rem;
  padding-bottom: math.div($s-h, 2);
}
</style>
