import localSave from './../utils/local-save';

export default () => {
  const increaseSessionReach = (id) => {
    const savedShows = localSave.get('tempShowsReach', 'sessionStorage') || [];
    if (savedShows && !!savedShows.find(show => show.id === Number(id))) return;
    localSave.save('tempShowsReach', [...savedShows, { id, reach: 1 }], 'sessionStorage');
  }

  const hasAdditionalReach = (id) => {
    const savedShows = localSave.get('tempShowsReach', 'sessionStorage');
    return !!savedShows && !!savedShows.find(show => show.id === Number(id));
  }

  return {
    increaseSessionReach,
    hasAdditionalReach
  }
}
