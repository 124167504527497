import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('members', {

  getById({ id }) {
    return Api.members.get({ id })
  },

  add({ data }) {
    return Api.members.post({ data })
  },

  edit({ id, data }) {
    return Api.members.put({ id }, data)
  }

});
