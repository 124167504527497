import dataLayerCreator from '@services/data-layer'
import playerServiceCreator from '@services/player-service'
import queueServiceCreator from '@services/queue-service'
import keyControlsServiceCreator from '../../services/key-controls-service'

const dataLayer = dataLayerCreator()
const playerService = playerServiceCreator()
const queueService = queueServiceCreator({ dataLayer, playerService })
const keyControlsService = keyControlsServiceCreator(playerService, queueService)

export const state = {
  dataLayer,
  queueService,
  playerService,
  keyControlsService,
}

export const getters = {
  api(state) {
    return state.dataLayer.api
  },
}

export const mutations = {}

export const actions = {}
