<script>
import Logo from '@components/logo'
export default {
  components: { Logo },
}
</script>

<template>
  <div :class="$style.menuBar">
    <div
      :class="$style.menuButton"
      @click="$emit('toggle-menu')">
      <span :class="$style.buttonLine"/>
    </div>
    <Logo :class="$style.logo"/>
    <BaseLink
      :class="[$style.title, $style.baseLink]"
      :to="{ name: 'search'}"
      aria-label="Go to Search Page">
      <BaseIcon
        :class="$style.searchIcon"
        name="search"/>
    </BaseLink>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.menuBar {
  position: absolute;
  z-index: $layer-dropdown-z-index;
  width: 100%;
  height: $size-menu-bar-height;
  padding: gutter();
  text-align: center;
}
.menuButton {
  position: absolute;
  top: 50%;
  left: gutter();
  transform: translateY(-50%);
}
.buttonLine {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 2px;
  vertical-align: middle;
  background: $c-razzmatazz;
  &::before {
    position: absolute;
    top: -5px;
    left: 0;
    width: 16px;
    height: 2px;
    content: '';
    background: $c-razzmatazz;
  }
  &::after {
    position: absolute;
    top: 5px;
    left: 0;
    width: 16px;
    height: 2px;
    content: '';
    background: $c-razzmatazz;
  }
}
.title {
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  line-height: $size-menu-bar-height - gutter() * 2;
  color: $c-white;
  vertical-align: middle;
}
.searchIcon {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 20px;
  height: 20px;
  box-shadow: 0 12px 24px 0 rgba(16, 17, 24, 0.27);
  fill: $c-grey-abbey;
  transform: translateY(-50%);
}

@media (min-width: $desktop) {
  .menuBar {
    display: none;
  }
}
</style>
