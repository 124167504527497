import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('artists', {

  getById(id) {
    return Api.artists.get({id})
  },

  getRandomByGenre(id, limit = 12, offset = 0) {
    return Api.artists.find({genre: id, limit, offset, order: 'rand()'})
  },

  getPopularByChannel(channelId, limit = 6, offset = 0) {
    return Api.artists.find({channel: channelId, limit, offset, trending: true})
  },

  getTrending(limit = 7, offset = 0) {
    return Api.artists.find({limit, offset, trending: true})
  },

});
