<script>
import { mapActions } from 'vuex'
import localSave from '@utils/local-save'
import Layout from '@layouts/login'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import SocialLogin from '@views/auth/social-login'

export default {
  page() {
    const title = 'Log in'
    const description = 'Log in'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: { Layout, SocialLogin },
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      password: '',
      checking: false,
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    },
  },
  methods: {
    ...mapActions('auth', ['logIn', 'logOut']),
    tryToLogIn() {
      this.checking = true
      if(this.$v.$invalid) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('EMAIL_PASSWORD_REQUIRED'), type: 'danger' })
        this.$v.$reset()
        this.checking = false
        return
      }
      this.logIn({
        email: this.email,
        password: this.password,
      })
        .then((res) => this._successHandler(res))
        .catch(error => this._errorHandler(error))
    },
    _successHandler(_res) {
      this.checking = false
      this._redirectHandler()
    },
    _errorHandler(error) {
      this._clearFields()
      this.checking = false
      if(error.response.status === 404) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('EMAIL_NOT_FOUND'), type: 'danger' })
        return
      }
      if(error.response.status === 401) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('WRONG_PASSWORD'), type: 'danger' })
      }
      else {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'danger' })
      }
    },
    _clearFields() {
      this.email= ''
      this.password = ''
      this.$v.$reset()
    },
    _redirectHandler() {
      const redirectURL = localSave.get('redirectURL') || '/'
      window.location.replace(redirectURL)
    }
  },
}
</script>

<template>
  <Layout>
    <BaseContent>
      <BaseSection padded>
        <div :class="$style.formContainer">
          <h4 :class="$style.title">Log In</h4>
          <SocialLogin />
          <div :class="$style.lineSeparator">OR</div>
          <form
            :class="$style.form"
            @submit.prevent="tryToLogIn"
          >
            <div :class="$style.fieldContainer">
              <BaseInput
                v-model.lazy="email"
                :class="[$style.field, $v.email.$error && $style.error]"
                name="email"
                autocomplete="current-email"
                placeholder="email*"
                @blur.native="$v.email.$touch()"
              />
              <label
                v-if="$v.email.$error"
                :class="[$style.label, $style.error]"
              >This field is required and must be an email</label>
            </div>
            <div :class="$style.fieldContainer">
              <BaseInput
                v-model="password"
                :class="[$style.field, $v.password.$error && $style.error]"
                name="password"
                type="password"
                autocomplete="current-password"
                placeholder="password*"
                @blur.native="$v.password.$touch()"
              />
              <label
                v-if="$v.password.$error"
                :class="[$style.label, $style.error]"
              >This field is required</label>
            </div>
            <div :class="$style.buttonContainer">
              <BaseButton
                :class="$style.button"
                :disabled="checking"
                button-type="highlight"
                type="submit"
                @click="() => {}"
              >Log In</BaseButton>
            </div>
            <div :class="$style.forgotPassword">
              <div>Forgot your password?</div>
              <BaseLink
                :class="$style.forgotPasswordLink"
                :to="{ name: 'forgot-password' }"
              >Click here to reset your password</BaseLink>
            </div>
            <div :class="$style.agreement">
              If you log in using Facebook, Twitter, Apple or Google and are not a FRISKY user, you will be registered and you agree to our
              <a
                :class="$style.link"
                href="https://www.friskyradio.com/about/tos"
                target="_blank"
              >Terms and Conditions</a>
              &
              <a
                :class="$style.link"
                href="https://www.friskyradio.com/about/privacy"
                target="_blank"
              >Privacy Policy</a>.
            </div>
            <div :class="$style.additionalOptions">
              <span :class="$style.additionalText">Don't have an account?</span>
              <BaseLink :to="{ name: 'signup' }">
                <BaseButton
                  button-type="tagBlack"
                  @click="() => {}"
                >SIGN UP</BaseButton>
              </BaseLink>
            </div>
          </form>
        </div>
      </BaseSection>
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.formContainer {
  position: relative;
  margin-bottom: $s-h;
  .field {
    &.error {
      margin-bottom: $s-xl;
      border-color: $c-punch-red;
      border-width: 2px;
    }
  }
}
.fieldContainer {
  position: relative;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label {
  @extend %contentSubtitleM;

  display: block;
  font-weight: 300;
  text-align: left;

  &.error {
    position: absolute;
    bottom: $s-xs;
    color: $c-punch-red;
  }
}
.title {
  text-align: left;
}
.subtitle {
  @extend %contentTinyHeading;

  font-weight: 400;
  text-align: left;
}

.lineSeparator {
  @include lineSeparator(true, transparentize($c-tuna, 0.5));

  @extend %contentSubtitleS;

  margin-bottom: $s-m * 2;
  font-weight: 100;
}

.buttonContainer {
  width: 100%;
  text-align: right;
}

.button {
  margin-top: 0;
}

.agreement {
  @extend %contentSubtitleS;

  margin-top: $s-l;
}

a.link {
  color: $c-torch-red;
}

.additional {
  &Options {
    margin-top: $s-h;
  }
  &Text {
    @extend %contentSubtitleM;
  }
}

.forgotPassword {
  @extend %contentSubtitleL;

  color: $c-text-faded;
}
a.forgotPasswordLink {
  font-weight: 600;
  color: $c-highlight;
}

@include desktop {
  .additionalText {
    margin-right: $s-xl;
  }
}
</style>
