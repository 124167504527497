import localSave from '@utils/local-save'

export const state = {
  currentUser: localSave.get('auth.currentUser'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    localSave.save('auth.currentUser', newValue)
  },
}

export const getters = {
  loggedIn(state) {
    return !!state.currentUser
  },
}

export const actions = {
  init({ state, dispatch }) {
    dispatch('setToken')
    dispatch('validate')
  },

  setToken({ state, rootGetters }) {
    return rootGetters['services/api'].token.set(state.currentUser ? `Bearer ${state.currentUser.token}` : '')
  },

  setUser({ dispatch, commit }, user) {
    commit('SET_CURRENT_USER', user)
    dispatch('setToken')
  },

  logIn({ commit, dispatch, getters, rootGetters }, fields = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return rootGetters['services/api'].login(fields).then(response => {
      const user = response.data
      dispatch('setUser', user)
      dispatch('member/init', null, { root: true })

      return user
    })
  },

  logOut({ dispatch, rootGetters }) {
    return rootGetters['services/api'].revokeToken()
      .then(_res => {
        dispatch('setUser', null)
        dispatch('member/destroy', null, { root: true })
        localSave.save('redirectURL', '')
        window.location.href = '/'
      })
      .catch(error => {
        if (error.response.status === 401 || error.response.status === 500) {
          dispatch('setUser', null)
          dispatch('member/destroy', null, { root: true })
          localSave.save('redirectURL', '')
          window.location.href = '/'
        }
      })
  },

  validate({ dispatch, state, rootGetters }) {
    if (!state.currentUser) return Promise.resolve(null)

    return rootGetters['services/api']
      .get(`${state.currentUser.members_id.link}/permissions`)
        .then(_response => true)
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            dispatch('logOut', null)
          }
          return null
        })
  },

  userExists({ dispatch, state, rootGetters }) {
    return rootGetters['services/api']
      .get(`${state.currentUser.members_id.link}`)
      .then(_response => {
        return true
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 500) {
          dispatch('logOut', null)
        }
        return null
      })
  }
}
