<script>
import ShowScheduleVerticalBlock from '@components/show-schedule-vertical-block'
import formatDate from '@utils/format-date-full'
import ChannelOptions from './channel-options'

export default {
  components: {
    ShowScheduleVerticalBlock,
    ChannelOptions,
  },
  props: {
    channel: {
      type: Object,
      default: () => {
        return {
          id: 'channelId',
          title: 'channelTitle',
          servers: {},
          playlist: [],
        }
      },
    },
    activeSlide: {
      type: Number,
      default: () => 0,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    playlist: {
      type: Array,
      default: () => []
    },
    displayMinimalVersion: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    resource() {
      return this.$store.state.playback.resource
    },
    status() {
      return this.$store.state.playback.status
    },
    nowPlaying() {
      return this.playlist[0]
    },
    nowPlayingAvailable() {
      return this.nowPlaying && this.nowPlaying.mixes
    },
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },
  methods: {
    airDate(airStart) {
      if (airStart) {
        const date = new Date(airStart)
        return formatDate(date)
      }
      return ''
    },
    playRadioChannel(channel) {
      this.$queueService.playRadioChannel(channel.id)
    }
  },
}
</script>

<template>
  <div>
    <!-- Channel Detail Tile -->
    <BaseLink
      :to="{ name: 'channel', params: { channelId: channel.id }}"
      :class="$style.channelLink"
      name="channel">
      <BasePlaceholder
        :text="channel.title"
        :tag="displayMinimalVersion ? 'h6' : 'h5'"
        :class="[$style.channelTitle, displayMinimalVersion && $style.minimal]"
        type="channel"
      />
    </BaseLink>

    <!-- COVER AREA -->
    <div :class="[$style.coverArea, 'coverArea', {[$style.active]: index === activeSlide}]">
      <div :class="[$style.bg, $style[channel.id]]">
        <div
          v-if="nowPlayingAvailable && nowPlaying.mixes.album_art"
          :class="$style.imageBg"
          :style="`background-image:url(${nowPlaying.mixes.album_art.url})`"/>
        <div :class="$style.gradientBg"/>
      </div>
      <div
        :class="[$style.coverInner, $style[channel.id]]">
        <div
          :class="$style.coverImage">
          <BaseResourceImage
            v-if="nowPlayingAvailable"
            :resource="nowPlaying.mixes.episode"
            :class="$style.image" />
        </div>
        <div
          :class="$style.coverImageBlur">
          <BaseResourceImage
            v-if="nowPlayingAvailable"
            :resource="nowPlaying.mixes.episode"
            :class="$style.image" />
        </div>
        <BaseResourceCover
          v-if="channel"
          :media-resource="channel"
          :class="$style.overlay"
          media-type="channel">
          <div slot="secondaryActions">
            <ChannelOptions :channel="channel" />
          </div>
        </BaseResourceCover>

        <!--
        <div
          v-else
          :class="$style.stopped">
          <BaseButton
            :class="$style.playButton"
            @click="playRadioChannel(channel)">
            <span
              :class="$style.buttonTitle"
              title="PLAY">PLAY</span>
          </BaseButton>
        </div> -->
      </div>
    </div>
    <!-- END OF COVER AREA -->

    <!-- NOW PLAYING AREA -->
    <div
      v-if="channel"
      :class="$style.nowPlayingArea">
      <div :class="['heading', $style.nowPlayingHead]">Now Playing</div>
      <div
        :class="$style.nowPlayingContent">
        <BaseLink
          v-if="nowPlayingAvailable && nowPlaying.mixes.show"
          :to="{name: 'show', params: { id: nowPlaying.mixes.show.id }}">
          <div
            :class="['title', $style.nowPlayingTitle, {[$style.active] : status === 'playing' && resource.id === channel.id}]">{{ nowPlaying.mixes.show.title }}</div>
        </BaseLink>
        <BasePlaceholder
          v-else
          :class="['title', $style.nowPlayingTitle, {[$style.active] : status === 'playing' && resource.id === channel.id}]"
          :text="nowPlayingAvailable && nowPlaying.mixes.show.title"
          type="show"
          tag="div"/>
        <BaseLink
          v-if="nowPlayingAvailable && nowPlaying.mixes.id"
          :to="{name: 'mix', params: { id: nowPlaying.mixes.id }}">
          <div :class="$style.date">{{ airDate(nowPlaying.mixes.episode.air_start) }}</div>
        </BaseLink>
        <BasePlaceholder
          v-else
          :class="$style.date"
          :text="nowPlayingAvailable && airDate(nowPlaying.mixes.episode.air_start)"
          :size="5"
          tag="div"
        />
        <BaseLink
          v-if="nowPlayingAvailable"
          :to="{path: `/artist/${nowPlaying.mixes.artist.url}/${nowPlaying.mixes.artist.id}`}">
          <div :class="['subtitle', 'highlight', $style.nowPlayingArtist]">
            {{ nowPlaying.mixes.artist.title }}
          </div>
        </BaseLink>
        <BasePlaceholder
          v-else
          :class="['subtitle', 'highlight', $style.nowPlayingArtist]"
          :text="nowPlayingAvailable && nowPlaying.mixes.artist.title"
          type="artist"
          tag="div"
        />
      </div>
      <div :class="$style.mobilePlayButton">
        <BaseButtonPlay
          :resource="({type: 'channel', id: channel.id})"
          icon/>
      </div>
    </div>
    <!-- END OF NOW PLAYING AREA -->

    <!-- UPCOMING SCHEDULE AREA -->
    <ShowScheduleVerticalBlock
      v-if="!displayMinimalVersion"
      :playlist="playlist"
      :now-playing="channel.nowPlaying"/>
      <!-- END OF UPCOMING SCHEDULE AREA -->

  </div>
</template>

<style lang="scss" module>
@import '@design';

// COVER AREA
.channelTitle {
  margin: $s-s 0;
  text-align: center;
  &:hover {
    color: $c-highlight;
  }
}

.channelLink {
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.coverArea {
  position: relative;
  height: 230px;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.2, 0.7) translateX(0);
  &.active {
    transform: scale(0.8) translateX(0);
  }

  &:hover {
    @media (min-width: $desktop) {
      .coverImage {
        opacity: 0;
      }
      .coverImageBlur {
        opacity: 1;
      }
    }
  }
}

.gradientBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  opacity: 0.4;
}

.imageBg {
  width: 100%;
  height: 100%;
  filter: blur(30px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.bg {
  @include fill;

  &.frisky {
    .gradientBg {
      background: $c-frisky-channel-grad;
    }
  }
  &.chill {
    .gradientBg {
      background: $c-chill-channel-grad;
    }
  }
  &.deep {
    .gradientBg {
      background: $c-deep-channel-grad;
    }
  }
  &.classics {
    .gradientBg {
      background: $c-classics-channel-grad;
    }
  }
}

.coverImage {
  @include center;

  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  .image {
    width: 160px;
  }
}

.coverImageBlur {
  @include center;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  .image {
    width: 160px;
    filter: blur(10px);
  }
}

.coverInner {
  height: 100%;
  &.classics .coverImage {
    filter: grayscale(100%);
  }
  &.classics .coverImageBlur {
    filter: grayscale(100%);
  }
}

//  NOW PLAYING AREA
.nowPlayingArea {
  min-height: 68px;
  margin: 16px 0 40px;
  text-align: center;
}
.nowPlayingHead {
  font-size: 11px;
  color: $c-text-faded;
}
.nowPlayingContent {
  display: inline-block;
}
.nowPlayingTitle {
  max-width: 270px;
  margin: 8px 0 0;
  overflow: hidden;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  color: $c-white;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.active {
    color: $c-razzmatazz;
  }
}

.date {
  @extend %contentSubtitleM;

  margin-bottom: $s-xxs;
}

.nowPlayingArtist {
  @extend %contentSubtitleL;

  margin: $s-xxs 0 0;
  font-size: 13px;
}

.mobilePlayButton {
  width: 150px;
  margin: 30px auto;
  .button {
    text-align: left;
  }
  .title {
    margin-left: $size-grid-padding * 2;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    vertical-align: middle;
  }
}

.overlay {
  height: 100%;
}

@media (min-width: $tablet) {
  .coverArea {
    transform: unset;
    &.active {
      transform: unset;
    }
  }
}

@media (min-width: $desktop) {
  .channelTitle {
    text-align: left;
    &.minimal {
      text-align: center;
    }
  }
  .nowPlayingHead {
    letter-spacing: normal;
  }
  .mobilePlayButton {
    display: none;
  }
}
</style>
