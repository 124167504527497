<script>
import { mapGetters } from 'vuex'
import Logo from '@components/logo'

export default {
  components: {
    Logo
  },
  props: {
    isMenuOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('member', ['email', 'premium', 'superAdmin']),
  },
}
</script>

<template>
  <div
    :class="[$style.sideBar, { [$style.activeNav] : isMenuOpen}]"
  >
    <div
      :class="$style.menuButton"
      @click="$emit('toggle-menu')"
    >
      <span :class="$style.buttonLine"/>
    </div>
    <Logo :class="$style.logo"/>
    <div :class="$style.section">
      <BaseLink
        :class="[$style.title, $style.baseLink]"
        :to="{ name: 'home'}"
        name="home"
      >
        <BaseIcon
          :class="[$style.icon, $style.iconHome]"
          name="home"
        />
        <span :class="$style.header">Home</span>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'browse/charts/index'}]"
        :to="{ name: 'browse/charts/index' }"
        name="browse/charts/index"
      >
        <div :class="$style.link">
          Charts
        </div>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'browse/playlists/index'}]"
        :to="{ name: 'browse/playlists/index' }"
      >
        <div :class="$style.link">
          Playlists
        </div>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'browse/genres'}]"
        :to="{ name: 'browse/genres' }"
      >
        <div :class="$style.link">
          Genres & Moods
        </div>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : ($route.name === 'browse/mixes/latest' || $route.name === 'browse/mixes/trending')}]"
        :to="{ name: 'browse/mixes/latest' }"
      >
        <div :class="$style.link">
          Mixes
        </div>
      </BaseLink>
    </div>
    <div :class="[$style.section, $style.sectionSearch]">
      <BaseLink
        :class="[$style.title, $style.baseLink]"
        :to="{ name: 'search'}"
      >
        <BaseIcon
          :class="$style.icon"
          name="search"
        />
        <span :class="$style.header">Search</span>
      </BaseLink>
    </div>
    <div :class="$style.section">
      <BaseLink
        :class="[$style.title, $style.baseLink]"
        :to="{ name: 'channel-index'}"
        name="radio"
      >
        <BaseIcon
          :class="$style.icon"
          name="radio"
        />
        <span :class="$style.header">Radio</span>
      </BaseLink>
      <BaseLink
        v-for="channelLink in $store.state.channels.channels"
        :key="channelLink.id"
        :class="[$style.item, $style.baseLink, {[$style.active] : channelLink.id === $route.params.channelId}]"
        :to="{ name: 'channel', params: { channelId: channelLink.id }}"
        name="channel"
      >
        <div :class="$style.link">
          {{ channelLink.title }}
        </div>
      </BaseLink>
    </div>
    <div :class="$style.section">
      <div
        :class="[$style.title, $style.baseLink]"
        name="radio"
      >
        <BaseIcon
          :class="$style.icon"
          name="browse"
        />
        <span :class="$style.header">Your Music</span>
      </div>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'your-music/mixes'}]"
        :to="{ name: 'your-music/mixes' }"
        name="channel"
      >
        <div :class="$style.link">
          Mixes
        </div>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'your-music/artists'}]"
        :to="{ name: 'your-music/artists' }"
        name="channel"
      >
        <div :class="$style.link">
          Artists
        </div>
      </BaseLink>
      <BaseLink
        :class="[$style.item, $style.baseLink, {[$style.active] : $route.name === 'your-music/shows'}]"
        :to="{ name: 'your-music/shows' }"
        name="channel"
      >
        <div :class="$style.link">
          Shows
        </div>
      </BaseLink>
    </div>
    <div :class="[$style.section, $style.sectionBottom, $style.sectionLogin]">
      <BaseLink
        v-if="!loggedIn"
        :class="[$style.item, $style.baseLink]"
        name="login"
      >
        <div :class="$style.link">
          Log in
        </div>
      </BaseLink>
      <div
        v-else
      >
        <div
          :class="$style.item"
        >
          <div :class="$style.col">{{ email }}</div>
          <div
            v-if="premium"
            :class="$style.col"
          >*</div>
          <div
            v-if="superAdmin"
            :class="$style.col"
          >!</div>
        </div>
        <BaseLink
          :class="[$style.item, $style.baseLink]"
          name="settings"
        >
          <div :class="[$style.item, $style.baseLink, {[$style.active] : ($route.name === 'settings' || $route.name === 'settings/change-password') }]">
            Settings
          </div>
        </BaseLink>
        <BaseLink
          :class="[$style.item, $style.baseLink]"
          name="logout"
        >
          <div :class="$style.link">
            Log out
          </div>
        </BaseLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
  @use 'sass:math';
  @use '@design' as *;

  .menuButton {
    position: absolute;
    top: math.div($size-menu-bar-height, 2);
    left: 30px;
    cursor: pointer;
    transform: translateY(-50%);

    @include desktop {
      display: none;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: $s-xxs;
    vertical-align: middle;
    fill: $c-white;
    &Home {
      width: 20px;
      height: 20px;
    }
  }

  .header {
    vertical-align: middle;
  }

  .buttonLine {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 2px;
    vertical-align: middle;
    &::before,
    &::after {
      content: '';
      background: $c-razzmatazz;

      @include fill;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  .sideBar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-modal-z-index;
    display: flex;
    flex-direction: column;
    width: $size-side-bar;
    height: calc(100vh - #{$size-music-player});
    padding: 30px;
    overflow-y: auto;
    background: $c-grey-heavy-metal;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    &.activeNav {
      transform: translateX(0);
    }

    @include desktop {
      position: sticky;
      top: 0;
      z-index: auto;
      width: $size-large-side-bar;
      transform: translateX(0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparentize($c-tuna, 0.8);
    }
  }

  .section {
    margin-top: $s-l;

    &Bottom {
      margin-top: auto;
    }
    &Search {
      display: none;

      @include desktop {
        display: block;
      }
    }
    &Login {
      @include desktop {
        display: none;
      }
    }
  }
  .logo {
    display: none;

    @include desktop {
      display: block;
      margin-bottom: $s-xl;
    }
  }
  .title {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: -0.5px;
    &,
    &:visited {
      color: $c-white;
    }
  }

  .link {
    display: block;
    &:hover {
      color: $c-pink-mandy;
    }
  }

  .baseLink {
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  .item {
    display: flex;
    flex: 1 1 auto;
    font-size: 12px;
    font-weight: 500;
    line-height: 36px;
    color: $c-text-faded;
    text-decoration: none;
    letter-spacing: -0.09px;
    vertical-align: middle;

    &.active {
      .link {
        font-weight: bold;
        color: $c-white;
      }
    }

    &:last-of-type {
      margin-bottom: $s-m;

      @include desktop {
        margin-bottom: 0;
      }
    }
  }

  .col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

</style>
