<script>
import { mapGetters } from 'vuex'
import ChannelDetailTile from '@components/channel/channel-detail-tile'

export default {
  components: {
    ChannelDetailTile
  },
  props: {
    channels: {
      type: Array,
      default: () => []
    },
    playlists: {
      type: [Object, Array],
      default: () => {}
    },
    isFavorites: {
      type: Boolean,
      default: false
    },
    displayMinimalVersion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeSlide: 0,
      totalCarouselPages: 0,
      realCarouselPageCount: 0,
      resizeListener: null,
      tabs: [
        {
          to: { name: 'channel-index' },
          label: 'All Channels',
        },
        {
          to: { name: 'channel-index/favorites' },
          label: 'Favorites',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    favorites() {
      return this.$store.state.settings.favorites
    },
    computedChannels() {
      if (this.isFavorites) {
        return this.channels.filter(channel => this.favorites.includes(channel.id))
      } else {
        return this.channels
      }
    },
    resource() {
      return this.$store.state.playback.resource
    },
    mix() {
      return this.$store.state.playback.mix
    },
    status() {
      return this.$store.state.playback.status
    },
    carouselGrid() {
      return this.displayMinimalVersion ? [[0, 1], [768, 2], [1081, 4]] : [[0, 1], [768, 2], [1081, 3], [1921, 4]]
    }
  },
  watch: {
    computedChannels() {
      this.$nextTick(this.handlePageCount)
    },
  },
  mounted() {
    window.addEventListener('resize', this.handlePageCount)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handlePageCount)
  },
  methods: {
    currentCarPage() {
      this.activeSlide = this.$refs.carousel.currentPage
      this.totalCarouselPages = this.$refs.carousel.pageCount
    },
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
    handlePageCount() {
      this.totalCarouselPages = this.$refs.carousel.pageCount + 2
      this.realCarouselPageCount = this.$refs.carousel.pageCount
    }
  },
}
</script>

<template>
  <div :class="$style.container">
    <!-- Channel Detail Block -->
    <div :class="[$style.row, displayMinimalVersion && $style.minimal]">
      <BaseTabPanel
        v-if="!displayMinimalVersion"
        :class="$style.tabNavigation"
        :tabs="tabs"
        address-navigation
        is-underlined
        is-unpadded
      >
        <div
          v-if="$refs.carousel && realCarouselPageCount > 1"
          :class="[$style.carouselNavigation]"
        >
          <span
            :class="[$style.carouselNavIcon, { [$style.faded] : $refs.carousel.currentPage === 0 }]"
            @click.prevent="prevSlide()"
          >
            <BaseIcon
              :class="[$style.icon, $style.iconArrowLeft]"
              name="arrow-long-left"
            />
          </span>
          <span
            :class="[$style.carouselNavIcon, { [$style.faded] : $refs.carousel.currentPage === (totalCarouselPages - 1) }]"
            @click.prevent="nextSlide()"
          >
            <BaseIcon
              :class="[$style.icon, $style.iconArrowRight]"
              name="arrow-long-right"
            />
          </span>
        </div>
      </BaseTabPanel>
      <carousel
        ref="carousel"
        :per-page-custom="carouselGrid"
        :pagination-enabled="false"
        :navigation-enabled="false"
        :resistance-coef="50"
        :scroll-per-page="false"
        :min-swipe-distance="100"
        :space-padding="12"
        :class="[$style.carousel, 'carousel', displayMinimalVersion && $style.minimal]"
        @pageChange="currentCarPage"
      >
        <slide
          v-for="(channel, index) in computedChannels"
          :key="channel.id"
          :class="$style.slide"
        >
          <ChannelDetailTile
            :channel="channel"
            :index="index"
            :playlist="playlists[channel.id]"
            :display-minimal-version="displayMinimalVersion"
            :active-slide="activeSlide"
            :class="[$style.item, { [$style.faded] : (status === 'playing' && resource.id !== channel.id && resource.id !== mix.id) }, displayMinimalVersion && $style.minimal]"
          />
        </slide>
      </carousel>

      <BaseSection
        v-if="computedChannels.length === 0 && isFavorites && !displayMinimalVersion"
        padded
      >
        <div
          :class="$style.message"
        >
          <h4 :class="$style.messageTitle">
            {{ $m('NO_FAVORITE_CHANNEL') }}
          </h4>
          <p :class="$style.messageParagraph">
            {{ $m('DISCOVER_NEW_CHANNELS') }}
          </p>
          <BaseLink :to="{ name: 'channel-index' }">
            <BaseButton icon="signal">
              Discover New Channels
            </BaseButton>
          </BaseLink>
        </div>
      </BaseSection>
    </div>
  </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.container {
  @include container;

  overflow: hidden;

  @include tablet {
    overflow: visible;
  }
}
.row {
  display: flex;
  flex-direction: column;
  &.minimal {
    margin-right: -24px;
    margin-left: -24px;
  }
}

.item {
  padding: gutter() 0;
  transition: opacity 2s ease-in-out;
  &.minimal {
    padding: 0;
  }
}

.slide {
  padding: 0 math.div(gutter(), 2);
}

.tabNavigation {
  display: none;

  @include tablet {
    display: block;
  }
}

.carouselNavigation {
  padding: 0 gutter();
  text-align: right;
}

.carouselNavIcon {
  display: inline-block;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  background: $c-shark-black;
  border-radius: 6px;
  transition: opacity 0.3s ease-in-out;
  &:first-of-type {
    margin-right: 8px;
  }
  &.faded {
    opacity: 0.2;
  }
  &:hover:not(.faded) {
    background: lighten($c-shark-black, 5%);
  }
}

.iconArrowLeft {
  width: 16px;
  height: 36px;
  fill: $c-nevada-grey;
  transform: scale(1.3, 0.8);
}

.iconArrowRight {
  width: 16px;
  height: 36px;
  fill: $c-nevada-grey;
  transform: scale(1.3, 0.8);
}

.carousel > div {
  overflow: visible;
}

.message {
  max-width: 40rem;
  margin: $s-h * 2 auto $s-xl;
  text-align: center;

  &Paragraph {
    @extend %contentSubtitleL;

    max-width: 25rem;
    margin-right: auto;
    margin-bottom: $s-xxl;
    margin-left: auto;
    color: $c-text-faded;
  }

  &Title {
    margin-bottom: $s-l;
  }
}

@media (min-width: $desktop) {
  .item {
    &.faded {
      opacity: 0.2;
    }
    &:hover {
      opacity: 1;
      transition-duration: 0.5s;
    }
  }
}
</style>
