import store from '@state/store'

import Login from '@views/auth/login'
import Signup from '@views/auth/signup'
import ForgotPassword from '@views/auth/forgot-password'
import SetNewPassword from '@views/auth/set-new-password'
import Settings from '@views/settings'

import Homepage from '@views/homepage'
import Chart from '@views/charts/chart'
import Charts from '@views/charts'
import Playlist from '@views/playlists/playlist'
import Playlists from '@views/playlists'
import BrowseGenres from '@views/browse-genres'
import Genre from '@views/genre'
import BrowseMixes from '@views/browse-mixes'
import Mix from '@views/mix'

import Search from '@views/search'
import SearchRecent from '@components/search/recent'
import SearchResults from '@components/search/results'

import Channels from '@views/channels'
import Channel from '@views/channels/channel'

import YourMusicMixes from '@views/your-music-mixes'
import YourMusicArtists from '@views/your-music-artists'
import YourMusicShows from '@views/your-music-shows'

import Show from '@views/show'
import Artist from '@views/artist'

// import Premium from './views/premium/premium'
// import PremiumWelcome from './views/premium/welcome'
// import PremiumPlans from './views/premium/view-plans'
// import PremiumSelectPayment from './views/premium/select-payment'
// import PremiumComplete from './views/premium/complete'

import PrivacyPolicy from './views/about/privacy-policy'

function beforeEnterAuth(redirectOnLogout) {
  return function(routeTo, routeFrom, next) {
    // If the user is already logged in
    if (store.getters['auth/loggedIn']) {
      // Go to page as expected
      next()
    } else {
      // Redirect to login page
      redirectOnLogout ? next({ name: 'login' }) : next({ name: 'login', params: { redirect: routeTo.fullPath } })
    }
  }
}

export default [
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      if (store.getters['auth/loggedIn']) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
  },
  {
    name: 'signup',
    path: '/auth/signup',
    component: Signup,
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      if (store.getters['auth/loggedIn']) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
  },
  {
    name: 'forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    props: true,
    beforeEnter(routeTo, routeFrom, next) {
      if (store.getters['auth/loggedIn']) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
  },
  {
    name: 'reset-password',
    path: '/auth/reset-password',
    alias: '/set-new-password',
    component: SetNewPassword,
    props: true,
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    props: {
      mainComponent: 'Overview',
    },
    beforeEnter: beforeEnterAuth(true),
  },
  {
    name: 'settings/audio-quality',
    path: '/settings/audio-quality',
    component: Settings,
    props: {
      mainComponent: 'AudioQuality',
    },
    beforeEnter: beforeEnterAuth(true),
  },
  {
    name: 'settings/change-password',
    path: '/settings/change-password',
    component: Settings,
    props: {
      mainComponent: 'ChangePassword',
    },
    beforeEnter: beforeEnterAuth(true),
  },
  {
    name: 'settings/subscription',
    path: '/settings/subscription',
    component: Settings,
    props: {
      mainComponent: 'Subscription',
    },
    beforeEnter: beforeEnterAuth(true),
  },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut')
    },
  },

  {
    name: 'home',
    path: '/',
    component: Homepage
  },
  {
    name: 'browse/charts/index',
    path: '/browse/charts',
    component: Charts,
  },
  {
    name: 'browse/charts',
    path: '/browse/charts/:id',
    component: Chart,
  },
  {
    name: 'browse/playlists/index',
    path: '/browse/playlists',
    component: Playlists,
  },
  {
    name: 'browse/playlists',
    path: '/browse/playlists/:id',
    component: Playlist,
  },
  {
    name: 'browse/genres',
    path: '/browse/genres',
    component: BrowseGenres,
  },
  {
    name: 'genre',
    path: '/genre/:id/',
    component: Genre,
  },
  {
    name: 'browse/mixes/latest',
    alias: '/browse/mixes',
    path: '/browse/mixes/latest',
    component: BrowseMixes,
    props: {
      mainComponent: 'LatestMixes',
    },
  },
  {
    name: 'browse/mixes/trending',
    path: '/browse/mixes/trending',
    component: BrowseMixes,
    props: {
      mainComponent: 'TrendingMixes',
    },
  },{
    name: 'browse/mixes/recently',
    path: '/browse/mixes/recently',
    component: BrowseMixes,
    props: {
      mainComponent: 'RecentlyPlayedMixes',
    },
  },
  {
    name: 'mix',
    path: '/mix/:id/',
    component: Mix,
  },

  {
    name: 'search',
    path: '/search',
    component: Search,
    redirect: '/search',
    children: [
      {
        name: 'search/recent',
        path: '',
        component: SearchRecent,
      },
      {
        name: 'search/results/top',
        path: 'results/top/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Top',
        },
      },
      {
        name: 'search/results/mixes',
        path: 'results/mixes/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Mixes',
        },
      },
      {
        name: 'search/results/genres',
        path: 'results/genres/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Genres',
        },
      },
      {
        name: 'search/results/artists',
        path: 'results/artists/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Artists',
        },
      },
      {
        name: 'search/results/shows',
        path: 'results/shows/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Shows',
        },
      },
      {
        name: 'search/results/tracklist',
        path: 'results/tracklist/:keyword?',
        component: SearchResults,
        props: {
          mainComponent: 'Tracklist',
        },
      },
    ],
  },

  {
    name: 'channel-index',
    path: '/radio',
    component: Channels,
  },
  {
    name: 'channel-index/favorites',
    path: '/radio/favorites',
    component: Channels,
    props: {
      isFavorites: true,
    },
    beforeEnter: beforeEnterAuth(),
  },
  {
    name: 'channel',
    path: '/radio/:channelId/',
    component: Channel,
    props: {
      mainComponent: 'Overview',
    },
  },
  {
    name: 'channel/schedule',
    path: '/radio/:channelId/schedule',
    component: Channel,
    props: {
      mainComponent: 'Schedule',
    },
  },
  {
    name: 'channel/history',
    path: '/radio/:channelId/history',
    component: Channel,
    props: {
      mainComponent: 'History',
    },
  },

  {
    path: '/yourmusic/mixes',
    name: 'your-music/mixes',
    component: YourMusicMixes,
    beforeEnter: beforeEnterAuth(true),
  },
  {
    path: '/yourmusic/artists',
    name: 'your-music/artists',
    component: YourMusicArtists,
    beforeEnter: beforeEnterAuth(true),
  },
  {
    path: '/yourmusic/shows',
    name: 'your-music/shows',
    component: YourMusicShows,
    beforeEnter: beforeEnterAuth(true),
  },

  {
    path: '/shows/:id',
    name: 'show',
    component: Show,
    props: {
      mainComponent: 'Overview',
    },
  },
  {
    path: '/shows/:id/about',
    name: 'show/about',
    component: Show,
    props: {
      mainComponent: 'About',
    },
  },
  {
    name: 'artist',
    path: '/artist/:artistUrl?/:id',
    component: Artist,
    props: {
      mainComponent: 'Overview',
    },
  },
  {
    name: 'artist/about',
    path: '/artist/:artistUrl?/:id/about',
    component: Artist,
    props: {
      mainComponent: 'About',
    },
  },
  // {
  //   name: 'premium',
  //   path: '/premium',
  //   redirect: '/premium',
  //   component: Premium,
  //   children: [
  //     {
  //       name: 'premium/welcome',
  //       path: '/premium',
  //       component: PremiumWelcome
  //     },
  //     {
  //       name: 'premium/view-plans',
  //       path: 'view-plans',
  //       component: PremiumPlans
  //     },
  //     {
  //       name: 'premium/select-payment',
  //       path: 'select-payment/:planId',
  //       component: PremiumSelectPayment,
  //     },
  //     {
  //       name: 'premium/complete',
  //       path: 'complete',
  //       component: PremiumComplete
  //     },
  //   ]
  // },
  {
    name: 'about/privacy',
    path: '/about/privacy-policy',
    component: PrivacyPolicy
  },

  {
    path: '/404',
    name: '404',
    component: require('@views/404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]
