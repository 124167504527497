import localSave from '@utils/local-save'

export const state = {
  resourceId: -1,
  resource: null, // object
  resourceType: null,
  status: null,
  currentTime: -1,
  duration: -1,
  mix: null, // mix object
  history: localSave.get('queueHistory') || null, // mix array
}

export const getters = {}

export const mutations = {
  SET_STATUS(state, { currentTime, status, duration }) {
    state.currentTime = currentTime
    state.duration = duration
    state.status = status
  },
  SET_DATA(state, { resource, mix, history }) {
    state.resource = resource
    state.resourceId = (resource && resource.id) || null
    state.resourceType = (resource && resource.type) || null
    state.mix = mix
    state.history = history
  },
}

export const actions = {}
