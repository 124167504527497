<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
  },
  computed: {
    isActive() {
      return this.$parent.activeTab === this
    },
  },
  mounted() {
    this.$parent.$emit('tab:created', this)
  },
}
</script>

<template>
  
  <div v-if="isActive">
    <slot/>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
