export default {
  auth: {
    login: {
      url: '/auth/token',
      method: 'post',
    },
  },

  models: {
    _collection: true,
    _preventPopulation: true,

    dispatch: {
      url: '/dispatch',
      options: ['models', 'depth'],
    },
  },

  shows: {
    _model: 'Shows',

    get: {
      url: '/shows/{id}',
    },
  },

  artists: {
    _model: 'Artists',

    get: {
      url: '/artists/{id}',
    },

    find: {
      url: '/artists',
      options: ['channel', 'genre', 'offset', 'limit', 'order', 'trending'],
    },
  },

  episodes: {
    _model: 'Episodes',

    get: {
      url: '/episodes/{id}',
    },

    find: {
      url: '/episodes',
      options: ['channel', 'startdate', 'enddate'],
    },
  },

  mixes: {
    _model: 'Mixes',

    get: {
      url: '/mixes/{id}',
    },

    find: {
      url: '/mixes',
      options: ['channel', 'artists_id', 'shows_id', 'genre', 'trending', 'offset', 'limit', 'order'],
    },

    url: {
      url: '/mixes/{id}/urls',
      options: ['quality'],
    },
  },

  elastic: {
    _collection: true,

    search: {
      url: '/search',
      options: ['query', 'offset', 'limit'],
      method: 'get',
    },
  },

  playlists: {
    _model: 'Playlists',

    get: {
      url: '/playlists',
      options: ['type', 'offset', 'limit', 'hidden', 'created_by', 'order', 'featured']
    },

    getById: {
      url: '/playlists/{id}'
    }
  },

  playlistsRevisionsMixes: {
    _model: 'PlaylistsRevisionsMixes',

    get: {
      url: '/playlists/{playlistId}/mixes',
      options: ['offset', 'limit']
    },
  },

  favorites: {
    _model: 'Mixes',

    get: {
      url: '/members/{membersId}/favorites/mixes',
      options: ['offset', 'limit', 'order'],
    },
    post: {
      url: '/members/{membersId}/favorites/mixes',
    },
    delete: {
      url: '/members/{membersId}/favorites/mixes/{id}',
    },
  },

  related: {
    _model: 'Mixes',

    get: {
      url: '/related/mixes/',
      options: ['mixes_id', 'offset', 'limit', 'order'],
    },
  },

  channels: {
    _model: 'Stations',

    get: {
      url: '/stations',
    },
    validateStream: {
      url: '/subscriptions/validate-streaming',
      options: ['token', 'station', 'mount'],
      method: 'get',
    }
  },

  stationsPlaylists: {
    _model: 'StationsPlaylist',
    _preventModelsCaching: true,

    get: {
      url: '/stations/playlists',
      options: ['station', 'after', 'offset', 'limit'],
    },
  },

  bfhs: {
    _model: 'Bfh',
    get: {
      url: '/bfhs',
      options: ['limit', 'offset', 'order', 'show_all'],
    }
  },

  members: {
    _model: 'Members',
    get: {
      url: '/members/{id}',
    },
    post: {
      url: '/members',
    },
    put: {
      url: '/members/{id}',
    },
  },

  uploader: {
    post: {
      url: '/media/s3',
    }
  },

  versions: {
    get: {
      url: '/versions',
    }
  },

  subscriptions: {
    getProducts: {
      url: '/subscriptions/products',
      method: 'get',
    },
    getMembersProducts: {
      url: '/subscriptions',
      method: 'get',
      options: ['membersid', 'status'],
    },
    checkout: {
      url: '/subscriptions/checkouts',
      method: 'post'
    }
  }
}
