<script>
import DataLayer from '@services/data-layer/index'
import PlaylistsTableBlock from '@components/playlists/playlists-table-block'

export default {
  components: { PlaylistsTableBlock },
  data() {
    return {
      playlistMixes: [],
      id: 103,
      playlist: {},
      limit: 5,
      total: null
    }
  },
  computed: {
    displayedPlaylistMixes() {
      return this.playlistMixes.slice(0, this.limit)
    },
    isCurrentChartPlaying() {
      return Number(this.id) === this.$store.state.queue.playlistId
    },
  },
  mounted() {
    DataLayer.playlists.getById(this.id)
        .then(playlist => {
          this.playlist = playlist.body
        })
    DataLayer.playlists.getRevisionsMixes(this.id, this.limit, 0)
      .then(mixes => {
        this.total = Number(mixes.headers['x-items-total'])
        this.playlistMixes = mixes.body.map(revisionsMixes => revisionsMixes.mixes)
      })
  },
  methods: {
    loadAllMixes() {
      return DataLayer.playlists.getRevisionsMixes(this.id, this.total).then(mixes => {
        this.playlistMixes = mixes.body.map(revisionsMixes => revisionsMixes.mixes)
      })
    },
    listenChart(position = -1) {
      if((this.isCurrentChartPlaying && position === -1) || (this.isCurrentChartPlaying && this.$store.state.queue.position && this.$store.state.queue.position === position)) {
        this.$queueService.pause()
      }else {
        this.$queueService.playMixMultiple({mixes: [...this.playlistMixes], id: this.id, position: position > -1 ? position : 0})
      }
    },
    listenChartHandler(position = -1) {
      if(this.playlistMixes.length === this.total) {
        this.listenChart(position)
      }else{
        this.loadAllMixes().then(mixes => {
          this.listenChart(position)
        })
      }
    },
    playPlaylistMix(index) {
      this.listenChartHandler(index)
    }
  }
}
</script>

<template>
  <div
    v-if="displayedPlaylistMixes && displayedPlaylistMixes.length"
    :class="$style.section">
    <BaseSection
      :show-all-link="{ name: 'browse/charts', params: { id }}"
      :title="playlist.revisions_title || playlist.title"
      show-all-title="Show Full Chart"
      padded>
      <PlaylistsTableBlock
        :mixes="displayedPlaylistMixes"
        :error="{
          title: `There are no mixes in this chart yet`,
        }"
        :should-transition="true"
        playlist-id
        genres
        is-indexed-list
        @playPlaylistMix="playPlaylistMix"
      />
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
  margin-top: $s-xxl;
}
</style>
