export default function formatMs(timeInMs) {
  var hours = Math.floor((timeInMs / (1000 * 60 * 60)) % 60)
  var minutes = Math.floor((timeInMs / (1000 * 60)) % 60)
  var seconds = Math.floor((timeInMs / 1000) % 60)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  return `${hours !== `00` ? `${hours}:` : ``}${minutes}:${seconds}`
}
