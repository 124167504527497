<script>
import ShowOptions from '@components/show/show-options'
import formatTime from '@utils/format-time'
import formatTimeAMPM from '@utils/format-time-am-pm'
import formatDate from '@utils/format-date'

export default {
  components: {
    ShowOptions,
  },
  props: {
    artwork: {
      type: Boolean,
      default: false,
    },
    scheduleItem: {
      type: Object,
      default: () => {
        return {
          show: {
            id: 'id',
            title: 'showTitle',
            url: 'showUrl',
            type: 'show',
            image: {},
            genre: [],
            artist_id: [],
            artist: [],
          },
        }
      },
    },
  },
  computed: {
    show() {
      return this.scheduleItem.show
    },
    date() {
      return new Date(this.scheduleItem.air_start)
    },
    airTime() {
      return this.artwork ? formatTimeAMPM(this.date) : formatTime(this.date)
    },
    airDate() {
      return formatDate(this.date)
    },
  },
}
</script>

<template>
  <div
    v-if="show"
    :class="[ $style.upcoming, artwork && $style.artwork ]">
    <div :class="$style.dateTime">
      <BasePlaceholder
        :class="['title', $style.time]"
        :text="airTime"
        tag="div"/>
      <BasePlaceholder
        v-if="artwork"
        :class="$style.date"
        :text="airDate"
        tag="div"/>
    </div>

    <BaseResourceImage
      v-if="artwork"
      :resource="show"
      :class="$style.image"/>
    <div :class="$style.show">
      <BaseLink
        v-if="show && show.title"
        :class="['title', $style.title, $style.showLink]"
        :to="{name: 'show', params: { id: show.id }}">
        {{ show.title }}
      </BaseLink>
      <BasePlaceholder
        v-else
        :class="['title', $style.title, $style.showLink]"
        :text="show && show.title"
        type="show"
        tag="div"/>

      <div
        v-if="show.artist && show.artist.title"
        :class="['subtitle', $style.artist]">
        <BaseLink :to="{path: `/artist/${show.artist.url}/${show.artist.id}`}">
          {{ show.artist.title }}
        </BaseLink>
      </div>

      <BasePlaceholder
        v-else
        :text="show.artist && show.artist.title"
        :class="['subtitle', $style.artist]"
        tag="div"
        type="artist"/>
    </div>
    <div
      :class="$style.options"
      @click.prevent="()=>{}">
      <BaseDropdown :padded="false">
        <BaseButton
          slot="trigger"
          :modifiers="{ autoWidth: true, transparent: true, dots: true}"
          button-type="tag"
          aria-label="Options"
          size="small"/>
        <div slot="dropdown">
          <ShowOptions :show="show" />
        </div>
      </BaseDropdown>
    </div>
  </div>
</template>


<style lang="scss" module>
@import '@design';

.upcoming {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: $s-s;
  padding-bottom: $s-s;
  margin: 0;
  border-bottom: 1px solid $c-grey-woodsmoke;
}
.dateTime {
  display: inline-block;
  width: 40px;
  min-height: 38px;

  .artwork & {
    width: auto;

    @include desktop {
      width: span(1 of 12 narrow);
    }
  }
}
.time {
  display: inline-block;
  width: 100%;
  margin: 0 0 $s-xxs;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  vertical-align: top;
}
.upcoming:nth-of-type(1) {
  .time {
    color: $c-text;
  }
}
.new {
  max-width: 40px;
  padding: 2px 0;
  font-size: 8px;
  font-weight: bold;
  line-height: 10px;
  color: $c-razzmatazz;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.17px;
  border: 2px solid $c-razzmatazz;

  .artwork & {
    @include desktop {
      display: none;
    }
  }
}
.show {
  display: inline-block;
  width: 70%;
  padding-left: $s-l;
  margin-right: auto;
  vertical-align: top;

  .artwork & {
    @include desktop {
      display: flex;
    }
  }

  .artwork &Link {
    @include desktop {
      min-width: 55%;
    }
  }
}
.title {
  @extend %contentTitleM;

  max-width: 220px;
  margin: 0 0 $s-xxs;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.artist {
  @extend %contentSubtitleM;

  max-width: 100%;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

@media (min-width: $desktop) {
  .time {
    color: $c-text;
  }
}

.image {
  display: none;
  width: 2rem;
  margin-left: gutter(of 12 narrow);

  @include tablet {
    display: block;
  }
}

.date {
  display: none;

  @extend %contentSubtitleS;

  @include desktop {
    display: block;
    white-space: nowrap;
  }
}
</style>
