<script>
export default {
  props: {
    padded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isActive: false,
      offset: 0,
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
      this.emitActivity()
      if(this.isActive) {
        setTimeout(() => {
          this.correctOffset()
        }, 0);
      }
    },
    hide() {
      this.isActive = false
      this.emitActivity()
    },
    emitActivity() {
      this.$emit('is-panel-active', this.isActive)
    },
    correctOffset() {
      const elementRect = this.$refs.dropdownItem.getBoundingClientRect()
      const windowWidth = window.innerWidth
      if (elementRect.right > windowWidth) {
        this.offset = elementRect.right - windowWidth + 20
      }
    }
  }
}
</script>

<template>
  <div :class="[$style.container, isActive && $style.active]">
    <div
      :class="$style.dropdownContainer"
      :style="`transform: translateX(-${offset}px)`">
      <div
        ref="dropdownItem"
        :class="[$style.dropdown, padded && $style.padded]"
      >
        <slot name="dropdown"/>
      </div>
    </div>
    <div
      v-click-outside="hide"
      :class="$style.trigger"
      @click="toggle">
      <slot name="trigger"/>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.trigger {
  position: relative;
  cursor: pointer;
}

.dropdown {
  @extend %contentSubtitleS;

  position: absolute;
  top: $s-m;
  left: 0;
  z-index: $layer-message-z-index;
  display: none;
  min-width: 240px;
  overflow: hidden;
  text-transform: none;
  background-color: $c-grey-heavy-metal;
  border-radius: 5px;

  @include baseShadow;

  &.padded {
    padding: $s-s $s-m;
  }
}

.dropdownContainer {
  position: relative;
  top: $s-m;
  left: 0;
  z-index: $layer-message-z-index;
}

.container {
  position: relative;
  &.active .dropdown {
    display: block;
  }
}
</style>
