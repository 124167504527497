const Promise = require('bluebird')

export default function getAndUpdate(action, returnWithPromise) {
  const actionResult = action(returnWithPromise.value)

  if (returnWithPromise.promise) {
    return returnWithPromise.promise.then(value => action(value))
  }

  return new Promise(resolve => resolve(actionResult))
}
