import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('channels', {

  get() {
    return Api.channels.get()
  },
  validateStream({ token, station, mount }){
    return Api.channels.validateStream({ token, station, mount })
  }

});
