import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('subscriptions', {

  getProducts() {
    return Api.subscriptions.getProducts()
  },

  getMembersProducts({ membersid, status }) {
    return Api.subscriptions.getMembersProducts({ membersid, status })
  },

  checkout(data) {
    return Api.subscriptions.checkout({}, data)
  }

});
