import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('playlists', {

  getByType({ type, hidden = 0, limit = 100, offset = 0, order = '-modification_time', featured = null }) {
    return Api.playlists.get({ type, hidden, limit, offset, order, featured })
  },

  getById(id) {
    return Api.playlists.getById({ id })
  },

  getRevisionsMixes(playlistId, limit = 100, offset = 0) {
    return Api.playlistsRevisionsMixes.get({ playlistId, limit, offset })
  },

  getMemberRecommendations(memberId) {
    return Api.playlists.get({ type: 'recommendations', created_by: memberId })
  }

});
