<script>
import BfhsButton from '@components/bfhs/bfhs-button'

export default {
  components: { BfhsButton },
  props: {
    bfh: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<template>
  <div :class="$style.slider">
    <div :class="$style.imageContainer">
      <BaseResourceImage
        :fill="false"
        :class="$style.image"
        :resource="bfh"
        :should-lazyload="false"
        type="image"/>
    </div>
    <div :class="$style.inner">
      <BaseSection
        :class="$style.section"
        padded>
        <h3
          v-if="bfh.heading"
          :class="$style.title">{{ bfh.heading }}</h3>
        <div :class="$style.details">
          <h4
            v-if="bfh.subheading"
            :class="$style.subtitle">{{ bfh.subheading }}</h4>
          <p
            v-if="bfh.description"
            :class="$style.description">{{ bfh.description }}</p>
          <div :class="$style.buttons">
            <BfhsButton
              v-if="bfh.primary_action"
              :class="$style.button"
              :title="bfh.heading"
              :action="bfh.primary_action"
              type="primary" />
            <BfhsButton
              v-if="bfh.secondary_action"
              :class="[$style.button, $style.buttonSecondary]"
              :title="bfh.heading"
              :action="bfh.secondary_action"
              type="tagBlack" />
          </div>
        </div>
      </BaseSection>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.slider {
  position: relative;
  width: 100%;
  min-width: 1px;
  height: calc(80vh - #{$size-music-player});
  overflow: hidden;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 50%;
  width: $s-bfh-image-width;
  height: $s-bfh-image-height;
  text-align: center;
  transform: translateX(-50%);
}

.section {
  position: relative;
  height: 100%;
}

.title {
  position: absolute;
  top: $s-h;
  left: $s-xl;
  z-index: $layer-page-z-index;
  margin-top: 0;

  @extend %h6;
}

.details {
  position: absolute;
  bottom: $s-xxl * 2;
  left: $s-xl;
}

.subtitle {
  margin: 0;
  margin-bottom: 82.9px;
  font-size: 56px;
  line-height: 56px;
}

.description {
  display: none;
  max-width: 550px;
  margin-bottom: $s-xl;
}

.buttons {
  display: flex;
}

.button {
  width: 300px;
  &Secondary {
    display: none;
  }
}

.button~.button {
  margin-left: $s-xl;
}

@include desktop {
  .slider {
    height: auto;
  }
  .image {
    position: unset;
    left: unset;
    width: auto;
    height: auto;
    transform: translateX(0);
  }
  .title {
    top: $s-xxl * 2;
  }
  .subtitle {
    margin-bottom:$s-s;
    font-size: 84px;
    line-height: 84px;
    &::first-letter {
      margin-left: -6px;
    }
  }
  .description {
    display: block;
    margin-bottom: 38px;
  }
  .button {
    width: 170px;
    &Secondary {
      display: block;
    }
  }
}

</style>
