<script>
import GenresList from '@components/genres-list'
import extractGenres from '@utils/extract-genres'

export default {
  components: { GenresList },
  props:{
    searchResults: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mixes() {
      return this.searchResults.Mixes
    },
    genres() {
      return this.mixes && extractGenres(this.mixes)
    }
  }
}
</script>

<template>
  <div>
    <BaseSection
      v-if="genres"
      padded>
      <GenresList :genres="genres"/>
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
