<script>
export default {
  computed: {
    historyLength() {
      return this.$store.state.status.historyLength
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      this.$store.commit('status/SET_HISTORY_LENGTH', this.historyLength - 2)
    },
  },
}
</script>

<template>
  <a
    :class="[$style.container, (historyLength > 1? $style.visible : '')]"
    @click="goBack">
    <base-icon
      :class="$style.arrow"
      name="arrow-longer-left"
    />
  </a>
</template>

<style lang="scss" module>
@import '@design';

.container {
  z-index: $layer-overlay-z-index;
  cursor: pointer;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
.baseSection.baseSectionArrow {
  margin-top: 0;
}
.arrow {
  width: $s-m;
  height: $s-xl;
  fill: $c-white;
}
</style>
