<script>
import isMatch from 'lodash/isMatch'
export default {
  props: {
    // enables using navigation wildcard to set tab
    addressNavigation: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Object,
      default: null,
    },
    isUnderlined: {
      type: Boolean,
      default: false,
    },
    isUnpadded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isActive(tabRoute, { name, params }) {
      return isMatch(tabRoute, Object.keys(params).length > 0 ? { name, params } : { name })
    },
  },
}
</script>

<template>
  <BaseContent>
    <BaseSection
      :class="$style.baseSection"
      padded
    >
      <div :class="$style.row">
        <div
          v-if="addressNavigation"
          :class="[$style.tabList, isUnpadded ? $style.tabListUnpadded : '']"
        >
          <BaseLink
            v-for="tab in tabs"
            :key="tab.label"
            :class="isActive(tab.to, $route) ? `${$style.tab} ${$style.active}` : $style.tab"
            v-bind="tab.to"
          >
            {{ tab.label }}
          </BaseLink>
        </div>
        <div
          v-else
          :class="[$style.tabList, isUnpadded ? $style.tabListUnpadded : '']"
        >
          <div
            v-for="tab in tabs"
            :key="tab.label"
            :class="activeTab === tab ? `${$style.tab} ${$style.active}` : $style.tab"
            @click="$emit('setActive', tab)"
          >{{ tab.label }}
          </div>
        </div>
        <div :class="$style.sideSlot">
          <slot/>
        </div>
      </div>
      <div
        v-if="isUnderlined"
        :class="$style.separatorLine"
      />
    </BaseSection>
  </BaseContent>
</template>

<style lang="scss" module>
@use "sass:math";
@use '@design' as *;

.container {
  text-align: left;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: $s-xl;

  @include desktop {
    justify-content: space-between;
  }
}

.baseSection {
  margin-top: $s-m;

  @include desktop {
    margin-top: $s-xl;
  }
}
.sideSlot {
  display: flex;
  margin-left: auto;
}

.tab {
  position: relative;
  padding: 0 0 $s-m;
  margin: 0;
  cursor: pointer;

  &:hover, &:active {
    text-decoration: none;
  }

  @extend %contentSubtitleM;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    content: '';
    background: $c-highlight;
    transition: $t;
    transform: scaleX(0);
  }

  &.active {
    @extend %contentTitleM;

    &::after {
      transform: scaleX(1);
    }
  }
}

.tab~.tab {
    margin-left: $s-xs;
  }

.tabList {
  @include scrollableWrapper;

  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: math.div($s-h, 2);

  &Unpadded {
    padding-bottom: 0;

    .tab {
      margin-bottom: 0;
    }
  }

  @include tablet {
    justify-content: flex-start;
    width: auto;
  }

  @include desktop {
    justify-content: flex-start;
  }
}

.separatorLine {
  width: 100%;
  height: 1px;
  opacity: 0.1;

  &::after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background: $c-dusty-gray;
  }
}

@include desktop {
  .tab {
    padding: 0 $s-m $s-m;
  }

  .tab~.tab {
    margin-left: $s-m;
  }
}
</style>
