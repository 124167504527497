import localSave from '@utils/local-save'

export const state = {
  // need to check if we have user logged in first
  currentMember: localSave.get('user.currentUser'),
  passState: null,
}

export const mutations = {
  SET_CURRENT_MEMBER(state, newValue) {
    state.currentMember = newValue
    localSave.save('user.currentMember', newValue)
  },
  SET_PASS_STATE(state, val) {
    state.passState = val
  }
}
export const actions = {
  init({ rootState, state, commit, rootGetters }) {
    if (rootState.auth.currentUser && rootState.auth.currentUser.members_id) {
      const membersId = rootState.auth.currentUser.members_id // for now fetch regularly then replace by the api
      rootGetters['services/api'].get(`${membersId.link}?includepasswordstate=1`).then(({ data, headers }) => {
        commit('SET_CURRENT_MEMBER', data)
        commit('SET_PASS_STATE', headers['x-password-state'])
      })
    }
  },
  destroy({ commit }) {
    commit('SET_CURRENT_MEMBER', null)
    commit('SET_PASS_STATE', null)
  },
}

export const getters = {
  premium(state) {
    return state.currentMember && state.currentMember.role === 7
  },
  superAdmin(state) {
    return state.currentMember && state.currentMember.role === 1
  },
  email(state) {
    return state.currentMember && state.currentMember.email
  },
}
