<script>
export default {
  props: {
    genre: {
      type: String,
      default: '',
    },
  },
  computed: {
    id() {
      return this.genre.toLowerCase().replace(/ /g, '_')
    },
  },
  methods: {},
}
</script>

<template>
  <BaseButton
    button-type="tagSecondary"
    size="small"
    @click="$router.push({ name: 'genre', params: { id }})">
    <slot>
      {{ genre }}
    </slot>
  </BaseButton>
</template>

<style lang="scss" module>
@import '@design';
</style>
