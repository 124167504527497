<script>
export default {}
</script>

<template>
  <div :class="$style.page">
    <slot/>
  </div>
</template>

<style lang="scss" module>
@import '@design';

$width: 800px;

.page {
  position: relative;
  width: $width;
  max-width: 100%;
  padding: 0 $s-s;
  margin: 0 auto;
  background-color: $c-black-grey-gradient;
  text-align: left;
}

@include tablet {
  .main {
    margin: $s-h auto 0;
  }
}
</style>
