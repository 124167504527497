<script>
import ShowOptions from '@components/show/show-options'
export default {
  components: {
    ShowOptions,
  },
  props: {
    index: {
      type: Number,
      default: -1,
    },
    mix: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div :class="[$style.inner, $style.main]">
      <BaseResourceImage
        :resource="mix.show"
        :class="$style.image"/>
      <BaseLink
        v-if="mix.show && mix.show.title"
        :to="{name: 'show', params: { id: mix.show.id }}">
        <div :class="$style.show">

          {{ mix.show.title }}
        </div>
      </BaseLink>
    </div>
    <div :class="[$style.inner, $style.time]"/>
    <div :class="[$style.inner, $style.buttons]">
      <div
        :class="$style.options"
        @click.prevent="()=>{}">
        <BaseDropdown :padded="false">
          <BaseButton
            slot="trigger"
            :modifiers="{ autoWidth: true, transparent: true, dots: true}"
            button-type="tag"
            aria-label="Options"
            size="small"/>
          <div slot="dropdown">
            <ShowOptions :show="mix.show" />
          </div>
        </BaseDropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  @extend %contentSubtitleM;

  display: flex;
  align-items: center;
  padding-bottom: $s-xxs;
  margin-bottom: $s-xxs;
  border-bottom: 1px solid rgba($c-ebony-clay, 0.35);
}
.inner {
  display: flex;
  align-items: center;
}
.index {
  width: span(2 wide of 12 narrow);
  max-width: 70px;

  &Main {
    margin-right: gutter(of 2 wide);
    color: $c-shuttle-gray;
  }

  @include tablet {
    width: span(2 narrow of 12 narrow);
  }

  @include desktop {
    width: span(1 narrow of 12 narrow);
  }
}
.main {
  @extend %contentTitleM;

  width: span(7 wide of 12 narrow);

  @include tablet {
    width: span(5 narrow of 12 narrow);
  }
}
.image {
  display: none;
  width: 2rem;
  margin-right: gutter(of 12 narrow);

  @include tablet {
    display: block;
  }
}
.show {
  color: $c-white;
}
.time {
  display: flex;
  justify-content: flex-end;
  min-width: 10rem;
  margin-left: auto;
  color: $c-shuttle-gray;
}
.buttons {
  display: flex;
  margin-left: 50px;
}
</style>
