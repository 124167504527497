export const state = {
  historyLength: 0,
}

export const getters = {}

export const mutations = {
  SET_HISTORY_LENGTH(state, length) {
    state.historyLength = length
  },
}

export const actions = {}
