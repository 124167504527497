<script>
import waveform from '@components/waveform'

import formatMs from '@utils/format-ms'

let volumeDisplayTime = null

export default {
  components: {
    waveform,
  },
  data() {
    return {
      prevVolume: 0,
      isVolumeActive: false,
    }
  },
  computed: {
    playback() {
      return this.$store.state.playback
    },
    resource() {
      return this.playback.resource
    },
    isChannelPlaying() {
      return this.resource && this.resource.type && this.resource.type === 'channel'
    },
    isMixPlaying() {
      return this.resource && !this.isChannelPlaying
    },
    queueLength() {
      return this.$store.state.queue && this.$store.state.queue.mixes && this.$store.state.queue.mixes.length || null
    },
    queuePosition() {
      return this.$store.state.queue && this.$store.state.queue.position
    },
    isQueuePlaying() {
      return !!this.queueLength
    },
    isFirstPosition() {
      return this.isQueuePlaying && this.queuePosition === 0
    },
    isLastPosition() {
      return this.isQueuePlaying && this.queuePosition === this.queueLength - 1
    },
    timeFraction() {
      return this.isMixPlaying && this.playback.currentTime && this.playback.currentTime / this.playback.duration
    },
    duration() {
      return this.isMixPlaying && this.playback.duration > 0 && formatMs(this.playback.duration)
    },
    currentTime() {
      return this.isMixPlaying && this.playback.currentTime >= 0 && formatMs(this.playback.currentTime)
    },
    channelsNotPlaying() {
      if (this.isChannelPlaying) {
        return this.$store.state.channels.channels.filter(el => el.id !== this.resource.id)
      }
      return this.$store.state.channels.channels
    },
    mix() {
      return this.$store.state.playback.mix
    },
    status() {
      return this.$store.state.playback.status
    },
    volume: {
      get() {
        return this.$store.state.settings.volume
      },
      set(value) {
        // fix for hard to hit 100%
        if (value > 95 && this.$store.state.settings.volume > 95) {
          return this.$playerService.setVolume(100)
        }
        return this.$playerService.setVolume(value)
      },
    },
    volumeIcon() {
      if (this.volume > 75) return 'volume_up'
      if (this.volume > 25) return 'volume_down'
      if (this.volume > 0) return 'volume_mute'

      return 'volume_off'
    },
    isQueueActive() {
      return !!this.$store.state.playback.resource || this.isQueuePlaying
    },
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },
  watch: {
    volume() {
      if(volumeDisplayTime) {
        clearTimeout(volumeDisplayTime)
        volumeDisplayTime = null
      }
      this.isVolumeActive = true
      volumeDisplayTime = setTimeout(() => {
        this.isVolumeActive = false;
      }, 1000)

    }
  },
  methods: {
    playRadioChannel(channel) {
      if(!this.loggedIn) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('PLAYER_LOGIN_SIGNUP'), type: 'warning' })
        return
      }

      this.$queueService.playRadioChannel(channel.id)
    },
    mute() {
      this.$playerService.toggleMute()
    },
    setVolume({ offsetY }) {
      this.volume = 100 - offsetY
    },
    seekTime({ offsetX, srcElement }) {
      this.$playerService.seekTime((offsetX / srcElement.offsetWidth) * this.playback.duration)
    },
    toggleQueueDialog() {
      if (this.isQueueActive) {
        const { name, params, query } = this.$route
        if (query.open === 'queue') {
          const { open, ...newQuery } = query
          this.$router.push({ name, params, newQuery })
        } else {
          this.$router.push({
            name,
            params,
            query: { ...query, open: 'queue' },
          })
        }
      }
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.main">

      <div :class="$style.selector">
        <div :class="[$style.channels, $style.selectorDropdown]">
          <div
            v-if="isChannelPlaying"
            :class="[$style.channelsItem, $style.channelsItemActive]"
            @click="$queueService.pause()"
          >
            <BaseIcon
              :class="$style.channelsIcon"
              :name="(playback.status === 'playing' || playback.status === 'loading') ? 'pause' : 'play_arrow'"
            /> {{ resource.title }}
          </div>
          <div
            v-for="channel in channelsNotPlaying"
            :key="channel.id"
            :class="$style.channelsItem"
            @click="playRadioChannel(channel)"
          >

            <BaseIcon
              :class="$style.channelsIcon"
              name="play_arrow"
            /> {{ channel.title }}
          </div>
        </div>
        <div :class="$style.selectorTrigger">

          <div
            v-if="isChannelPlaying"
            :class="[$style.selectorChannel]"
          >
            {{ resource.title }}
          </div>
          <div :class="$style.selectorTitle">
            {{ playback.resourceType === 'mix' ? 'Mix on Demand' : playback.resourceType === 'playlist' ? 'Playlist' : 'Radio Channel' }}
          </div>
          <BaseIcon
            :class="[$style.selectorIcon, $style.selectorIconLess]"
            name="expand_less"
          />
          <BaseIcon
            :class="[$style.selectorIcon, $style.selectorIconMore]"
            name="expand_more"
          />
        </div>
      </div>

      <div
        :class="$style.meta"
      >
        <div
          v-if="mix && mix.show && mix.episode"
          :class="$style.metaInner"
        >
          <BaseLink
            :class="$style.imageContainer"
            :to="{name: 'mix', params: { id: mix.id }}"
          >
            <BaseResourceImage
              :resource="mix.episode"
              :class="$style.image"
            />
          </BaseLink>
          <BaseFavourites
            v-if="mix"
            :class="$style.favorites"
            :mix="mix"
            size="icon-only"
            button-type="transparent"
          />
          <div>
            <div :class="['title', $style.title]">{{ mix.show.title }}</div>
            <div :class="['artist', $style.artist]">
              <BaseLink :to="{path: `/artist/${mix.artist.url}/${mix.artist.id}`}">
                {{ mix.artist.title }}
              </BaseLink>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isMixPlaying"
        :class="$style.centerWrap"
      >
        <div
          :class="[$style.time, $style.timeElapsed]"
        >{{ currentTime || '' }}</div>
        <waveform
          :key="resource.id"
          :class="$style.waveform"
          :time-fraction="timeFraction"
          :duration="playback.duration"
        />
        <div
          :class="$style.time"
        >{{ duration || '' }}</div>
      </div>

      <div :class="[ $style.controls, $style.controlsSecondary]">
        <div :class="$style.volumeWrap">
          <div
            :class="$style.iconWrap"
            @click="mute"
          >
            <BaseIcon
              :class="[ $style.icon, $style.iconVolume ]"
              :name="volumeIcon"
              preserve-aspect-ratio="xMinYMid"
            />
          </div>
          <div :class="[$style.volumeSlider, isVolumeActive ? $style.active : '']">
            <div
              :class="$style.volumeSliderInner"
              @click.prevent.stop="setVolume"
            >
              <div
                :class="$style.volumeSliderValue"
                :style="{height: `${volume}%`}"
              />
            </div>
          </div>

        </div>
        <div :class="[$style.queueWrap, {[$style.active] : isQueueActive}]">
          <div
            :class="$style.iconWrap"
            @click="toggleQueueDialog"
          >
            <BaseIcon
              :class="[$style.icon, $style.iconQueue]"
              name="playlist_add"
            />
          </div>
        </div>
      </div>
      <div :class="[$style.controls, $style.controlsMain]">
        <div @click="$queueService.playPrevious()">
          <BaseIcon
            :class="[$style.icon, $style.iconSkip, isQueuePlaying && !isFirstPosition && $style.active]"
            name="skip_previous"
          />
        </div>
        <div
          :class="[$style.play, !resource && $style.playFaded ]"
          @click="$queueService.pause()"
        >
          <BaseIcon
            v-if="status === 'playing'"
            :class="$style.icon"
            name="pause"
          />
          <BaseIcon
            v-else-if="status === 'loading'"
            :class="[$style.icon, $style.iconSpinning]"
            name="spinner"
          />
          <BaseIcon
            v-else
            :class="$style.icon"
            name="play_arrow"
          />
        </div>

        <div @click="$queueService.playNext()">
          <BaseIcon
            :class="[$style.icon, $style.iconSkip, isQueuePlaying && !isLastPosition && $style.active]"
            name="skip_next"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $layer-overlay-z-index;
  width: 100%;
}

.main {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  height: $size-music-player;
  background: $c-grey-woodsmoke;
}

.image {
  display: block;
  width: $size-music-player;
  height: $size-music-player;

  &Container {
    display: none;

    @include desktop {
      display: block;
    }
  }
}

.selector {
  display: none;

  @include desktop {
    display: block;
  }

  &Trigger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 200px;
    height: 100%;
    padding: 0 $s-m;
  }

  &Dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $layer-very-negative-z-index;
    width: 280px;
    padding: $s-s $s-m;
    background-color: $c-black;
    transition: $t;
    transform: translate3d(0, 0%, 0);
  }

  &:hover {
    .selectorDropdown {
      transform: translate3d(0,-100%, 0);
    }
  }

  &Channel {
    text-transform: uppercase;

    @extend %contentTitleS;
  }

  &Title {
    @extend %contentSubtitleS;
  }

  &Icon {
    position: absolute;
    top: 50%;
    right: $s-s;
    display: none;
    margin-top: -0.75rem;
    fill: $c-highlight;

    @include square(1.5rem);
  }

  &IconMore {
    display: block;
  }

  &:hover &IconMore {
    display: none;
  }

  &:hover &IconLess {
    display: block;
  }
}

.channels {
  &SelectorWrap {
    width: 200px;
  }

  &Item {
    position: relative;
    padding: $s-s;
    padding-left: $s-xl + $s-m;
    text-transform: uppercase;
    cursor: pointer;

    @extend %contentTitleS;

    &Active {
      padding-bottom: $s-m;
      margin-bottom: $s-xxs;
      color: $c-highlight;
      border-bottom: 1px solid rgba($c-dusty-gray, 0.1);
    }
  }

  &Icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: $s-xl;
    height: 0.8rem;
    margin-top: -0.4rem;
    fill: $c-white;
  }
}

.meta {

  &Inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  @include desktop {
    min-width: 12rem;
  }
}

.container .favorites {
  display: flex;
  align-content: center;
  justify-content: center;
  width: $size-music-player;
  min-width: $size-music-player;
  height: $size-music-player;
  min-height: $size-music-player;
  margin-bottom: 0;
}

.centerWrap {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.waveform {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transform: translateY(-100%);

  @include desktop {
    position: relative;
    top: auto;
    left: auto;
    width: 36vw;
    max-width: 480px;
    height: 1rem;
    transform: none;
  }
}

.time {
  @extend %contentSubtitleS;

  display: none;
  width: 7em;
  padding: 0 $s-s;

  @include desktop {
    display: block;
  }

  &Elapsed {
    color: $c-white;
    text-align: right;
  }
}

.heartIcon {
  @include square(1rem);

  fill: $c-white;

  @include center;

  &Full {
    opacity: 0;
  }
}

.heart {
  position: relative;

  @include square(1.5rem);

  margin-right: 0.5rem;

  &:hover .heartIconFull {
    opacity: 1;
  }
}

.title {
  font-size: 11px;
  line-height: 14px;
  color: $c-white;
}

.artist {
  font-size: 11px;
  line-height: 14px;
  color: $c-text-faded;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  @include square(1.5rem);

  box-sizing: content-box;
  fill: $c-white;

  &Volume {
    cursor: pointer;
  }

  &Spinning {
    animation: spin 2s linear infinite;
  }

  &Skip {
    padding: 0 math.div($s-s, 2);
    fill: $c-text-faded;

    @include square($s-xl);

    &.active {
      cursor: pointer;
      fill: $c-white;
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;

  &Main {
    padding: 0 math.div($s-s, 2);
    margin-left: auto;

    @include desktop {
      margin-left: 0;
    }
  }

  &Secondary {
    display: none;
    margin-left: auto;

    @include desktop {
      display: flex;
    }

    .icon {
      padding: $s-s;
      fill: $c-grey-abbey;
    }
  }
}

.play {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 math.div($s-s, 2);
  cursor: pointer;
  background: $c-button-bg;
  border-radius: 50%;

  @include square($s-m * 2);
  .icon {
    @include square(22px);

    fill: $c-body-bg;
  }

  &Faded {
    cursor: default;
    background: $c-grey-abbey;
  }
}

.volume {
  padding: 20px 0;

  &Wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: $c-grey-woodsmoke;
    transition: $t;

    &:hover {
      background: $c-black;

      .icon {
        fill: $c-white;
      }
    }
  }

  &Slider {
    position: absolute;
    top: 0;
    z-index: $layer-negative-z-index;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: $s-s;
    padding-bottom: $s-s + $s-xs;
    overflow: hidden;
    transition: $t;
    transform: translateY(0%);

    &Inner {
      position: relative;
      z-index: $layer-page-z-index;
      box-sizing: content-box;
      width: 100%;
      height: 100px;
      padding: 0 $s-m;
      cursor: pointer;

      &::before {
        position: absolute;
        top: -$s-s;
        left: 0;
        display: block;
        width: 100%;
        height: calc(100% + #{2 * $s-s});
        pointer-events: none;
        content: '';
        background-color: $c-black;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 4px;
        height: 100%;
        margin-left: -2px;
        pointer-events: none;
        content: '';
        background-color: $c-mine-shaft;
      }
    }

    &Value {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: $layer-page-z-index;
      width: 4px;
      height: 100%;
      margin-left: -2px;
      pointer-events: none;
      background-image: $c-button-bg;
      transition: $t;
      transform: scaleY(100%);
    }
  }

  &Wrap:hover .volumeSlider{
    transform: translateY(-100%);
  }
}

.volumeSlider.active {
  transform: translateY(-100%);
}

.queueWrap {
  .iconQueue {
    fill: $c-mine-shaft;
  }
  &.active {
    cursor: pointer;
    .iconQueue {
      fill: $c-grey-abbey;
    }
    &:hover {
      background: $c-black;
      .iconQueue {
        fill: $c-white;
      }
    }
  }
}
</style>
