<script>
import formatDate from '@utils/format-date-full'
import MixOptions from '@components/mix/mix-options'

export default {
  components: { MixOptions },
  props: {
    index: {
      type: Number,
      default: -1,
    },
    mix: {
      type: Object,
      default: () => {},
    },
    buttons: {
      type: Object,
      default: () => {},
    },
    isIndexedList: {
      type: Boolean,
      default: false,
    },
    genres: {
      type: Boolean,
      default: false,
    },
    isPlaylistMix: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTileHovered: false,
    }
  },
  computed: {
    airDate() {
      if (this.mix.episode) {
        const date = new Date(this.mix.episode.air_start)
        return formatDate(date)
      }
      return ''
    },
    isMixLoaded() {
      return this.$store.state.playback.resource && this.mix.id === this.$store.state.playback.resource.id
    },
    playbackStatus() {
      return this.$store.state.playback.status === 'playing' || this.$store.state.playback.status === 'loading'
    },
    tileIndex() {
      return `${this.index + 1}`.padStart(2, "0");
    },
  },
}
</script>

<template>
  <div
    :class="[$style.container, $style.inner, $style.left, {[$style.active] : isMixLoaded && playbackStatus}]"
    @mouseover="isTileHovered = true"
    @mouseleave="isTileHovered = false">
    <div
      v-if="isIndexedList"
      :class="$style.index">
      <div :class="$style.indexMain">
        {{ tileIndex }}
      </div>
    </div>
    <div :class="[$style.containerInner, $style.inner, $style.space]">
      <div :class="[$style.inner, $style.left]">
        <div
          :class="$style.image"
          @click="$emit('play-playlist-mix')">
          <BaseResourceImage
            :resource="mix.episode"
            :playable-mix="mix"
            :has-hover-state="isTileHovered"
            is-playlist-mix
          />
        </div>
        <div>
          <div :class="$style.title">
            <BaseLink
              v-if="mix.show && mix.show.title"
              :class="$style.titleLink"
              :to="{name: 'show', params: { id: mix.show.id }}">
              {{ mix.show.title }}
            </BaseLink>
            <BasePlaceholder
              v-else
              :text="mix.show && mix.show.title"
              type="show"/>
          </div>
          <div :class="[$style.inner, $style.left]">
            <div
              :class="$style.artist">
              <div :class="[$style.artistInner]">
                <BaseLink
                  v-if="mix.artist"
                  :to="{path: `/artist/${mix.artist.url}/${mix.artist.id}/`}">
                  {{ mix.artist.title }}
                </BaseLink>
                <BasePlaceholder
                  v-else
                  :text="mix.artist && mix.artist.title"
                  type="artist"
                />
              </div>
            </div>
            <div :class="[$style.time]">
              <div :class="[$style.timeInner]">
                <BaseLink
                  v-if="mix.title"
                  :to="{name: 'mix', params: { id: mix.id }}">
                  {{ airDate }}
                </BaseLink>
                <BasePlaceholder
                  v-else
                  :text="mix.title"
                  :size="10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="[$style.inner, $style.right]">
        <div
          v-if="genres"
          :class="$style.genreList">
          <BaseButtonGenre
            v-for="genre in mix.genre.slice(0,2)"
            :key="genre"
            :genre="genre"/>
          <BaseButton
            v-if="mix.genre.length > 2"
            button-type="tagSecondary"
            size="small">
            +{{ (mix.genre.length - 2) }} MORE
          </BaseButton>
        </div>
        <div :class="[$style.buttons]">
          <BaseButton
            v-if="mix && buttons && buttons.playCount"
            :class="[$style.button, $style.buttonPlayCount]"
            icon="play_arrow"
            size="small"
            button-type="tag">
            {{ Number(mix.reach).toLocaleString() || '0' }}
          </BaseButton>
          <BaseDropdown :class="$style.button">
            <BaseButton
              slot="trigger"
              :modifiers="{ autoWidth: true, transparent: true, dots: true}"
              button-type="tag"
              aria-label="Options"
              size="small"/>
            <div slot="dropdown">
              <MixOptions :mix="mix" />
            </div>
          </BaseDropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  @extend %contentSubtitleM;

  display: flex;
  align-items: center;
  &Inner {
    flex-basis: 100%;
    justify-content: space-between;
    padding: $s-s $s-m;
  }
  &:hover {
    .containerInner {
      background: rgba($c-tuna, 0.15);
    }
  }
  &.active {
    .containerInner {
      background: rgba($c-tuna, 0.1);
    }
    .main {
      color: $c-highlight;
    }
  }
  &:active {
    .containerInner {
      background: $c-active-bg;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &.space {
    justify-content: space-between;
  }
}
.index {
  margin-right: $s-s;
  &Main {
    font-size: 16px;
    color: $c-text-faded;
  }
}
.image {
  justify-content: flex-start;
  width: 4rem;
  height: 4rem;
  margin-right: gutter(of 12 narrow);
}
.title {
  margin-bottom: $s-xxs;
  color: $c-white;

  &Link {
    &, &:visited {
      color: $c-white;
    }
  }
}

.genre {
  &List {
    display: none;

    @include desktop {
      display: flex;
      overflow: visible;
    }
  }
  + .genre {
    margin-left: $s-xs;
  }
}
.artist {
  margin-right: $s-m;
}
.time {
  position: relative;
  display: none;
  margin-left: $s-m;
  &::before {
    position: absolute;
    top: 0;
    left: -$s-l;
    font-size: 5px;
    content: '\25CF';
  }

  @include tablet {
    display: inherit;
  }
}
.dots {
  margin-left: auto;

  @include desktop {
    margin-left: 0;
  }
}
.buttons {
  display: flex;
  margin-left: auto;

  @include desktop {
    margin-left: gutter(of 12 narrow);
  }
}
.button {
  margin-left: $s-s;

  @include desktop {
    margin-left: 0;
  }
}
.button + .button {
    margin-left: $s-s;
}
.button.buttonPlayCount {
  display: none;

  @include desktop {
    display: block;
  }
}
</style>
