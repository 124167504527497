<script>
import copyToClipBoard from '@utils/copy-to-clipboard'
import SocialShare from '@components/social-share'

export default {
  components: {
    SocialShare
  },
  props: {
    playlist: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    url() {
      const protocol = `${window.location.protocol}`
      const hostname = `${window.location.hostname}`
      const port = `${window.location.port}`
      return `${protocol}//${hostname}${port ? ':' + port : ''}${this.$router.resolve({name: this.isChart() ? 'browse/charts' : 'browse/playlists', params: { id: this.playlist.id }}).href}`
    }
  },
  methods: {
    copyLinkToClipBoard() {
      copyToClipBoard(this.url)
      this.$store.dispatch('appearance/handleNotification', { message: this.$m('LINK_COPIED_CLIPBOARD'), type: 'success' })
    },
    isChart() {
      return this.playlist.type === 'chart'
    },
  }
}
</script>

<template>
  <ul :class="$style.dropdownMenu">
    <li
      :class="$style.dropdownItem"
      @click="$router.push({ name: `${isChart() ? 'browse/charts' : 'browse/playlists'}`, params: {id: playlist.id } })">
      <BaseButton
        icon="eye"
        size="medium"
        button-type="transparent"
        @click="()=>{}">&nbsp; <span :class="$style.dropdownText">View {{ isChart() ? 'Chart' : 'Playlist' }}</span></BaseButton>
    </li>
    <h6 :class="$style.shareTitle">Share</h6>
    <div :class="$style.separator"/>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="facebook"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          type="facebook" />
      </BaseButton>
    </li>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="twitter"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          :custom-text="`${playlist.revisions_title} on @friskyradio #feelinfrisky`"
          type="twitter" />
      </BaseButton>
    </li>
    <li
      :class="$style.dropdownItem"
      @click="copyLinkToClipBoard">
      <BaseButton
        icon="link"
        size="medium"
        button-type="transparent"
        @click="()=>{}">&nbsp; Copy {{ isChart() ? 'Chart' : 'Playlist' }} link
      </BaseButton>
    </li>
  </ul>
</template>

<style lang="scss" module>
@import '@design';

.dropdown{
  &Menu {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &Item {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 $s-m;
  }
}

.separator {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: $c-mine-shaft;
}

.shareTitle {
  @extend %contentTitleM;

  margin: $s-s 0 $s-s $s-xl;
}
</style>
