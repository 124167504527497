export default {
  0: {
    title: 'January',
    value: 0
  },
  1: {
    title: 'February',
    value: 1
  },
  2: {
    title: 'March',
    value: 2
  },
  3: {
    title: 'April',
    value: 3
  },
  4: {
    title: 'May',
    value: 4
  },
  5: {
    title: 'June',
    value: 5
  },
  6: {
    title: 'July',
    value: 6
  },
  7: {
    title: 'August',
    value: 7
  },
  8: {
    title: 'September',
    value: 8
  },
  9: {
    title: 'October',
    value: 9
  },
  10: {
    title: 'November',
    value: 10
  },
  11: {
    title: 'December',
    value: 11
  },
}
