<script>
import copyToClipBoard from '@utils/copy-to-clipboard'
import SocialShare from '@components/social-share'

export default {
  components: {
    SocialShare
  },
  props: {
    artist: {
      type: Object,
      default: () => {},
    },
    hasViewArtistOption: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    url() {
      const protocol = `${window.location.protocol}`
      const hostname = `${window.location.hostname}`
      const port = `${window.location.port}`
      return `${protocol}//${hostname}${port ? ':' + port : ''}${this.$router.resolve({name: 'artist', params: { artistUrl: this.artist.url, id: this.artist.id }}).href}`
    }
  },
  methods: {
    copyLinkToClipBoard() {
      copyToClipBoard(this.url)
      this.$store.dispatch('appearance/handleNotification', { message: this.$m('LINK_COPIED_CLIPBOARD'), type: 'success' })
    }
  }
}
</script>

<template>
  <ul :class="$style.dropdownMenu">
    <li
      v-if="hasViewArtistOption"
      :class="$style.dropdownItem"
      @click="$router.push({ name: 'artist', params: { artistUrl: artist.url, id: artist.id } })">
      <BaseButton
        icon="eye"
        size="medium"
        button-type="transparent"
        @click="() => {}">&nbsp; View Artist</BaseButton>
    </li>
    <div
      v-if="hasViewArtistOption"
      :class="$style.separator"/>

    <li :class="$style.dropdownItem">
      <BaseFavourites
        v-if="artist"
        :artist="artist"
        size="medium"
        button-type="transparent"
        icon-main="user-minus"
        icon-alt="user-plus">
        <span slot="main">Follow Artist</span>
        <span slot="alt">Unfollow Artist</span>
      </BaseFavourites>
    </li>
    <h6 :class="$style.shareTitle">Share</h6>
    <div :class="$style.separator"/>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="facebook"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          type="facebook" />
      </BaseButton>
    </li>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="twitter"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          :custom-text="`${artist.title} on @friskyradio #feelinfrisky`"
          type="twitter" />
      </BaseButton>
    </li>
    <li
      :class="$style.dropdownItem"
      @click="copyLinkToClipBoard">
      <BaseButton
        icon="link"
        size="medium"
        button-type="transparent"
        @click="() => {}">&nbsp;Copy Artist link</BaseButton>
    </li>

  </ul>
</template>

<style lang="scss" module>
@import '@design';

.dropdown{

  &Menu {
    padding: 0;
    margin: 0;
    text-align:left;
    list-style: none;
  }
  &Item {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 $s-m;
  }
}

.separator {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: $c-mine-shaft;
}

.shareTitle {
  @extend %contentTitleM;

  margin: $s-s 0 $s-s $s-xl;
}
</style>
