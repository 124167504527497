<script>
import ShowOptions from '@components/show/show-options'
import formatTime from '@utils/format-time'
import formatTimeAMPM from '@utils/format-time-am-pm'
import formatDate from '@utils/format-date'

export default {
  components: {
    ShowOptions,
  },
  props: {
    artwork: {
      type: Boolean,
      default: false
    },
    playlistItem: {
      type: Object,
      default: () => {
        return {
          show: {
            id: 'id',
            title: 'showTitle',
            url: 'showUrl',
            type: 'show',
            image: {},
            genre: [],
            artist_id: {},
            artist: {}
          }
        }
      }
    }
  },
  data() {
    return {
      isTileHovered: false
    }
  },
  computed: {
    mix() {
      return this.playlistItem.mixes
    },
    show() {
      return this.playlistItem.mixes.show? this.playlistItem.mixes.show : false
    },
    airTime() {
      const date = new Date(this.playlistItem.scheduled_start_time)
      return (this.artwork ? formatTimeAMPM(date) : formatTime(date)) || ''
    },
    airDate() {
      const date = new Date(this.playlistItem.scheduled_start_time)
      return formatDate(date) || ''
    },
    playbackStatus() {
      return this.$store.state.playback.status === 'playing' || this.$store.state.playback.status === 'loading'
    },
    isMixLoaded() {
      return this.$store.state.playback.resource && this.mix.id === this.$store.state.playback.resource.id
    }
  },
}
</script>

<template>
  <div
    :class="[ $style.upcoming, artwork && $style.artwork, {[$style.active] : isMixLoaded && playbackStatus} ]"
    @mouseover="isTileHovered = true"
    @mouseleave="isTileHovered = false">
    <div :class="$style.dateTime">
      <BaseLink
        v-if="mix && mix.id"
        :class="['title', $style.time]"
        :to="{name: 'mix', params: { id: mix.id }}">
        {{ airTime }}
      </BaseLink>
      <BasePlaceholder
        v-else
        :text="airTime"
        :size="3"/>
      <div
        v-if="playlistItem.premiere"
        :class="$style.new">
        New
      </div>
      <div
        v-if="artwork"
        :class="$style.date">
        <BaseLink
          v-if="mix && mix.id"
          :to="{name: 'mix', params: { id: mix.id }}">
          {{ airDate }}
        </BaseLink>
        <BasePlaceholder
          v-else
          :text="airDate"/>
      </div>
    </div>

    <BaseResourceImage
      v-if="artwork"
      :resource="show"
      :class="$style.image"
      :playable-mix="mix"
      :has-hover-state="isTileHovered"/>
    <div
      v-if="mix"
      :class="$style.show">
      <BaseLink
        v-if="mix.show && mix.show.title "
        :class="['title', $style.title, $style.showLink]"
        :to="{name: 'show', params: { id: mix.show.id }}">
        {{ mix.show.title }}
      </BaseLink>
      <BasePlaceholder
        v-else
        :class="['title', $style.title, $style.showLink]"
        :text="mix.show ? mix.show.title : ''"
        type="show"
        tag="div"/>
      <div :class="['subtitle', $style.artist]">
        <BaseLink
          v-if="mix.artist"
          :to="{path: `/artist/${mix.artist.url}/${mix.artist.id}`}">
          {{ mix.artist.title }}
        </BaseLink>
        <BasePlaceholder
          v-else
          type="artist"/>
      </div>
    </div>
    <div
      :class="$style.options"
      @click.prevent="()=>{}">
      <BaseDropdown :padded="false">
        <BaseButton
          slot="trigger"
          :modifiers="{ autoWidth: true, transparent: true, dots: true}"
          button-type="tag"
          aria-label="Options"
          size="small"/>
        <div slot="dropdown">
          <ShowOptions :show="mix.show" />
        </div>
      </BaseDropdown>
    </div>
  </div>
</template>


<style lang="scss" module>
@import '@design';

.upcoming {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: $s-s;
  padding-bottom: $s-s;
  margin: 0;
  border-bottom: 1px solid $c-grey-woodsmoke;
  &:hover {
    background: rgba($c-tuna, 0.15);
  }
  &.active {
    background: rgba($c-tuna, 0.1);
  }
}
.dateTime {
  display: inline-block;
  width: 40px;
  min-height: 38px;

  .artwork & {
    width: auto;

    @include desktop {
      width: span(1 of 12 narrow);
    }
  }
}
.time {
  display: inline-block;
  margin: 0 0 $s-xxs;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  vertical-align: top;
  &,
  &:visited {
    color: $c-white;
  }

  .active & {
    color: $c-highlight;
  }
}
.upcoming:nth-of-type(1) {
  .time {
    color: $c-text;
  }
}
.new {
  max-width: 40px;
  padding: 2px 0;
  font-size: 8px;
  font-weight: bold;
  line-height: 10px;
  color: $c-razzmatazz;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.17px;
  border: 2px solid $c-razzmatazz;

  .artwork & {
    @include desktop {
      display: none;
    }
  }
}
.show {
  display: inline-block;
  width: 70%;
  padding-left: $s-l;
  margin-right: auto;
  vertical-align: top;

  &Link {
    display: block;

    &,&:visited {
      color: $c-white;
    }

  }

  .artwork & {
    @include desktop {
      display: flex;
    }
  }

  .artwork &Link {
    @include desktop {
      min-width: 55%;
    }
  }
}
.title {
  @extend %contentTitleM;

  max-width: 220px;
  margin: 0 0 $s-xxs;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  .active & {
    color: $c-highlight;
  }
}
.artist {
  @extend %contentSubtitleM;

  max-width: 100%;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

@media (min-width: $desktop) {
  .time {
    color: $c-text;
  }
}

.image {
  display: none;
  width: 2rem;
  margin-left: gutter(of 12 narrow);
  pointer-events: auto;
  cursor: pointer;

  @include tablet {
    display: block;
  }
}

.date {
  display: none;

  @extend %contentSubtitleS;

  @include desktop {
    display: block;
    white-space: nowrap;
  }
}
</style>
