<script>
import DataLayer from '@services/data-layer/index'

export default {
  props: {
    imageResource: {
      type: [Object, Boolean],
      default: false,
    },
    imageType: {
      type: String,
      default: 'album_art',
    },
    mediaResource: {
      type: [Object, Boolean],
      default: false,
    },
    mediaType: {
      type: [String, Boolean],
      default: false
    },
    fill: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasArt() {
      return this.imageResource && this.imageResource[this.imageType] && this.imageResource[this.imageType].url
    },
    playbackStatus() {
      return this.$store.state.playback.status
    },
    isMediaLoaded() {
      return this.$store.state.playback.resource && (this.mediaResource.id === this.$store.state.playback.resource.id || this.mediaResource.id === this.$store.state.queue.playlistId) && this.mediaType === this.$store.state.playback.resource.type
    },
  },
  methods: {
    playMedia(e) {
      if(!this.$store.getters['auth/loggedIn']) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('PLAYER_LOGIN_SIGNUP'), type: 'warning' })
        return
      }
      if (this.isMediaLoaded) {
        return this.$queueService.pause()
      }
      if(this.mediaType === 'mix') {
        this._playMix()
      }else if(this.mediaType === 'playlist') {
        this._playPlaylist()
      }else if(this.mediaType === 'channel') {
        this._playChannel()
      }
    },
    _playMix() {
      this.$queueService.playMix(this.mediaResource)
        .catch(err => {
          console.error(err)
        })
    },
    _playChannel() {
      this.$queueService.playRadioChannel(this.mediaResource.id)
    },
    _playPlaylist(position = 0) {
      DataLayer.playlists.getRevisionsMixes(this.mediaResource.id, this.mediaResource.mixes_count || 100, 0)
        .then(res => {
          const mixes = res.body.map(revisionsMixes => revisionsMixes.mixes)
          this.$queueService.playMixMultiple({ mixes: mixes, id: this.mediaResource.id, position: position })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
  },
}
</script>

<template>
  <div :class="[$style.container, isMediaLoaded && (playbackStatus === 'playing' || playbackStatus === 'loading') && $style.containerPlaying, {[$style.containerFill] : hasArt && fill }, {[$style.containerCentered] : centered }]">
    <div
      v-if="mediaResource"
      :class="$style.iconContainer"
    >
      <div
        :class="$style.action"
        @click.prevent="()=>{}">
        <div v-if="$slots.primaryActions">
          <BaseDropdown :padded="false">
            <BaseButton
              slot="trigger"
              :modifiers="{ autoWidth: true, transparent: true }"
              button-type="tag"
              aria-label="Options"
              size="small"/>
            <div slot="dropdown">
              <slot name="primaryActions"/>
            </div>
          </BaseDropdown>
        </div>
      </div>
      <div @click.prevent="playMedia">
        <BaseIcon
          v-if="isMediaLoaded && playbackStatus === 'playing'"
          :class="$style.iconPlay"
          name="pause"/>
        <BaseIcon
          v-else-if="isMediaLoaded && playbackStatus === 'loading'"
          :class="[$style.iconPlay, $style.iconSpinning]"
          name="spinner"/>
        <BaseIcon
          v-else
          :class="$style.iconPlay"
          name="play-reverse"/>
      </div>
      <div
        :class="$style.action"
        @click.prevent="()=>{}">
        <div v-if="$slots.secondaryActions">
          <BaseDropdown :padded="false">
            <BaseButton
              slot="trigger"
              :modifiers="{ autoWidth: true, transparent: true, dots: true }"
              button-type="tag"
              aria-label="Options"
              size="small"/>
            <div slot="dropdown">
              <slot name="secondaryActions"/>
            </div>
          </BaseDropdown>
        </div>
      </div>
    </div>
    <BaseResourceImage
      v-if="hasArt"
      :resource="imageResource"
      :type="imageType"
      :shouldLazyload="false"
      :class="[$style.image, {[$style.imageFill] : fill}, {[$style.imageCentered] : centered }]"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.icon {
  &Container {
    @include fill;

    z-index: $layer-highlight-z-index;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    background: $c-frisky-channel-grad-transparent;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &Play {
    z-index: $layer-tooltip-z-index;
    max-width: $s-xxl;
    max-height: $s-xxl;
    border-radius: 50%;
    fill: $c-white;
  }
  &Spinning {
    height: 100%;
    line-height: 230px;
    opacity: 0.3;
    animation: spin 2s linear infinite;
  }
}

.action {
  width: $s-xxl;
  min-height: 1px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.container {
  position: relative;
  width: 100%;

  &Fill {
    padding-bottom: 100%;
    background: $c-classics-channel-grad;
  }

  &Centered {
    height: 100%;
    background: $c-classics-channel-grad;
  }

  &:hover {
    .action {
      pointer-events: unset;
      opacity: 1;
    }
  }

  &Playing {
    z-index: $layer-overlay-z-index;
    .iconContainer {
      opacity: 1;
    }
  }
  &:hover {
    z-index: $layer-dropdown-z-index;
    .iconContainer {
      opacity: 1;
    }
  }
}

.image {
  display: inline-block;
  max-width: 100%;

  &Fill {
    @include fill;
  }

  &Centered {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;

    @include center;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
