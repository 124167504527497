export default asyncFunction => {
  const queue = []

  function removeFromQueue(id) {
    queue.splice(queue.findIndex(el => el.id === id), 1)
  }

  function addToQueue(id, obj) {
    const promise = asyncFunction(obj)
    queue.push({
      id,
      promise,
    })

    promise.then(() => removeFromQueue(id), () => removeFromQueue(id))

    return promise
  }

  function add(id, obj = id) {
    const found = queue.find(el => el.id === id)

    return found ? found.promise : addToQueue(id, obj)
  }

  return add
}
