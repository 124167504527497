import localSave from '@utils/local-save'

export const state = {
  volume: localSave.get('volume') !== null ? localSave.get('volume') : 100,
  favorites: localSave.get('favorites') || [],
  onDemandQuality: localSave.get('onDemandQuality') || '96'
}

export const getters = {}

export const mutations = {
  SET_SETTINGS(state, { volume, favorites, onDemandQuality }) {
    if (volume !== undefined) {
      state.volume = volume
      localSave.save('volume', state.volume)
    }

    if (favorites !== undefined) {
      state.favorites = favorites
      localSave.save('favorites', state.favorites)
    }

    if(onDemandQuality !== undefined) {
      state.onDemandQuality = onDemandQuality
      localSave.save('onDemandQuality', state.onDemandQuality)
    }
  },
}

export const actions = {
  // logIn({ commit, dispatch, getters, rootGetters }, fields = {}) {
  toggleToFavorites({ commit, state }, channelId) {
    const foundIndex = state.favorites.findIndex(el => el === channelId)
    if (foundIndex > -1) {
      const favorites = [...state.favorites]
      favorites.splice(foundIndex, 1)
      commit('SET_SETTINGS', { favorites })
    } else {
      commit('SET_SETTINGS', { favorites: [...state.favorites, channelId] })
    }
  },
}
