<script>
export default {
  name: 'BaseHeroBackground',
  props: {
    resource: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'album_art',
    },
    isGreyscaled: {
      type: Boolean,
      default: false,
    },
    isBlurred: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div
    v-if="resource"
    :class="$style.background"
  >
    <BaseResourceImage
      :class="$style.image"
      :style="`filter: ${isGreyscaled ? 'grayscale(100%)' : ''} ${isBlurred ? 'blur(9px)' : ''};`"
      :resource="resource"
      :type="type"
      :should-lazyload="false"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 6rem);
  margin-top: -$size-menu-bar-height;
  overflow: hidden;

  &::after {
    @include fill;

    top: unset;
    bottom: 0;
    height: 50%;
    content: '';
    background: linear-gradient(
      180deg,
      transparent 0%,
      transparentize($c-grey, 0.5) 50%,
      transparentize($c-grey, 0) 100%
    );
  }
}

.image {
  z-index: $layer-negative-z-index;
  width: calc(100% + 4rem);
  max-width: unset !important;
  height: 100%;
  margin-left: -2rem;
  object-fit: cover;
  object-position: center;
}
</style>
