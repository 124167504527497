var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g({class:[
    _vm.$style.button,
    _vm.isLabel ? _vm.$style.cannotBeHovered : '',
    _vm.$style[_vm.size],
    _vm.$style[_vm.buttonType],
    _vm.modifiers.fill ? _vm.$style.fill : '',
    _vm.modifiers.dots ? _vm.$style.dots : '',
    _vm.modifiers.autoWidth ? _vm.$style.autoWidth : '',
    _vm.modifiers.noMargins && _vm.$style.noMargins,
    _vm.modifiers.roundCorners && _vm.$style.roundCorners,
    _vm.active ? _vm.$style.active : '' ]},_vm.$listeners),[_c('span',{staticClass:"helper"}),(_vm.icon)?_c('BaseIcon',{class:[_vm.$style.icon, _vm.icon === 'signal' ? _vm.$style.iconSignal : '', _vm.size === 'iconOnly' ? _vm.$style.iconOnly : ''],attrs:{"name":_vm.icon}}):_vm._e(),(_vm.size !== 'icon')?_c('span',{class:_vm.$style.main},[_vm._t("default")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }