<script>
import LazyLoad from "./../directives/LazyLoad";

export default {
  directives: {
    lazyload: LazyLoad,
  },
  props: {
    resource: {
      type: [Object, Boolean],
      default: false,
    },
    type: {
      type: String,
      default: 'album_art',
    },
    width: {
      type: [Boolean, Number],
      default: false,
    },
    height: {
      type: [Boolean, Number],
      default: false,
    },
    fill: {
      type: Boolean,
      default: true,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    playableMix: {
      type: [Object, Boolean],
      default: false,
    },
    hasHoverState: {
      type: Boolean,
      default: false,
    },
    isBackground: {
      type: Boolean,
      default: false
    },
    playlistId: {
      type: Number,
      default: null
    },
    isPlaylistMix: {
      type: Boolean,
      default: false
    },
    queueIndex: {
      type: [Number, Boolean],
      default: false
    },
    shouldLazyload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasArt() {
      return this.resource && this.resource[this.type] && this.resource[this.type].url
    },
    isMixLoaded() {
      return this.$store.state.playback.resource && this.playableMix.id === this.$store.state.playback.resource.id
    },
    playbackStatus() {
      return this.$store.state.playback.status === 'playing' || this.$store.state.playback.status === 'loading'
    },
    isPlaylistLoaded() {
      return !!this.playlistId && this.$store.state.queue.playlistId === this.playlistId
    },
    isQueueMix() {
      return this.queueIndex !== false && this.queueIndex >= 0
    }
  },
  methods: {
    playMix() {
      if (!this.isPlaylistMix && this.isMixLoaded) {
        return this.$queueService.pause()
      }
      this.queueIndex && this.$queueService.setQueuePosition(this.queueIndex)
      return !this.playlistId && !this.isPlaylistMix && this.$queueService.playMix(this.playableMix, 0, !this.isQueueMix)
        .catch(e => {
          console.error(e)
          this.$store.dispatch('appearance/handleNotification', { message: e.message, type: 'warning' })
        })
    },
  },
}
</script>

<template>
  <div>
    <div

      :class="[$style.container, (isMixLoaded || isPlaylistLoaded) && playbackStatus && $style.containerPlaying, {[$style.hover] : hasHoverState}, {[$style.containerFill] : fill }, {[$style.containerCentered] : centered }]">
      <div
        v-if="(playableMix && playableMix.title) || playlistId"
        :class="$style.iconContainer"
        @click="playMix">
        <BaseIcon
          v-if="(isMixLoaded || isPlaylistLoaded) && playbackStatus"
          :class="$style.iconPlay"
          name="pause"/>
        <BaseIcon
          v-else
          :class="$style.iconPlay"
          name="play_arrow"/>

        <svg
          :class="$style.iconCircle"
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1">
          <circle
            cx="25"
            cy="25"
            r="23"
            stroke="white"
            stroke-width="3px"
            fill="none"/></svg></div>
      <div 
        v-lazyload="shouldLazyload" 
        :class="[$style.imageContainer, { 'lazy': !isBackground && shouldLazyload }]">
        <img
          v-if="hasArt && !isBackground"
          :width="width"
          :height="height"
          :data-src="resource[type].url"
          :src="!shouldLazyload ? resource[type].url : ''"
          :alt="`${resource.title || resource.heading} art`"
          :class="[$style.image, {[$style.imageFill] : fill}, {[$style.imageCentered] : centered }, 'realImage']" >
      </div>
      <div
        v-if="hasArt && isBackground"
        :class="[$style.image, $style.imageBackground]"
        :style="{backgroundImage: `url(${resource[type].url})`}"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.icon {
  &Container {
    @include fill;

    z-index: $layer-highlight-z-index;
    text-align: center;
    cursor: pointer;
    background: $c-frisky-channel-grad-transparent;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &Circle {
    @include center;
    @include square(78px);

    max-width: 90%;
    max-height: 90%;
  }

  &Play {
    @include center;
    @include square(67%);

    z-index: $layer-tooltip-z-index;
    max-width: 36px;
    max-height: 36px;
    border-radius: 50%;
    fill: $c-white;
  }
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;

  &Fill {
    padding-bottom: 100%;
    background: $c-classics-channel-grad;
  }

  &Centered {
    height: 100%;
    background: $c-classics-channel-grad;
  }

  &Playing,
  &.hover,
  &:hover {
    .iconContainer {
      opacity: 1;
    }
  }
}

.image {
  display: inline-block;
  max-width: 100%;
  object-fit: cover;

  &Fill {
    @include fill;
  }

  &Centered {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;

    @include center;
  }

  &Background {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
}
</style>

<style lang="scss">
.lazy {
    opacity: 0;
    transition: opacity 50ms ease-in-out;
  &.loaded {
    opacity: 1;
  }
}
</style>
