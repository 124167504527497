<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Number,
      default: 6,
    },
    more: {
      type: Number,
      default: 5,
    },
    max: {
      type: [Number, Boolean],
      default: false,
    },
    getFc: {
      type: Function,
      default: null,
      required: true,
    },
    updateFc: {
      type: Function,
      default: null,
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
      required: true,
    },
  },
  data() {
    return {
      visibleAdjusted: this.visible,
    }
  },
  methods: {
    showMore() {
      this.getFc(this.id, this.more, this.visibleAdjusted)

      this.updateFc()

      this.visibleAdjusted += this.more
    },
  },
}
</script>

<template>
  <div
    v-if="list.length >= visibleAdjusted && (max ? list.length < max : true)"
    :class="$style.loadMoreButton"
    @click="showMore">
    <span>Load more</span>
    <BaseIcon
      :class="[ $style.icon, $style.iconArrowDown ]"
      name="arrow-long-down"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.loadMoreButton {
  margin-top: 25px;
  text-align: right;
  cursor: pointer;

  @extend %contentTextButton;
}

.iconArrowDown {
  width: 36px;
  height: 16px;
  vertical-align: middle;
  fill: $c-highlight;
}
</style>
