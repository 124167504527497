import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('elastic', {
  search(keywords, limit = 30, offset = 0) {
    return Api.elastic.search({query: keywords, limit, offset})
  },
})

export default function searchCreator({ storage }) {

  const getRecent = function() {
    const recentSearches = storage.recentSearch.get().array
    return recentSearches || []
  }

  const addToRecent = function(item) {
    let recent = storage['recentSearch'].get().array
    let duplicate = recent.find(el => item.id === el.id)
    duplicate && storage['recentSearch'].remove(duplicate.id)
    recent && recent.length > 4 && !duplicate && storage['recentSearch'].remove(recent[0].id)
    storage['recentSearch'].add(item)
  }

  const clearRecent = function() {
    storage['recentSearch'].clear()
  }

  return {
    getRecent,
    addToRecent,
    clearRecent
  }
}
