export const state = {
  activeObject: null,
  hasIncreasedReach: false,
}

export const getters = {}

export const mutations = {
  SET_ACTIVE(state, show) {
    state.hasIncreasedReach = false;
    state.activeObject = show;
  },
  SET_INCREASED_REACH(state, status) {
    state.hasIncreasedReach = status;
  }
}

export const actions = {
  increaseReach({ commit, state }) {
    if(state.hasIncreasedReach) return;
    const show = { ...state.activeObject };
    show.reach ++;
    commit('SET_ACTIVE', show);
    commit('SET_INCREASED_REACH', true);
  }
}
