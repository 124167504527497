import { createResource } from './storage-resource'

export default function storageCreator() {
  const resources = [
    {
      id: 'artists',
      object: true,
      useLocalStorageFirst: true,
    },
    {
      id: 'shows',
      object: true,
      useLocalStorageFirst: true,
    },
    {
      id: 'mixes',
      object: true,
      useLocalStorageFirst: true,
    },
    {
      id: 'episodes',
      object: true,
      useLocalStorageFirst: true,
    },
    {
      id: 'channels',
      useLocalStorageFirst: true,
    },
    {
      id: 'schedule',
      useLocalStorageFirst: false,
    },
    {
      id: 'showSchedule',
      useLocalStorageFirst: false,
    },
    {
      id: 'nowPlaying',
      useLocalStorageFirst: false,
    },
    {
      id: 'favMixes',
      useLocalStorageFirst: false,
    },
    {
      id: 'favArtists',
      useLocalStorageFirst: false,
    },
    {
      id: 'recentSearch',
      useLocalStorageFirst: true
    },
    {
      id: 'favShows',
      useLocalStorageFirst: true,
    }
  ]

  if (window.localStorage.getItem('version') !== process.env.VUE_APP_STORAGE_VERSION) {
    resources.map(resource => {
      window.localStorage.setItem(resource.id, '')
    })
    window.localStorage.setItem('version', process.env.VUE_APP_STORAGE_VERSION)
  }

  const result = resources.reduce((acc, val) => {
    acc[val.id] = createResource(val.id, val.object, val.useLocalStorageFirst)
    return acc
  }, {})

  return result
}
