import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('versions', {

  get() {
    return Api.versions.get()
  },

});
