<script>
import DataLayer from '@services/data-layer/index'
import formatDate from '@utils/format-date-full'
import Layout from '@layouts/main'
import MixMetaTile from '@components/mix-meta-tile'
import TracklistBlock from '@components/tracklist-block'
import MixGridBlock from '@components/mix-grid-block'

export default {
  page() {
    const title = this.mix.show && this.mix.artist && this.mix.episode
          ? `${this.mix.artist.title} - ${this.mix.show.title} - ${this.airDate}`
          : `Mix`
    const description = title
    const image = this.mix && this.mix.episode && this.mix.episode.image && this.mix.episode.image.url
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },
        { property: 'og:image', vmid: 'og:image', content: image },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
        { property: 'twitter:image', vmid: 'twitter:image', content: image },
      ],
    }
  },
  components: { Layout, MixMetaTile, TracklistBlock, MixGridBlock },
  data() {
    return {
      mix: {
        id: 0,
        type: 'mix',
        genre: [],
        artist: {
          id: 0,
          type: 'artist'
        },
        track_list: Array.from(Array(7), (e,i)=> ({}))
      },
      relatedMixes: []
    }
  },
  computed: {
    airDate() {
      if (!this.mix.episode) return ''
      return formatDate(new Date(this.mix.episode.air_start))
    },
  },
  mounted() {
    Promise.all([
      DataLayer.mixes.getById(this.$route.params.id),
      DataLayer.mixes.getRelatedByMix(this.$route.params.id)
    ])
      .then(([mixModel, relatedMixes]) => {
          mixModel.body.type = "mix"
          this.mix = mixModel.body
          this.relatedMixes = relatedMixes.body
      })
  }
}
</script>

<template>
  <Layout>
    <BaseContent>
      <BaseSection
        :class="$style.container"
        padded>
        <h6>Mix</h6>
        <div :class="$style.content">
          <MixMetaTile
            :mix="mix"
            :class="$style.details" />
          <div>
            <h6 :class="$style.subtitle">Tracklist</h6>
            <TracklistBlock :tracklist="mix['track_list']"/>
          </div>
        </div>
      </BaseSection>
      <BaseSection
        v-if="relatedMixes && relatedMixes.length"
        padded
        title="Related Mixes">
        <MixGridBlock
          :mixes="relatedMixes"/>
      </BaseSection>
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.container {
  margin-top: $s-h;
}

.content {
  @include widescreen {
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-gap: $s-xxxl;
  }
}

.details {
  max-width: 300px;
  margin: 0 auto;

  @include widescreen {
    margin: unset;
  }
}

.subtitle {
  margin-top: 0;
  margin-bottom: $s-m;
}
</style>
