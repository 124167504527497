<script>
import MixGridBlock from '@components/mix-grid-block'

export default {
  components: { MixGridBlock },
  props:{
    searchResults: {
      type: Object,
      default: () => {}
    },
    searchJoin: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      INITIAL_RESOURCES: 30,
      visibleResources: 30,
      shouldLoadMore: true
    }
  },
  computed: {
    mixes() {
      return this.searchResults.Mixes
    },
    resourcesRemain() {
      return this.shows.length % this.INITIAL_RESOURCES === 0
    },
    scroll() {
      return this.$store.state.appearance.scroll
    },
    scrollChild() {
      return this.$store.state.appearance.scrollChild
    },
    keyword() {
      return this.$store.state.search.keyword.replace(/ /g, '_')
    },
  },
  watch: {

    scroll() {
      if(this.mixes.length < this.visibleResources) {
        return false
      }

      let bottomOfWindow = this.scroll + window.innerHeight >= this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight/3;
      if (bottomOfWindow && this.shouldLoadMore) {
        this.shouldLoadMore = false
        setTimeout(() => {
          this.shouldLoadMore = true
        }, 200)
        this.showMoreResults()
      }
    }
  },
  methods: {

    async elasticSearch(limit, offset) {
      this.searchJoin(limit, offset, 'Mixes').then( res => {
        this.visibleResources = res
      })
    },
    showMoreResults() {
      this.elasticSearch(this.INITIAL_RESOURCES, this.visibleResources)
    }
  }
}
</script>

<template>
  <div>
    <BaseSection
      v-if="mixes"
      padded>
      <MixGridBlock :mixes="mixes"/>
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
