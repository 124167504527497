import ReconnectingWebSocket from 'ReconnectingWebSocket'
import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('stationsPlaylists', {

  get(channel, after, limit = 12, offset = 0) {
    return Api.stationsPlaylists.get({station: channel, after: after.toISOString(), limit, offset})
  },

  subscribe(cb) {
    NowPlaying.subscribe(cb);
    return Promise.resolve({
      meta: {
        preventModelsCaching: true,
        preventPopulation: true
      }
    });
  },

  _processNowPlayingResponse(error, message) {
    if(error) {
      return Promise.reject(error);
    }

    return Promise.resolve({
      meta: {
        model: "StationsPlaylist"
      },
      data: message
    })
  }

});


const NowPlaying = (function NowPlayingController() {
  let cachedResponse = null;
  const subscribers = [];
  const socket = new ReconnectingWebSocket(process.env.VUE_APP_API_NOW_PLAYING, null, {reconnectInterval: 4000})
  socket.addEventListener('message', function (event) {
    DataLayer.$wrap(() => Promise.resolve({
      meta: {
        model: "StationsPlaylist"
      },
      data: JSON.parse(event.data)
    }))()
      .then(response => {
        cachedResponse = response;
        subscribers.forEach(cb => cb(response));
      })
  });

  socket.addEventListener('error', function (event) {
    DataLayer.stationsPlaylists._processNowPlayingResponse(event)
  });

  return {
    subscribe: function (cb) {
      subscribers.push(cb);
      cachedResponse && cb(cachedResponse);
    }
  }
})();
