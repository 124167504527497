<script>
import DataLayer from '@services/data-layer/index'
import getAndUpdate from '@utils/get-and-update'
import svgSprite from '@components/svg-sprite'
import appConfig from '@src/app.config'
import QueueDialog from '@components/queue-dialog'
import NotificationDialog from '@components/notification-dialog'
import { getCookie, deleteCookie } from '@utils/cookies'
import localSave from '@utils/local-save'
import socialImage from '@assets/images/frisky-social-share-image.png'

const CHECK_VERSION_INTERVAL_DURATION = 1000 * 60 * 60 // 1 hour

export default {
  components: {
    svgSprite,
    QueueDialog,
    NotificationDialog,
  },

  page: {
        // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === 'function' ? title(this.$store) : title
        return title ? `${appConfig.title} | ${title}` : appConfig.title
      },
      meta: [
        { property: 'og:title', vmid: 'og:title', content: `${appConfig.title}` },
        { property: 'og:type', vmid: 'og:type', content: 'website' },
        { property: 'og:url', vmid: 'og:url', content: `${window.location.href}`},
        { property: 'og:image', vmid: 'og:image', content: socialImage},
        { property: 'fb:app_id', vmid: 'fb:app_id', content: '126928420652882' },

        { property: 'twitter:card', vmid: 'twitter:card', content: "sumary" },
        { property: 'twitter:url', vmid: 'twitter:url', content: `${window.location.href}`},
        { property: 'twitter:site', vmid: 'twitter:site', content: "@friskyradio" },
        { property: 'twitter:creator', vmid: 'twitter:creator', content: "@friskyradio" },
        { property: 'twitter:image', vmid: 'twitter:image', content: socialImage},
      ]
  },

  data() {
    return {
      lastKey: null,
      socialImage,
      checkVersionInterval: null
    }
  },

  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },

  watch: {
    loggedIn: {
      handler() {
        this.updateFavorites()
      },
      immediate: true,
    },
    routeKey (to, from) {
      this.lastKey = to
      this.updateFavorites()
    }
  },

  mounted() {
    this.lastKey = this.$route.fullPath
    this.checkAuthentication()
    getAndUpdate(input =>
      this.$store.commit('channels/SET_CHANNELS', input),
      this.$dataLayer.channels.get()
    )
    this.checkCodeVersion()
    this.checkVersionInterval = setInterval(() => {
      this.checkCodeVersion()
    }, CHECK_VERSION_INTERVAL_DURATION)
    window.addEventListener("keydown", this.$keyControlsService.reactToKey)
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.$keyControlsService.reactToKey)
    clearInterval(this.checkVersionInterval)
  },

  methods: {
    updateFavorites() {
      this.loggedIn && this.$dataLayer.favMixes.init()
      this.loggedIn && this.$dataLayer.favArtists.init()
      this.loggedIn && this.$dataLayer.favShows.init()
    },

    checkAuthentication() {
      const oauthSession = getCookie('oauthSession') || ''
      if(oauthSession) {
        const user = JSON.parse(oauthSession)
        this.$store.dispatch('auth/setUser', user)
        this.$store.dispatch('member/init', null, { root: true })
        deleteCookie({ name: 'oauthSession', domain: process.env.VUE_APP_COOKIES_DOMAIN })
        this.redirectHandler()
      }
    },

    redirectHandler() {
      const redirectURL = localSave.get('redirectURL') || '/'
      if(redirectURL !== '/') window.location.replace(redirectURL)
    },

    checkCodeVersion() {
      const localCodeVersion = localSave.get('localCodeVersion')
      let actualCodeVersion = ''
      DataLayer.versions.get().then(res => {
        actualCodeVersion = res.body.clientVersion;
        localSave.save('actualCodeVersion', actualCodeVersion)
        if (!localCodeVersion) {
          localSave.save('localCodeVersion', actualCodeVersion)
        } else if (localCodeVersion !== actualCodeVersion) {
          this.notifyForNewVersion(localCodeVersion, res.body)
        }
      })
    },

    notifyForNewVersion(oldVersion, newVersion) {
      this.$store.dispatch('appearance/handleNotification', { message: newVersion.severity === 'critical' ?  this.$m('CRITICAL_UPDATE_AVAILABLE') : this.$m('UPDATE_AVAILABLE'), type: 'warning', hasReload: true, isPersistentNotification: true, forceUpdate: newVersion.severity === 'critical' })
    },
  },
}
</script>

<template>
  <div id="app">
    <svg-sprite/>
    <router-view />
    <QueueDialog/>
    <NotificationDialog v-if="$store.state.appearance.isNotificationOpen"/>
  </div>
</template>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern
@use '@design' as *;
@import '~nprogress/nprogress.css';
@import url('https://use.typekit.net/gom4uqy.css');

@include init;

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
width: $size-scrollbar-width;
}
::-webkit-scrollbar-track {
  opacity: 0;
}
::-webkit-scrollbar-thumb {
  min-height: $s-xxl;
  background-color: $c-tuna;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 16px;
}

body {
  background: $c-body-bg;
}

option {
  color: $c-white;
  background: $c-grey-woodsmoke;
}

// ===
// Vendor
// ===

#nprogress .bar {
  height: 5px;
  background: $c-link-text;
  will-change: transform;
}
#nprogress .peg {
  box-shadow: 0 0 10px $c-link-text, 0 0 5px $c-link-text;
}
</style>
