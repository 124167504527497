<script>
import BfhsTile from '@components/bfhs/bfhs-tile'
export default {
  components: {
    BfhsTile
  },
  props: {
    bfhs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div :class="[$style.bfhs, 'bfhs']">
    <carousel
      :per-page="1"
      :pagination-enabled="true"
      :navigation-enabled="false"
      :resistance-coef="50"
      :scroll-per-page="false"
      :min-swipe-distance="200"
      :autoplay="false"
      :loop="true"
      :autoplay-timeout="5000"
      :class="[$style.carousel, 'carousel']">
      <slide
        v-for="(bfh, index) in bfhs"
        :class="$style.slide"
        :key="'bfh' + bfh.id + index">
        <BfhsTile :bfh="bfh"/>
      </slide>
    </carousel>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.bfhs {
  position: relative;
  width: 100%;
  min-height: 554px;
  margin-top: -$size-menu-bar-height;
}

@include desktop {
  .bfhs {
    margin-top: -$s-main-desktop-top;
  }
}
</style>

<style lang="scss">
@import '@design';

// Force-Bypass vue-carousel plugin's styles
// stylelint-disable
.bfhs .VueCarousel-pagination {
  position: relative;
  bottom: $s-h;
  display: none;
  width: 100%;
  text-align: left;
  @include sidePadding;
}
// BYPASS PROGRAMMATICALLY ELEMENT STYLING INJECTED BY VUE-CAROUSEL PLUGIN
.bfhs .VueCarousel-dot-container {
  margin-top: 0 !important;
}
.bfhs .VueCarousel-dot {
  width: $s-l !important;
  height: $s-l !important;
  margin-top: 0 !important;
  padding: 0 !important;
  background-color: $c-bfh-dot !important;
  &~button {
    margin-left: $s-xs;
  }
  &--active {
    background-color: $c-bfh-dot-active !important;
  }
}

@include desktop {
  .bfhs .VueCarousel-pagination {
    @include container;
    display: block;
  }
}
// stylelint-enable
</style>
