export const state = {
  activeObject: null,
}

export const getters = {}

export const mutations = {
  SET_ACTIVE(state, mix) {
    state.activeObject = mix
  },
}

export const actions = {}
