const Promise = require('bluebird')

export default function nowPlayingCreator({ storage, api, resources }) {
  let events = null

  const returnObj = { value: [], promise: null }

  let isComplete = false

  const createPromise = function() {
    return new Promise(function(resolve, reject) {
      function eventHandler(e) {
        events.off('dataUpdate', eventHandler)
        e && storage.nowPlaying.update(e)
        returnObj.value = grab()
        returnObj.promise = createPromise()
        resolve(returnObj)
      }
      const mixesPromise = resources.mixes.getPromise()
      mixesPromise &&
        mixesPromise.then(() => {
          eventHandler()
        })
      events.on('dataUpdate', eventHandler)
    })
  }

  function grab() {
    const nowPlayingSchedule = storage.nowPlaying
      .get()
      .array.sort((a, b) => {
        return +a.air_time - +b.air_time
      })
      .map(el => {
        return {
          ...el,
          mix: el.mixes_id ? resources.mixes.get(el.mixes_id).value : null,
        }
      })

    isComplete = resources.episodes.isExtendedList(nowPlayingSchedule)
    return nowPlayingSchedule
  }

  return {
    getPromise() {
      return returnObj.promise
    },
    grab,
    isComplete() {
      return isComplete
    },
    get() {
      if (!events) {
        events = api.nowPlaying.get()
        returnObj.promise = createPromise()
      }
      return returnObj
    },
  }
}
