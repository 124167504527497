<script>
import MixGridBlock from '@components/mix-grid-block'
import GenresList from '@components/genres-list'
import ArtistBlock from '@components/artist/artist-block'
import ShowGridBlock from '@components/show-grid-block'
import MixTableBlock from '@components/mix-table-block.v2'
import BestMatch from '@components/search/best-match'
import extractTracklist from '@utils/extract-tracklist'
import extractGenres from '@utils/extract-genres'

export default {
  components: { MixGridBlock, GenresList, ArtistBlock, ShowGridBlock, MixTableBlock, BestMatch },
  props:{
    searchResults: {
      type: Object,
      default: () => {}
    },
    bestMatchType: {
      type: String,
      default: ''
    },
    bestMatchModel: {
      type: [Object, Array],
      default: undefined
    }
  },
  computed: {
    searchResultsData() {
      return this.searchResults
    },
    hasBestMatch() {
      return !!this.bestMatchType
    },
    mixes() {
      return this.searchResultsData.Mixes.slice(0, 6)
    },
    artists() {
      return this.searchResultsData.Artists.slice(0, 6)
    },
    shows() {
      return this.searchResultsData.Shows.slice(0, 4)
    },
    tracklist() {
      return this.mixes && extractTracklist(this.mixes, 5)
    },
    genres() {
      return this.mixes && extractGenres(this.mixes)
    }
  }
}
</script>

<template>
  <div >
    <BaseSection
      v-if="hasBestMatch && bestMatchType !== 'TrackLists'"
      :class="$style.bestMatch">
      <h5 :class="$style.title">Best Match</h5>
      <BestMatch
        :best-match-model="bestMatchModel"
        :best-match-type="bestMatchType"/>
    </BaseSection>

    <BaseSection
      v-if="mixes"
      :show-all-link="{name: 'search/results/mixes', params: { keyword: $route.params.keyword }}"
      title="Mixes"
    >
      <MixGridBlock
        :mixes="mixes"
        scroll
        scroll-mobile-only
        padded/>
    </BaseSection>

    <BaseSection
      v-if="genres"
      :show-all-link="{name: 'search/results/genres', params: { keyword: $route.params.keyword }}"
      title="Genres"
    >
      <GenresList
        :genres="genres"
        hor-scroll
        hor-scroll-mobile
        padded/>
    </BaseSection>

    <BaseSection
      v-if="artists"
      :show-all-link="{name: 'search/results/artists', params: { keyword: $route.params.keyword }}"
      title="Artists">
      <ArtistBlock
        :artists="artists"
        overflowed-scrolling
        wrapping/>
    </BaseSection>

    <BaseSection
      v-if="shows"
      :show-all-link="{name: 'search/results/shows', params: { keyword: $route.params.keyword }}"
      title="Shows">
      <ShowGridBlock
        :shows="shows"
        scroll
        scroll-mobile-only
        padded/>
    </BaseSection>

    <BaseSection
      v-if="mixes && mixes.length"
      :show-all-link="{name: 'search/results/tracklist', params: { keyword: $route.params.keyword }}"
      title="Tracklist"
      padded>
      <MixTableBlock
        :mixes="mixes"
        genres
        is-indexed-list/>
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.bestMatch {
  text-align: center;

  @include desktop {
    text-align: unset;
  }
}

.title {
  @include desktop {
    margin: 0 0 $s-xl $size-grid-padding * 2;
  }

  @include h5;
}
</style>
