<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      }
    },
  },
}
</script>

<template>
  <div :class="$style.selectContainer">
    <select
      :value="value"
      :class="$style.select"
      v-on="listeners"
    >
      <slot/>
    </select>
    <div :class="$style.arrow">
      <BaseIcon
        :class="[$style.icon, $style.iconMore]"
        name="expand_more"/>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.selectContainer {
  position: relative;
  overflow: hidden;
  background: $c-mirage-grey;
  border-radius: $size-input-border-radius;
}

.select {
  @extend %input;

  position: relative;
  z-index: 1;
  max-width: unset;
  padding-right: 0;
  margin-bottom: 0;
  background: transparent;
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  height: $size-input-height;
  padding: 0 $s-s;
}

.icon {
  @include square($s-l);

  fill: $c-bali-hai;
}
</style>
