const Promise = require('bluebird')

export default function scheduleCreator({ storage, api, resources }) {
  let dataPromise = null

  const getPromise = function() {
    return dataPromise || resources.mixes.getPromise()
  }

  let isComplete = false

  return {
    getPromise,
    fetch(id, offset, range, time) {
      if (dataPromise) {
        return dataPromise
      }

      dataPromise = api.schedule.get(id, offset, range, time).then(array => {
        storage.schedule.join(array)
        dataPromise = null
        return storage.schedule.get().array
      })

      return dataPromise
    },
    promise: null,
    update() {},
    isComplete() {
      return isComplete
    },
    grab() {
      const storageSchedule = storage.schedule
        .get()
        .array.sort((a, b) => {
          return +a.scheduled_start_time - +b.scheduled_start_time
        })
        .map(el => {
          return {
            ...el,
            mix: el.mix_id ? resources.mixes.get(el.mixes_id).value : null,
          }
        })

      isComplete = resources.episodes.isExtendedList(storageSchedule)
      return storageSchedule
    },
    get(id = null, offset = 0, range = process.env.VUE_APP_PLAYLIST_ITEMS_INIT, time = new Date()) {
      return {
        value: this.grab(),
        promise: (this.fetch(id, offset, range, time) || new Promise(resolve => resolve())).then(() => {
          return this.grab()
        }),
      }
    },
  }
}
