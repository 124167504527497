<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    xLink() {
      return `#icon-${this.name}`
    },
  },
}
</script>

<template>
  <svg
    v-bind="$attrs"
    class="icon">
    <use :xlink:href="xLink"/>
  </svg>
</template>
