<script>
import ShowGridTile from '@components/show-grid-tile'

export default {
  components: {
    ShowGridTile,
  },
  props: {
    shows: {
      type: Array,
      default: () => [],
    },
    scroll: {
      type: Boolean,
      default: false
    },
    scrollMobileOnly: {
      type: Boolean,
      default: false,
    },
    padded: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<template>
  <div :class="[$style.block, scroll && $style.scroll, scrollMobileOnly && $style.scrollMobileOnly, padded && $style.blockPadded]">
    <ShowGridTile
      v-for="(show, index) in shows"
      :show="show"
      :index="index"
      :class="$style.tile"
      :key="show.id + index"/>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.block {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$size-grid-padding;
  margin-left: -$size-grid-padding;
  &Padded {
    @include sidePadding;
  }
}

.tile {
  flex-basis: 50%;
  padding: 0 $size-grid-padding;
  margin-bottom: $size-grid-padding * 2;

  @include tablet {
    flex-basis: 50%;
  }

  @include desktop {
    flex-basis: 25%;
  }
}

.scroll {
  @include scrollableWrapper;

  &MobileOnly {
    padding: 0;
    .tile {
      box-sizing: content-box;
      flex-basis: 70%;

      &:first-of-type {
        margin-left: $size-grid-padding * 2;
      }
      &:last-of-type {
        padding-right: $size-grid-padding * 3;
      }

      @include tablet {
        flex-basis: 50%;
      }

      @include desktop {
        box-sizing: border-box;
        flex-basis: 25%;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          padding-right: $size-grid-padding;
        }
      }
    }

    @include desktop {
      flex-wrap: wrap;
      &.blockPadded {
        @include sidePadding;
      }
    }
  }
}
</style>
