<script>
import ArtistOptions from '@components/artist/artist-options'
import ShowOptions from '@components/show/show-options'
import formatDate from '@utils/format-date-full'
import { mapGetters } from 'vuex'

export default {
  components: {
    ArtistOptions,
    ShowOptions,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    resource: {
      type: Object,
      default: null,
    },
    resourceType: {
      type: [String, Boolean],
      default: false,
    },
    mix: {
      type: [Boolean, Object],
      default: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    isFavorite() {
      if (this.type === 'channel' && this.loggedIn) {
        return this.$store.state.settings.favorites.find(el => el === this.resource.id)
      }
      return false
    },
    airDate() {
      if (this.type === 'mix' && this.resource.episode) {
        const date = new Date(this.resource.episode.air_start)
        return formatDate(date)
      }
      return ''
    },
    type() {
      return this.resourceType || (this.resource && this.resource.type)
    },
  },
  methods: {
    handleChannelFavoriteClick(resource) {
      if (this.loggedIn) {
        this.$store.dispatch('settings/toggleToFavorites', resource.id)
      } else {
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div
      v-if="resource && type"
      :class="$style.inner">
      <div
        v-if="type === 'channel'"
        :class="[$style.radioDot, $style[resource.id]]">
        <h5 :class="$style.radioDotTitle">{{ resource.title }}</h5>
      </div>
      <div
        v-if="type === 'channel'"
        :class="$style.main">
        <BasePlaceholder
          :class="$style.titleRadio"
          :text="resource.title"
          :type="type"
          tag="h2" />
        <BaseButtonPlay
          :resource="resource"
          :class="$style.button"
          icon />
        <BaseButton
          v-if="type === 'channel'"
          :button-type="isFavorite ? 'primary' : 'secondary'"
          @click="handleChannelFavoriteClick(resource)"
        >{{ isFavorite ? 'Remove from Favorites' : 'Add to Favorites' }}</BaseButton>
      </div>
      <div
        v-else-if="type === 'artist'"
        :class="[$style.main, $style.mainShow]">
        <h6 :class="$style.pageType">Artist Profile</h6>
        <div>
          <BasePlaceholder
            :text="resource.title && Number(resource.reach || 0).toLocaleString()"
            :size="5"
            :class="$style.totalPlays"
            tag="h5"
          />
          <p :class="$style.totalPlaysLabel">Total Plays</p>
          <BasePlaceholder
            :class="$style.showTitle"
            :text="resource.title"
            :type="type"
            tag="h1" />
          <BaseButtonPlay
            :resource="resource && resource.latestMix"
            :class="[$style.playButton, $style.button]"
            icon
          />
          <BaseFavourites
            :artist="resource"
            button-type-active="secondary"
            button-type="primary"
            size="large"
          >
            <span slot="main">Follow</span>
            <span slot="alt">Unfollow</span>
          </BaseFavourites>
          <div
            :class="$style.options"
            @click.prevent="()=>{}">
            <BaseDropdown :padded="false">
              <BaseButton
                slot="trigger"
                :class="$style.triggerButton"
                :modifiers="{ autoWidth: false, transparent: false, dots: true}"
                button-type="tag"
                aria-label="Options"
                size="large"/>
              <div slot="dropdown">
                <ArtistOptions
                  :has-view-artist-option="false"
                  :artist="resource" />
              </div>
            </BaseDropdown>
          </div>
        </div>
      </div>
      <div
        v-else-if="type === 'show'"
        :class="[$style.main, $style.mainShow]">
        <h6 :class="$style.pageType">Show Profile</h6>
        <div>
          <BasePlaceholder
            :text="resource.title && Number(resource.reach || 0).toLocaleString()"
            :size="5"
            :class="$style.totalPlays"
            tag="h5"
          />
          <p :class="$style.totalPlaysLabel">Total Plays</p>
        </div>
        <BasePlaceholder
          :class="$style.showTitle"
          :text="resource.title"
          :type="type"
          tag="h1" />
        <p
          v-if="resource.artist"
          :class="$style.showArtistLine">
          by
          <BaseLink
            v-if="resource.artist"
            :to="{path: `/artist/${resource.artist.url}/${resource.artist.id}/`}"
          >
            <div :class="$style.showArtist">{{ resource.artist.title }}</div>
          </BaseLink>
        </p>
        <div :class="$style.genreList">
          <BaseButtonGenre
            v-for="genre in resource.genre"
            :key="genre"
            :genre="genre" />
        </div>
        <BaseButtonPlay
          :resource="mix"
          :class="$style.button"
          icon />
        <BaseFavourites
          :show="resource"
          button-type-active="secondary"
          button-type="primary"
          size="large"
        >
          <span slot="main">Follow</span>
          <span slot="alt">Unfollow</span>
        </BaseFavourites>
        <div
          :class="$style.options"
          @click.prevent="()=>{}">
          <BaseDropdown :padded="false">
            <BaseButton
              slot="trigger"
              :class="$style.triggerButton"
              :modifiers="{ autoWidth: false, transparent: false, dots: true}"
              button-type="tag"
              aria-label="Options"
              size="large"/>
            <div slot="dropdown">
              <ShowOptions
                :has-view-show-option="false"
                :show="resource" />
            </div>
          </BaseDropdown>
        </div>
      </div>
      <div
        v-else-if="type === 'mix'"
        :class="[$style.main, $style.mainMix]">
        <BaseResourceImage
          :resource="resource.episode"
          :class="[$style.image, $style.imageMix]"
          :playable-mix="resource"
        />
        <BaseLink :to="{path: `/artist/${resource.artist.url}/${resource.artist.id}`}">
          <BasePlaceholder
            :class="$style.titleMix"
            :text="resource.artist && resource.artist.title"
            type="artist"
            tag="h3"
          />
        </BaseLink>
        <BasePlaceholder
          :text="resource.show && resource.show.title"
          type="show"
          tag="div" />
        <BasePlaceholder
          :text="airDate"
          :size="5"
          tag="p" />
        <div :class="$style.genres">
          <BaseButtonGenre
            v-for="genre in resource.genre"
            :key="genre"
            :genre="genre" />
        </div>
      </div>
    </div>
    <div
      v-else
      :class="$style.inner">
      <h6
        v-if="description"
        :class="$style.description">{{ description }}</h6>
      <h1 :class="$style.title">{{ text }}</h1>
    </div>
    <BaseHeroBackground
      v-if="resource && resource.nowPlaying && resource.nowPlaying.mixes"
      :resource="resource.nowPlaying.mixes.episode"
      is-blurred
    />
    <BaseHeroBackground
      v-else-if="resource && type === 'mix' && resource.episode"
      :resource="resource.episode"
      is-blurred
    />
    <BaseHeroBackground
      v-else-if="resource && type === 'show'"
      :resource="resource"
      is-blurred
      type="image" />
    <BaseHeroBackground
      v-else-if="resource && type === 'artist'"
      :resource="resource"
      :type="resource.hero ? 'hero' : 'photo'"
      :is-blurred="!(resource.hero && resource.hero.url)"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.inner {
  position: relative;
  z-index: $layer-page-z-index;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @include container;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    margin-top: $s-h * 0.3;
  }

  @include sidePadding;
}

.title {
  @extend %h3;

  width: 100%;
  margin-bottom: $s-xxl;
  text-align: left;

  @include tablet {
    @include h1;

    margin-top: $s-xxxl;
  }
}

.showTitle {
  overflow-wrap: break-word;

  @extend %h3;

  @include desktop {
    @include h1;

    margin-bottom: $s-s;
  }
}

.description {
  position: absolute;
  top: 0;
  display: none;

  @include tablet {
    display: block;
  }
}

.titleRadio {
  margin: 0 0 $size-grid-padding;
  text-align: center;

  @include tablet {
    text-align: left;
  }
}

.titleMix {
  margin: 0 0 $size-grid-padding;
  text-align: center;
  overflow-wrap: break-word;
}

.genres {
  display: flex;
}

.main {
  &Mix {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: $s-xxl;
    margin-left: auto;
  }

  &Show {
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @include tablet {
      margin-left: 0;
      text-align: left;
    }
  }
}

.image {
  &Mix {
    width: 200px;
    max-width: 100%;
    margin-bottom: $s-xl;
  }
}

.container {
  position: relative;
  z-index: $layer-highlight-z-index;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: $s-xl;

  @include desktop {
    min-height: 350px;
  }
}

.radioDot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: span(6 of 12 narrow);
  max-width: 200px;
  margin-top: gutter() * 4;
  border-radius: 50%;

  @include tablet {
    width: span(2 of 12 narrow);
    margin-top: 0;
  }

  &::after {
    position: relative;
    z-index: $layer-negative-z-index;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    content: '';
    border-radius: 50%;
  }

  &.frisky::after {
    background: $c-frisky-fill;
  }

  &.chill::after {
    background: $c-chill-fill;
  }

  &.classics::after {
    background: $c-classics-fill;
  }

  &.deep::after {
    background: $c-deep-fill;
  }

  + .main {
    @include tablet {
      margin-left: gutter(of 12 narrow);
    }
  }
}

.radioDotTitle {
  display: block;
  margin: 0;

  @include center;
}

.button {
  margin-right: $s-xl;
}

.showArtist {
  display: inline-block;
  color: $c-white;

  &Line {
    margin-top: -$s-s;
  }
}

.genreList {
  display: flex;
  justify-content: center;
  margin-bottom: $s-xl;

  @include tablet {
    justify-content: flex-start;
  }
}

.pageType {
  display: none;

  @include tablet {
    display: block;
  }
}

.totalPlays {
  margin-top: -$s-m;
  margin-bottom: 0;

  @include tablet {
    margin-top: $s-xxxl + $s-m;
  }

  &Label {
    margin-top: 0;
    margin-bottom: $s-l;

    @include tablet {
      margin-bottom: -$s-xl;
    }
  }
}

.options {
  position: relative;
  z-index: $layer-dropdown-z-index;
  display: inline-block;
  margin-left: $s-xl;
  vertical-align: top;
}

.triggerButton {
  width: 40px;
}
</style>
