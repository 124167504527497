import uniqBy from 'lodash/uniqBy'

export default function createGettableListCreator({ resources }) {
  return function(apiCall, defaultRange = 6, defaultOffset = 0, mapFc) {
    const storedLists = {}
    const listPromises = {}

    let isComplete = {}
    function get(listItemId, range = defaultRange, offset = defaultOffset) {
      if (storedLists[listItemId] && offset === defaultOffset && range === defaultRange) {
        return storedLists[listItemId]
      }
      fetch(listItemId, range, offset)
      return storedLists[listItemId] || []
    }

    function fetch(listItemId, range = defaultRange, offset = defaultOffset) {
      listPromises[listItemId] = apiCall(listItemId, range, offset).then(result => {
        if (storedLists[listItemId]) {
          storedLists[listItemId] = uniqBy([...storedLists[listItemId], ...result], 'id')
        } else {
          storedLists[listItemId] = result
        }

        listPromises[listItemId] = null
        return result
      })
      return listPromises
    }

    const getExtended = mapFc
      ? function(id) {
          const returnObj = get(id).map(item => item)
          isComplete[id] = resources.mixes.isExtendedList(returnObj)
          return returnObj
        }
      : function(id) {
          const returnObj = get(id).map(item => resources.mixes.getById(item.id).value || item)

          isComplete[id] = resources.mixes.isExtendedList(returnObj)
          return returnObj
        }

    return {
      get,
      getExtended,
      fetch,
      isComplete(id) {
        return isComplete[id]
      },
      promise: listPromises,
    }
  }
}
