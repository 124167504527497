<script>
import Layout from '@layouts/main'
import MixTableBlock from '@components/mix-table-block.v2'

export default {
  page() {
    const title = 'Your Music - Mixes'
    const description = 'Your Music - Mixes'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: { Layout, MixTableBlock },
  data() {
    return {
      favPopulatedList: Array.from(Array(6), (e,i)=> (
        {
          mixes: {
            id:  Number.MAX_SAFE_INTEGER - i,
            genre: []
          }
        }
      )),
      shouldTransition: false,
      maxMixes: 30,
      increaseMixes: 15,
      shouldLoadMore: false
    }
  },
  computed: {
    favList() {
      return this.$store.state.favorites.mixesList
    },
    mixesList() {
      return this.favPopulatedList.map(el => el.mixes)
    },
    scroll() {
      return this.$store.state.appearance.scroll
    },
    scrollChild() {
      return this.$store.state.appearance.scrollChild
    }
  },
  watch: {
    favList: {
      handler() {
        this.$dataLayer.favMixes.get(this.maxMixes).then(({body}) => {
          this.favPopulatedList = body
          this.shouldLoadMore = true
        }).then(() => {
          this.shouldTransition = true
        })
      },
      immediate: true
    },
    scroll() {
      let bottomOfWindow = this.scroll + window.innerHeight >= this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight/3;

      if (bottomOfWindow && this.shouldLoadMore) {
        this.shouldLoadMore = false
        setTimeout(() => {
          this.shouldLoadMore = true
        }, 200)
        this.loadMoreMixes()
      }
    }
  },
  methods: {
    loadMoreMixes() {
      this.maxMixes += this.increaseMixes
      this.$dataLayer.favMixes.get(this.maxMixes).then(({body}) => {
        this.favPopulatedList = body
        this.shouldLoadMore = true
      })
    },
  },
}
</script>

<template>
  <Layout>
    <BaseHero
      :class="$style.mainHeading"
      text="Favorite Mixes"
    />
    <BaseContent>
      <BaseSection padded>
        <MixTableBlock
          :mixes="mixesList"
          :error="{
            title: `You have not favorited any mixes`,
            paragraph:
              `When you press the heart icon on a mix, they will appear here`,
          }"
          :should-transition="shouldTransition"
          genres
          is-indexed-list
        />
      </BaseSection>
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading h1 {
  margin-top: 0;
}
</style>
