import localSave from '@utils/local-save'
import keyBy from 'lodash/keyBy'
import uniq from 'lodash/uniq'
import debounce from 'lodash/debounce'

export const MAXIMUM_STORED = 130
export const STORAGE_MAX_CUT = 30 // don't cut one at the time to keep operation less frequent
export const SAVE_DEBOUNCE_TIME = 3000

export function createResource(resourceName, object = false, useLocalStorageFirst = true) {
  let storage =
    (useLocalStorageFirst && localSave.get(resourceName)) ||
    (object
      ? {
          obj: {},
          latest: [],
        }
      : {
          array: [],
          latest: [],
        })

  const trimInnerArray = function() {
    storage.latest = uniq(storage.latest)
    if (storage.latest.length > MAXIMUM_STORED) {
      storage.latest = storage.latest.slice(0, -1 * STORAGE_MAX_CUT)
      return true
    }
    return false
  }

  const trim = object
    ? function() {
        if (trimInnerArray()) {
          const tempStorageObj = { ...storage.obj }
          Object.keys(tempStorageObj).forEach(key => {
            if (!(storage.latest.findIndex(arrayEl => arrayEl === key) > 0)) {
              delete tempStorageObj[key]
            }
          })

          return { obj: tempStorageObj, latest: storage.latest }
        }
        return storage
      }
    : function() {
        if (trimInnerArray()) {
          return {
            array: storage.array.filter(el => storage.latest.findIndex(arrayEl => el.id === arrayEl) > 0),
            latest: storage.latest,
          }
        }
        return storage
      }

  const saveStorageResourceInstant = function(save = true) {
    useLocalStorageFirst && save && localSave.save(resourceName, trim())
  }
  const saveStorageResource = debounce(saveStorageResourceInstant, SAVE_DEBOUNCE_TIME)

  const add = object
    ? function(item, save = true) {
        // add a single item
        storage.obj[item.id] = item
        storage.latest.unshift(item.id)

        saveStorageResource(save)

        return storage.obj
      }
    : function(item, save = true, unshift = false) {
        // add a single item
        const itemIndex = storage.array.findIndex(storageElement => item.id === storageElement.id)
        if (itemIndex >= 0) {
          storage.array[itemIndex] = item
        } else {
          unshift ? storage.array.unshift(item) : storage.array.push(item)
        }

        save && storage.latest.unshift(item.id)

        saveStorageResource(save)

        return this.get()
      }

  const get = object
    ? function(id = null) {
        return id ? storage.obj[id] : storage
      }
    : function(id) {
        return id ? storage.array.find(el => el.id === id) : storage
      }

  const remove = object
    ? function(id, save = true) {
        storage.obj[id] = undefined
        saveStorageResource(save)
      }
    : function(id, save = true) {
        const index = get().array.findIndex(el => el.id === id)
        const newArray = [...storage.array]
        newArray.splice(index, 1)
        update(newArray)
        saveStorageResource(save)
      }

  const update = object
    ? function(array) {
        storage.obj = keyBy(array, o => o.id)
        saveStorageResource()
      }
    : function(array) {
        storage.array = array
        saveStorageResource()
      }

  const clear = object
    ? function() {
        storage.obj = {}
        saveStorageResource()
      }
    : function() {
        storage.array = []
        saveStorageResource()
      }

  return {
    get,
    add,
    update,
    remove,
    clear,
    join(array, unshift = false) {
      array.map(el => this.add(el, false, unshift))

      saveStorageResource()

      return this.get()
    },
  }
}
