<script>
export default {
  props: {
    track: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: -1,
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div
      v-if="index"
      :class="$style.index">
      {{ String(index).padStart(2, 0) }}
    </div>
    <div :class="$style.main">
      <BasePlaceholder
        :class="$style.title"
        :text="track.title"
        tag="div"
      />
      <BasePlaceholder
        :class="$style.artist"
        :text="track.artist"
        tag="div"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.main::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba($c-tuna, 0.15);
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.container {
  display: flex;
  align-items: center;
  &:hover {
    .main::after {
      opacity: 1;
    }
  }
}

.main {
  position: relative;
  width: 100%;
  padding: $s-xl $s-m;
  overflow: hidden;
  border-radius: 3px;
}

.index {
  margin-right: $s-xl;
  font-size: 16px;
  color: $c-text-faded;
}

.title {
  @extend %contentSubtitleM;

  font-weight: 500;
  color: $c-white;
}

.artist {
  @extend %contentSubtitleM;

  margin-top: $s-xxs;
  color: $c-text-faded;
}
</style>
