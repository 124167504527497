<script>
import DataLayer from '@services/data-layer/index'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import Birthday from '@data/models/Birthday'
import months from '@enums/months'

export default {
  page() {
    const title = 'Settings - Overview'
    const description = 'Settings - Overview'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  mixins: [validationMixin],
  data() {
    return {
      user: null,
      hasChanges: false,
      isLoaded: false,
      months,
      currentYear: new Date().getFullYear(),
      startYear: 1900,
      newImage: null,
      s3Folder: process.env.VUE_APP_BFH_IMAGES_FOLDER,
      imageLoading: false,
    }
  },
  computed: {
    birthday() {
      let birthday = new Birthday
      if(this.user && this.user.birthday) {
        birthday.day = new Date(this.user.birthday).getDate()
        birthday.month = new Date(this.user.birthday).getMonth()
        birthday.year = new Date(this.user.birthday).getFullYear()
      }
      return birthday
    },
    years() {
      const years = []
      for(let i = this.startYear; i <= this.currentYear; i++) {
        years.push(i)
      }
      return years
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.hasChanges = this.isLoaded
        this.isLoaded = true
      }
    }
  },
  mounted() {
    this.user = { ...this.$store.state.member.currentMember }
  },
  methods: {
    setDate() {
      if(this.birthday.day && this.birthday.month && this.birthday.year) {
        const timezoneOffset = new Date().getTimezoneOffset();
        const newDate = new Date(this.birthday.year, this.birthday.month, this.birthday.day, 0, -timezoneOffset).toJSON()
        this.user.birthday = newDate
      }
    },
    save() {
      if(!this.$v.$invalid) {
        DataLayer.members.edit({ id: this.user.id, data: this.user })
          .then(user => {
            this.hasChanges = false
            this.$store.commit('member/SET_CURRENT_MEMBER', user.body)
            this.$store.dispatch('appearance/handleNotification', { message: this.$m('DETAILS_UPDATED'), type: 'success' })
          })
          .catch(error => {
            if(error.response.status === 409) {
              this.$store.dispatch('appearance/handleNotification', { message: this.$m('EMAIL_ALREADY_EXISTS'), type: 'danger' })
              return
            }
            this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'danger' })
          })
      }else{
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('EMAIL_REQUIRED'), type: 'warning' })
        this.$v.$reset()
      }
    },
    uploadImage(e) {
      this.imageLoading = true
      const files = e.target.files
      DataLayer.uploader.upload(files, this.s3Folder)
        .then(image => {
          this.user.avatar = image.body
          this.newImage = null
          this.imageLoading = false
        })
        .catch(_error => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'danger' })
        })

    }
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
    }
  },
}
</script>

<template>
  <BaseSection padded>
    <form
      v-if="user"
      @submit.prevent="save">
      <div :class="$style.settings">
        <div :class="$style.avatar">
          <div :class="$style.avatarContainer">
            <div
              v-if="imageLoading"
              :class="$style.spinner">Loading..</div>
            <BaseResourceImage
              v-if="user.avatar"
              :class="$style.image"
              :resource="user"
              :fill="false"
              :centered="true"
              type="avatar"
              alt="user avatar"/>
            <BaseIcon
              v-else
              :class="[$style.icon, $style.iconUser]"
              name="user"/>
          </div>
          <input
            ref="file"
            :class="$style.fileField"
            type="file"
            accept="image/*"
            @change="uploadImage($event)">
          <BaseButton
            :class="$style.photoButton"
            type="button"
            @click="$refs.file.click()">
            <span v-if="user.avatar">Change Photo</span>
            <span v-else>Add Photo</span>
          </BaseButton>
        </div>
        <div :class="$style.infoContainer">
          <div>
            <h6 :class="[$style.subtitle, $style.subtitleInfo]">Account</h6>
            <div :class="$style.fieldContainer">
              <label :class="$style.label">Email</label>
              <BaseInput
                v-model.lazy="user.email"
                :class="[$style.field, $v.user.email.$error && $style.error]"
                placeholder="Enter Email"
              />
            </div>
            <div :class="$style.fieldContainer">
              <label :class="$style.label">Name</label>
              <BaseInput
                v-model.lazy="user.firstName"
                :class="$style.field"
                placeholder="Enter First Name"
              />
            </div>
            <div :class="$style.fieldContainer">
              <label :class="$style.label">Last Name</label>
              <BaseInput
                v-model.lazy="user.lastName"
                :class="$style.field"
                placeholder="Enter Last Name"
              />
            </div>
            <div :class="$style.fieldContainer">
              <label :class="$style.label">Date of Birth</label>
              <div :class="$style.birthdayFields">
                <BaseSelect
                  v-model="birthday.day"
                  @change="setDate">
                  <option
                    :class="$style.option"
                    :value="null"
                    disabled
                    selected>Day</option>
                  <option
                    v-for="d in 31"
                    :key="d + 'day'"
                    :value="d"
                    :class="$style.option">{{ d }}</option>
                </BaseSelect>
                <BaseSelect
                  v-model="birthday.month"
                  @change="setDate">
                  <option
                    :class="$style.option"
                    :value="null"
                    disabled
                    selected>Month</option>
                  <option
                    v-for="month in months"
                    :key="month.title+month.value"
                    :value="month.value"
                    :class="$style.option">{{ month.title }}</option>
                </BaseSelect>
                <BaseSelect
                  v-model="birthday.year"
                  @change="setDate">
                  <option
                    :class="$style.option"
                    :value="null"
                    disabled
                    selected>Year</option>
                  <option
                    v-for="y in years"
                    :key="y + 'day'"
                    :value="y"
                    :class="$style.option">{{ y }}</option>
                </BaseSelect>
              </div>
            </div>
            <div :class="[$style.fieldContainer, $style.genderContainer]">
              <label :class="$style.label">Gender</label>
              <div :class="$style.genderSelection">
                <div :class="$style.gender">
                  <input
                    v-model="user.gender"
                    :class="$style.radio"
                    type="radio"
                    name="gender"
                    value="MALE">
                  <label
                    :class="[$style.genderLabel, $style.label]"
                    for="male">Male</label>
                </div>
                <div :class="$style.gender">
                  <input
                    v-model="user.gender"
                    :class="$style.radio"
                    type="radio"
                    name="gender"
                    value="FEMALE">
                  <label
                    :class="[$style.genderLabel, $style.label]"
                    for="female">Female</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.saveButtonContainer">
        <BaseButton
          :class="$style.saveButton"
          :disabled="!hasChanges"
          button-type="highlight"
          type="submit"
          @click="() => {}">Save</BaseButton>
      </div>
    </form>
  </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

$photo-size: 180px;

.settings {
  display: flex;
  flex-wrap: wrap-reverse;
}

.infoContainer {
  flex-basis: 100%;
}

.avatar {
  width: 100%;
  text-align: center;
  &Container {
    position: relative;
    width :$photo-size;
    height: $photo-size;
    margin: 0 auto $s-m;
  }
  .photoButton {
    width: $photo-size;
  }
}

.image {
  width: $photo-size;
  height: $photo-size;
  overflow: hidden;
  border-radius: 50%;
}

.iconUser {
  display: block;
  width: $photo-size;
  height: $photo-size;
  padding: $s-s;
  margin: 0 0 $s-m 0;
  text-align: center;
  background: $c-white;
  border-radius: 50%;
  fill: $c-black;
}

.label {
  @extend %contentSubtitleM;
}

.subtitle {
  margin-top: 0;
  text-align: center;
}

.birthdayFields {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    grid-gap: $s-xs;
  }

.genderSelection {
  display: flex;
  width: 100%;
  margin: $s-m 0;
  text-align: left;
}
.gender {
  display: flex;
  align-items: center;
}

.genderContainer {
  margin-top: $s-s;
}

.radio {
  @extend %radio;
}

.gender~.gender {
  margin-left: $s-m;
}
.genderLabel {
  margin-left: $s-s;
}

.saveButton {
  width: 100%;
  margin-top: $s-xl;
}

.fileField {
  display: none;
}


.spinner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $layer-highlight-z-index;
  width: $photo-size;
  height: $photo-size;
  font-size: 10px;
  text-indent: -9999em;
  border: $photo-size * 0.1 solid transparentize($c-white, 0.8);
  border-left: $photo-size * 0.1 solid transparentize($c-white, 0.4);
  border-radius: 50%;
  transform: translateZ(0);
  animation: spin 1.1s infinite ease-in-out;

  &::after {
    width: 10em;
    height: 10em;
    border-radius: 50%;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@include tablet {
  .settings {
    display: grid;
    grid-template-columns: $photo-size auto;
    grid-gap: $s-h * 3;
  }
  .subtitle {
    text-align: left;
  }
  .fieldContainer {
    display: grid;
    grid-template-columns: 2fr 5fr;
    align-items: center;
    margin-bottom: $s-s;
    .field {
      margin-bottom: 0;
    }
  }
  .birthdayFields {
    grid-template-columns: 1fr 3fr 2fr;
    grid-gap: $s-s;
  }
  .saveButton {
    width: auto;
    &Container {
      text-align: right;
    }
  }
}
</style>
