<script>
import MixTableTile from '@components/mix-table-tile'

export default {
  components: {
    MixTableTile,
  },
  props: {
    mixes: {
      type: Array,
      default: () => [],
    },
    hasLinkToArtistPage: {
      type: Boolean,
      default: false,
    },
    genres: {
      type: Boolean,
      default: false,
    },
    isIndexedList: {
      type: Boolean,
      default: false,
    },
    isQueueMix: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<template>
  <div>
    <MixTableTile
      v-for="(mix, index) in mixes"
      :mix="mix"
      :index="index"
      :key="$route.name + 'mixTable' + mix.id + index"
      :has-link-to-artist-page="hasLinkToArtistPage"
      :genres="genres"
      :is-indexed-list="isIndexedList"
      is-queue-mix/>
  </div>
</template>
