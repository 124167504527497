import Api from './../api';
import DataLayer from './../data-layer/index';

function _readFile(files) {
  if (files.length === 0) return;
  return new Promise((resolve, reject) => {
    const file = files[0];
    const mimeType = file.type;
    if (mimeType.match(/image\/*/) === null) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let result = reader.result;
        let encoded = result.replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve({
            fileName: file.name,
            fileBase64: encoded
        });
    };
  })
}

DataLayer.$extend('uploader', {

  upload(files, folder) {
    return _readFile(files)
      .then(albumArt => {
        albumArt.folder = folder;
        return Api.uploader.post({}, albumArt);
      })
  }

});
