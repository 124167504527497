<script>
import DataLayer from '@services/data-layer/index'
import Layout from '@layouts/main'

import Overview from '@views/artist/artist.overview'
import About from '@views/artist/artist.about'

export default {
  components: { Layout, Overview, About },
  props: {
    mainComponent: {
      type: String,
      default: 'Overview'
    }
  },
  page() {
    const title = this.artist.title ? `Artist ${this.mainComponent} - ${this.artist.title}` : `Artist`
    const description = this.artist && this.artist.biography ? this.artist.biography : title
    const image = this.artist && this.artist.photo && this.artist.photo.url
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },
        { property: 'og:image', vmid: 'og:image', content: image },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
        { property: 'twitter:image', vmid: 'twitter:image', content: image },
      ],
    }
  },
  data() {
    return {
      artist: {},
      latestMixes: Array.from(Array(6), (e,i)=> ({
        id: i,
        genre: []
      })),
      popularMixes: Array.from(Array(6), (e,i)=> ({
        id: i,
        genre: []
      })),
    }
  },
  computed: {
    heroArtist() {
      return { ...this.artist, latestMix: this.latestMix }
    },
    latestMix() {
      return this.latestMixes[0]
    },
    tabs() {
      const artistUrl = this.artist.url
      const id = String(this.artist.id) // in router, params are string by default, fixes tab matching
      return [
        {
          to: { name: 'artist', params: { artistUrl, id } },
          label: 'Overview',
        },
        {
          to: { name: 'artist/about', params: { artistUrl, id } },
          label: 'About',
        }
      ]
    },
  },
  mounted() {
    Promise.all([
      DataLayer.artists.getById(this.$route.params.id),
      DataLayer.mixes.getPopularByArtist(this.$route.params.id),
      DataLayer.mixes.getLatestByArtist(this.$route.params.id),
    ])
      .then(([artist, popularMixes, latestMixes]) => {
          this.artist = artist.body
          this.popularMixes = popularMixes.body
          this.latestMixes = latestMixes.body
      })
  }
}
</script>

<template>
  <Layout>
    <BaseHero
      :resource="heroArtist"
      resource-type="artist"/>
    <BaseTabPanel
      :tabs="tabs"
      :class="$style.tabs"
      is-unpadded
      address-navigation/>
    <BaseContent>
      <component
        :is="mainComponent"
        :artist="artist"
        :popular-mixes="popularMixes"
        :latest-mixes="latestMixes" />
    </BaseContent>
  </Layout>
</template>


<style lang="scss" module>
@import '@design';

.heroContent {
  position: relative;
  z-index: $layer-page-z-index;
  text-align: center;

  .playButton {
    margin-right: $s-xl;
    margin-bottom: 0;
  }

  @include desktop {
    padding-top: $s-h;
    text-align: left;
  }
}

.tabs {
  @include tablet {
    padding-bottom: $s-xxl;
  }
}

.pageType {
  display: none;

  @include desktop {
    display: block;
  }
}

.reach {
  margin-bottom: $s-xxl;
}

.reachNumber {
  margin-bottom: 0;

  @include desktop {
    margin-top: $spacing-medium * 10;
    margin-bottom: 0;
  }
}

.reachHeading {
  @extend %contentTinyHeading;
}

.artistTitle {
  margin-bottom: $spacing-medium * 2;
  overflow-wrap: break-word;

  @extend %h3;

  @include desktop {
    @include h1;

    margin-bottom: $spacing-medium * 2;
  }
}
</style>
