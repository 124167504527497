import transformer from '@utils/transformer'
import pad from '@utils/pad'
import axios from 'axios'
import ReconnectingWebSocket from 'ReconnectingWebSocket'
import Emitter from 'component-emitter'
import debounce from 'lodash/debounce'
import keyBy from 'lodash/keyBy'

const Promise = require('bluebird')

const CancelToken = axios.CancelToken
let cancelRequest

export default function apiCreator(storage, url = process.env.VUE_APP_API_BASE_URL) {
  const axiosInstance = axios.create({
    baseURL: url,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
  })

  const handleSchedule = items => {
    return items.map(el => ({
      ...el,
      mix_id: el.mixes_id && el.mixes_id.id,
      duration: el.duration * 1000,
      scheduled_start_time: new Date(el.scheduled_start_time).getTime(),
      scheduled_end_time: new Date(el.scheduled_end_time).getTime(),
      id: el.id,
    }))
  }

  const handleShowSchedule = (items, channelId) => {
    return items.map(el => ({
      ...el,
      station: channelId,
      id: el.id ? el.id : `${el.artist_id && el.artist_id.id}${el.show_id && el.show_id.id}${el.air_start}`,
      air_start: new Date(el.air_start).getTime(),
      air_end: new Date(el.air_end).getTime(),
    }))
  }

  const resourceList = [
    {
      name: 'mixes',
      modelName: 'Mixes',
      type: 'mix',
    },
    {
      name: 'shows',
      modelName: 'Shows',
      type: 'show',
    },
    {
      name: 'artists',
      modelName: 'Artists',
      type: 'artist',
    },
    {
      name: 'episodes',
      modelName: 'Episodes',
      type: 'episode',
    },
  ]

  const joinResources = function(data) {
    for (var i = resourceList.length - 1; i >= 0; i--) {
      const currentResource = resourceList[i]
      if (data[currentResource.modelName]) {
        storage[currentResource.name].join(
          data[currentResource.modelName].map(el => ({ ...el, type: currentResource.type }))
        )
      }
    }
  }

  const resource = function({ name, modelName }) {
    const fetchQueue = []
    const events = Emitter({})
    let queueIterator = 0 // match each queue to a number, used for events
    const fetchPostpone = function(idObj) {
      // have this function add to fetchQueue
      // have other function attempt to fetch all in queue or get debounced
      fetchQueue.push(idObj)
      const promise = new Promise(resolve => {
        events.on(queueIterator, evt => {
          resolve(evt[idObj.id])
        })
      })
      fetchDebounced()
      return promise
    }

    const fetchDebounced = debounce(() => {
      // multiple instances of fetch needed due to url length limitations

      const fetchMax = 10
      const fetchCount = Math.ceil(fetchQueue.length / fetchMax)
      for (let i = 0; i < fetchCount; i++) {
        const fetchString = `v3/dispatch?models=${JSON.stringify(
          fetchQueue.slice(i * fetchMax, (i + 1) * fetchMax)
        )}&depth=2`
        const eventId = queueIterator

        axiosInstance.get(fetchString).then(
          ({ data }) => {
            events.emit(eventId, keyBy(data[modelName], o => o.id))
            joinResources(data)
          },
          () => []
        )
      }

      fetchQueue.length = 0
      queueIterator++
    })

    return {
      get(idObj) {
        return fetchPostpone(idObj)
      },
      getTrending(channel) {
        return axiosInstance.get(`v3/${name}?channel=${channel}&trending=true&offset=0&limit=6`).then(({ data }) => {
          return data
        })
      },
    }
  }

  const resources = resourceList.reduce((acc, item) => {
    acc[item.name] = resource(item)
    return acc
  }, {})

  resources.mixes.getArtistTrending = function(id, limit = 5, offset = 0) {
    return axiosInstance
      .get(`v3/mixes?artists_id=${id}&limit=${limit}&offset=${offset}&order=-reach`)
      .then(({ data }) => {
        return data
      })
  }

  resources.mixes.getArtistLatest = function(id, limit = 5, offset = 0) {
    return axiosInstance
      .get(`v3/mixes?artists_id=${id}&limit=${limit}&offset=${offset}&order=-creation_time`)
      .then(({ data }) => {
        return data
      })
  }

  resources.mixes.getGenreLatest = function(genreTitle, limit = 12, offset = 0) {
    return axiosInstance
      .get(`v3/mixes?genre=${encodeURI(genreTitle)}&limit=${limit}&offset=${offset}&order=-creation_time`)
      .then(({ data }) => {
        return data
      })
  }

  resources.mixes.getGenreTrending = function(genreTitle, limit = 12, offset = 0) {
    return axiosInstance
      .get(`v3/mixes?genre=${encodeURI(genreTitle)}&limit=${limit}&offset=${offset}&order=-reach`)
      .then(({ data }) => {
        return data
      })
  }

  resources.artists.getGenreRandom = function(genreTitle, limit = 12, offset = 0) {
    return axiosInstance
      .get(
        `v3/artists?genre=${encodeURI(genreTitle)}&limit=${limit}&offset=${offset}&order=rand()
`
      )
      .then(({ data }) => {
        return data
      })
  }

  const channels = {
    get() {
      return axiosInstance.get('v3/stations').then(
        ({ data }) => {
          return Object.values(data)
            .map(el => transformer.channel(el))
            .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
        },
        () => []
      )
    },
  }

  const nowPlaying = (function() {
    let socket = null
    const events = Emitter({})
    let nowPlayingData = ''
    let forceSend = true

    function socketHandler(e) {
      const newNowPlayingData = e.data
      if (forceSend || newNowPlayingData !== nowPlayingData) {
        forceSend = false
        nowPlayingData = newNowPlayingData
        return events.emit('dataUpdate', JSON.parse(nowPlayingData))
      }
      return false
    }

    return {
      get() {
        if (!socket) {
          socket = new ReconnectingWebSocket(process.env.VUE_APP_API_NOW_PLAYING, null, { reconnectInterval: 5000 })
          socket.addEventListener('message', socketHandler)
          socket.addEventListener('open', () => {
            forceSend = true
          })
        }
        return events
      },
    }
  })()

  const schedule = {
    get(id = null, offset = 0, range = process.env.VUE_APP_PLAYLIST_ITEMS_INIT, time = new Date()) {
      const schedulePromise = axiosInstance
        .get(
          `v3/stations/playlists?${id ? `station=${id}&` : ``}after=${time.getUTCFullYear()}-${pad(
            time.getUTCMonth() + 1
          )}-${pad(time.getUTCDate())}T${pad(time.getUTCHours())}:00:00&offset=${offset}&limit=${
            id ? range : range * 4
          }`
        )
        .then(({ data }) => handleSchedule(data), () => [])
      return schedulePromise
    },
  }

  const showSchedule = {
    get(startDate = null, endDate = null, channelId = null) {
      // https://api.dev.frisky.fm/v3/episodes?startdate=2019-03-12T00:00:00&enddate=2019-03-12T23:59:59&channel=deep
      const schedulePromise = axiosInstance
        .get(
          `v3/episodes?startdate=${startDate.getUTCFullYear()}-${pad(startDate.getUTCMonth() + 1)}-${pad(
            startDate.getUTCDate()
          )}T${pad(startDate.getUTCHours())}:00:00&enddate=${endDate.getUTCFullYear()}-${pad(
            endDate.getUTCMonth() + 1
          )}-${pad(endDate.getUTCDate())}T${pad(endDate.getUTCHours())}:00:00${
            channelId ? `&channel=${channelId}` : ``
          }`
        )
        .then(({ data }) => handleShowSchedule(data, channelId), () => [])
      return schedulePromise
    },
  }

  const login = function({ email, password }) {
    return axiosInstance.post('v3/auth/token', { email, password })
  }

  const signup = function({ data }) {
    return axiosInstance.post('v3/members', data)
  }

  const revokeToken = () => axiosInstance.post('v3/auth/token/revoke');


  const resetPasswordRequest = function({ email }) {
    return axiosInstance.post('v3/members/reset-password' + '/?email=' + email, {email: email})
  }

  const resetPassword = function({ member, token }) {
    return axiosInstance.put(`v3/members/${member.id}`, member, {
        headers: { Authorization: "Bearer " + token }
    })
  }

  const favMixes = {
    get(membersId) {
      return axiosInstance.get(`v3/members/${membersId}/favorites/mixes?limit=100000`)
    },
    post(membersId, content) {
      return axiosInstance.post(`v3/members/${membersId}/favorites/mixes`, content)
    },
    delete(membersId, id) {
      return axiosInstance.delete(`v3/members/${membersId}/favorites/mixes/${id}`)
    },
  }

  const favArtists = {
    get(membersId) {
      return axiosInstance.get(`v3/members/${membersId}/favorites/artists?limit=100000`)
    },
    post(membersId, content) {
      return axiosInstance.post(`v3/members/${membersId}/favorites/artists`, content)
    },
    delete(membersId, id) {
      return axiosInstance.delete(`v3/members/${membersId}/favorites/artists/${id}`)
    },
  }

  const favShows = {
    get(membersId) {
      return axiosInstance.get(`v3/members/${membersId}/favorites/shows?limit=100000`)
    },
    post(membersId, content) {
      return axiosInstance.post(`v3/members/${membersId}/favorites/shows`, content)
    },
    delete(membersId, mixId) {
      return axiosInstance.delete(`v3/members/${membersId}/favorites/shows/${mixId}`)
    },
  }

  const genres = {
    getAll() {
      return axiosInstance.get(`v3/genres`)
        .then(response => {
          return { body: response.data }
        })
    }
  }

  const search = {
    get(keyword, limit = 6, offset = 0) {
      if (cancelRequest !== undefined) {
        cancelRequest()
      }
      return axiosInstance
        .get(`v3/search?query=${encodeURIComponent(keyword)}&limit=${limit}&offset=${offset}`, {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelRequest = c
          }),
        })
        .then(response => {
          return { headers: response.headers, body: response.data }
        })
    },
  }

  const token = {
    set(token) {
      axiosInstance.defaults.headers.common.Authorization = token
    },
    get() {
      return axiosInstance.defaults.headers.common.Authorization
    },
  }

  return {
    ...resources,
    schedule,
    showSchedule,
    channels,
    nowPlaying,
    favMixes,
    favArtists,
    favShows,
    genres,
    search,
    login,
    signup,
    revokeToken,
    resetPasswordRequest,
    resetPassword,
    token,
    get: axiosInstance.get,
  }
}
