<script>
import MixTableBlock from '@components/mix-table-block.v2'

export default {
  components: { MixTableBlock },
  props:{
    searchResults: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    mixes() {
      return this.searchResults.Mixes
    }
  }
}
</script>

<template>
  <div>
    <BaseSection
      v-if="mixes"
      padded>
      <MixTableBlock
        :mixes="mixes"
        genres
        is-indexed-list/>
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
