<script>
import ShowScheduleBlock from '@components/show-grid-block'

export default {
  components: { ShowScheduleBlock },
  props:{
    searchResults: {
      type: Object,
      default: () => {}
    },
    searchJoin: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      INITIAL_RESOURCES: 30,
      visibleResources: 30,
      shouldLoadMore: true
    }
  },
  computed: {
    shows() {
      return this.searchResults.Shows
    },
    resourcesRemain() {
      return this.shows.length % this.INITIAL_RESOURCES === 0
    },
    scroll() {
      return this.$store.state.appearance.scroll
    },
    scrollChild() {
      return this.$store.state.appearance.scrollChild
    },
    keyword() {
      return this.$store.state.search.keyword.replace(/ /g, '_')
    },
  },
  watch: {

    scroll() {

      if(this.shows.length < this.visibleResources) {
        return false
      }

      let bottomOfWindow = this.scroll + window.innerHeight >= this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight/3;
      if (bottomOfWindow && this.shouldLoadMore) {
        this.shouldLoadMore = false
        setTimeout(() => {
          this.shouldLoadMore = true
        }, 200)
        this.showMoreResults()
      }
    }
  },
  mounted() {
    this.visibleResources = this.shows.length
  },
  methods: {

    async elasticSearch(limit, offset) {
      this.searchJoin(limit, offset, 'Shows').then( res => {
        this.visibleResources = res
      })
    },
    showMoreResults() {
      this.elasticSearch(this.INITIAL_RESOURCES, this.visibleResources)
    }
  },
}
</script>

<template>
  <div>
    <BaseSection
      v-if="shows"
      padded>
      <ShowScheduleBlock :shows="shows"/>
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
