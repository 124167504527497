<script>
import DataLayer from './../../../services/data-layer/index'
import formatDate from './../../../utils/format-date-full'
import localSave from './../../../utils/local-save'

const subscriptionService = DataLayer.subscriptions

export default {
    page() {
        const title = 'Settings - Subscription'
        const description = 'Settings - Subscription'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },

    data() {
        return {
            activeProduct: null,
            hasFreePlan: null,
        }
    },

    mounted() {
        const member = localSave.get('user.currentMember')
        const membersid = member.id
        this.getProducts({ membersid, status: 'active' })
    },

    methods: {
        formatDate,

        async getProducts({ membersid, status }) {
            const [products, activeProduct] = await Promise.all([
                subscriptionService.getProducts(),
                subscriptionService.getMembersProducts({ membersid, status }),
            ])

            this.setProductsData(products.body, activeProduct.body[0])
        },

        setProductsData(products, activeProduct) {
            this.hasFreePlan = !activeProduct || activeProduct.price === 0

            const listedActiveProduct = this.hasFreePlan
                ? products.find(product => product.price === 0)
                : products.find(product => product.id === activeProduct.id)

            this.activeProduct = { ...activeProduct, ...listedActiveProduct }
        },
    },
}
</script>

<template>
  <BaseSection padded>
    <h6 :class="[$style.subtitle, $style.subtitleInfo]">Your Plan</h6>

    <div
      v-if="activeProduct"
      :class="$style.currentPlan"
      padded
    >
      <h5 :class="$style.title">{{ activeProduct.title }}</h5>
      <h6
        v-if="activeProduct.endTime"
        :class="$style.endDate"
      >
        Next billing date: {{ formatDate(new Date(activeProduct.endTime)) }}
      </h6>
      <div :class="$style.action">
        <router-link :to="{ name: 'premium/view-plans' }">
          <BaseButton
            button-type="primary"
            @click="() => {}"
          >{{
            hasFreePlan ? 'Upgrade Your Plan' : 'Change Your Plan'
          }}</BaseButton>
        </router-link>
      </div>
    </div>
  </BaseSection>
</template>

<style lang="scss" module>
@use '@design' as *;

.currentPlan {
    position: relative;
    width: 330px;
    height: 404px;
    margin-right: unset;
    margin-left: unset;
    text-align: center;
    background: $c-shark-black;
    border-radius: 6px;
    box-shadow: 0 12px 24px 0 rgba(16, 17, 24, 0.27);
}

.title {
    padding-top: $s-xl;
}

.endDate {
    @extend %contentSubtitleL;
}

.action {
    position: absolute;
    right: 0;
    bottom: $s-xxl;
    left: 0;
    margin: 0 auto;
}
</style>
