
import Queue from '@data/models/Queue'
const queue =  new Queue

export const state = {
  mixes: queue ? queue.mixes : [], // array of  mixes
  position: queue ? queue.position : -1, // queue position currently playing
  playlistId: queue ? queue.playlistId : null, // playlist id currently playing if any
}

export const getters = {}

export const mutations = {
  SET_DATA(state, { queue }) {
    state.mixes = queue.mixes
    state.position = queue.position
    state.playlistId = queue.playlistId
  },
}

export const actions = {}
