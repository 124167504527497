<script>
import ShowOptions from '@components/show/show-options'

export default {
  components: {
    ShowOptions,
  },
  props: {
    show: {
      type: Object,
      default: () => {},
    },
    extended: { // Centered on mobiles, expanded width on desktops
      type: Boolean,
      default: false
    },
    hasTypeTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div v-if="show">
    <div :class="[$style.container, extended && $style.containerExtended]">
      <div :class="$style.imageWrap">
        <div :class="$style.imageContainer">
          <BaseLink
            :to="{name: 'show', params: { id: show.id }}">
            <BaseResourceImage
              :class="$style.image"
              :resource="show || false"/>
          </BaseLink>
        </div>
        <div
          :class="$style.options"
          @click.prevent="()=>{}">
          <BaseDropdown :padded="false">
            <BaseButton
              slot="trigger"
              :modifiers="{ autoWidth: true, transparent: true, dots: true}"
              button-type="tag"
              aria-label="Options"
              size="small"/>
            <div slot="dropdown">
              <ShowOptions :show="show" />
            </div>
          </BaseDropdown>
        </div>
      </div>
      <div :class="$style.main">
        <BaseLink
          :to="{name: 'show', params: { id: show.id }}">
          <div
            :class="$style.title"
            class="title">
            {{ show.title }}
          </div>
        </BaseLink>
        <div
          v-if="show.artist"
          :class="$style.subtitle">
          <BaseLink :to="{path: `/artist/${show.artist.url}/${show.artist.id}`}">
            {{ show.artist.title }}
          </BaseLink>
        </div>
      </div>
      <div
        v-if="hasTypeTitle"
        :class="$style.typeTitle">Show</div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.options {
  z-index: $layer-dropdown-z-index;
  opacity: 0;
  transition: opacity .3s ease-in-out;

  @include center;
}

.main {
  flex-basis: 100%;

  @include tablet{
    flex-basis: span(3 of 12);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  &Extended {
    text-align: center;

    .main {
      margin-top: $s-xl;
    }

    @include tablet {
      display: grid;
      grid-template-areas: "image main" "typeTitle null";
      grid-template-columns: 264px max-content;
      grid-gap: $s-xl;
      align-items: center;
      .main {
        margin-top: unset;
        text-align: left;
      }
      .imageWrap {
        max-width: unset;
      }
    }
  }
}

.imageWrap {
  position: relative;
  flex-basis: 100%;
  grid-area: image;

  @include tablet {
    flex-basis: span(2 of 12 narrow);
    max-width: 200px;
  }

  &::after {
    @include fill;

    content: '';
    background: $c-purple-red-gradient;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover {
    .options {
      opacity: 1;
    }
    &::after {
      opacity: 0.5;
    }
  }
}

.imageContainer {
  position: relative;
  height: 200px;
  margin-bottom: auto;
  overflow: hidden;
}

.image {
  @include center;

  width: 100%;
}

.title {
  @extend %contentTitleL;

  margin-top: $s-xxs;
  margin-bottom: $s-s;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $s-xl;

  @include desktop {
    justify-content: unset;
  }
}

.subtitle {
  @extend %contentSubtitleM;

  margin-bottom: $s-m;
}

.typeTitle {
  @extend %contentTitleM;

  margin: auto 0;
  text-align: center;
}
</style>
