<script>
import formatDate from '@utils/format-date-full'
import MixOptions from '@components/mix/mix-options'

export default {
  components: { MixOptions },
  props: {
    mix: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    airDate() {
      if (this.mix.episode) {
        const date = new Date(this.mix.episode.air_start)
        return formatDate(date)
      }
      return ''
    },
  },
}
</script>

<template>
  <div
    v-if="mix"
    :class="$style.details">
    <div :class="$style.detailsInner">
      <BaseResourceCover
        :image-resource="mix.episode"
        :media-resource="mix"
        :class="$style.image"
        media-type="mix">
        <div slot="secondaryActions">
          <MixOptions :mix="mix" />
        </div>
      </BaseResourceCover>
      <h4 :class="$style.title">
        <BaseLink
          v-if="mix && mix.show"
          :to="{ path: `/shows/${mix.show.id}` }">
          <span :class="$style.titleInner">{{ mix.show.title }}</span>
        </BaseLink>
        <BasePlaceholder
          v-else
          :size="13" />
      </h4>
      <h5 :class="$style.artistTitle">
        <BaseLink
          v-if="mix && mix.artist"
          :to="{path: `/artist/${mix.artist.url}/${mix.artist.id}`}">
          {{ mix.artist.title }}
        </BaseLink>
        <BasePlaceholder
          v-else
          :size="11" />
      </h5>
      <p
        v-if="mix.description"
        :class="$style.description">{{ mix.description }}</p>
      <div :class="$style.genres">
        <span
          v-for="genre in mix.genre"
          :class="$style.genre"
          :key="genre">
          <BaseButtonGenre :genre="genre" />
        </span>
      </div>
      <div :class="$style.button">
        <BaseButtonPlay
          :icon="true"
          :class="$style.buttonInner"
          :resource="mix"
        />
      </div>
      <div :class="$style.actions">
        <BaseFavourites
          :mix="mix"
          button-type="tag"
        />
      </div>
      <div :class="$style.releaseDate">
        <div v-if="mix && airDate">Released {{ airDate }}</div>
        <BasePlaceholder
          v-else
          :size="14" />
      </div>

    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.details {
  text-align: center;
  &Inner{
    margin-bottom: $s-xl;

    @include widescreen {
      position: sticky;
      top: $s-xl + $s-xxxl;
      margin-bottom: 0;
    }
  }
}

.title {
  margin-top: $s-l;
  margin-bottom: 0;

  &Inner {
    color: $c-white;
  }
}

.artistTitle {
  margin-top: 0;
  margin-bottom: $s-s;
  font-size: 20px;
  color: $c-text-faded;

  &Inner {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 800;
  }
}

.description {
  @extend %contentTinyHeading;

  margin-top: 0;
  margin-bottom: $s-l;
  text-align: center;
}

.genres {
  width: 100%;
  margin: 0 auto $s-l;
  .genre {
    display: inline-block;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $s-l;
}

.dropdown{
  &Menu {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &Item {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 $s-m;
    cursor: pointer;
  }
}

.separator {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: $c-mine-shaft;
}

.releaseDate {
  @extend %contentTinyHeading;
}

</style>
