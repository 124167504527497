<script>
export default {
  props: {
    genres: {
      type: Array,
      default: () => [],
    },
    hasTypeTitle: {
      type: Boolean,
      default: false
    },
    horScroll: {
      type: Boolean,
      default: false
    },
    horScrollMobile: {
      type: Boolean,
      default: false
    },
    padded: {
      type: Boolean,
      default: false
    },
  },
  computed: {},
  methods: {
    genreLink(genre) {
      return genre.toLowerCase().replace(/ /g, '_')
    },
  },
}
</script>

<template>
  <div :class="[$style.genresList, horScroll && $style.genresListHorScroll, horScrollMobile && $style.genresListHorScrollMobile, padded && $style.genresListPadded]">
    <div
      v-for="(genre, index) in genres"
      :class="$style.genreItem"
      :key="`genre${index}`">
      <div
        :class="$style.genre"
        @click="$router.push({ name: 'genre', params: { id: genreLink(genre) }})">
        {{ genre }}
      </div>
      <div
        v-if="hasTypeTitle"
        :class="$style.typeTitle">Genre</div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.genresList {
  &HorScroll {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 140px;
    overflow-x: auto;
    overflow-y: none;
    text-align: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &Mobile {
      @include desktop {
        display: block;
        height: auto;
        padding: unset;
        overflow-x: unset;
        overflow-y: unset;
        text-align: unset;
      }
    }
  }
  &Padded {
    padding-left: $s-s * 2;
  }
}

.genreItem {
  position: relative;
  display: inline-block;
  padding-right: $s-m;
  margin-bottom: $s-m;
}
.genre {
  position: relative;
  padding: 0 $s-m;
  line-height: 44px;
  cursor: pointer;
  &::before,
  &::after {
    @include fill;

    z-index: $layer-negative-z-index;
    content: '';
  }
  &::before {
    background: $c-grey-woodsmoke;
  }
  &::after {
    background: $c-bali-hai;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover::after {
    opacity: 1;
  }
}
.typeTitle {
  @extend %contentTitleM;

  margin: $s-s 0;
  text-align: center;
}
</style>
