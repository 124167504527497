<script>
import { mapGetters } from 'vuex'
import SideBar from '@components/side-bar'
import MusicPlayer from '@components/music-player'
import MenuBar from '@components/menu-bar'
import BackArrow from '@components/back-arrow'
import TopBar from '@components/top-bar'
import SignupPromo from '@components/signup-promo'


export default {
  components: { SideBar, MusicPlayer, MenuBar, BackArrow, TopBar, SignupPromo },
  props: {
    hideTopBar: {
      type: Boolean,
      default: false,
    },
    hiddenOverflow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenuOpen: false,

    }
  },
  computed: {
      ...mapGetters('auth', ['loggedIn']),
      scrollTop() {
        return this.$store.state.appearance.scroll
      },
      backgroundOpacity() {
        if(!this.scrollTop) {
          return 0
        }
        return this.scrollTop < 90 && this.scrollTop/100 || 0.9
      }
  },
  mounted() {
    this.$store.commit('appearance/SET_SCROLL_CHILD', this.$refs.container)
    window.addEventListener('scroll', () => {
      this.$store.commit('appearance/SET_SCROLL', window.pageYOffset)
    }, {passive: true})
    this.$refs.main.addEventListener('scroll', () => {
      this.$store.commit('appearance/SET_SCROLL', this.$refs.main.scrollTop)
    }, {passive: true})
  }
}
</script>

<template>
  <div>
    <MenuBar
      v-if="!hideTopBar"
      @toggle-menu="isMenuOpen = !isMenuOpen"/>
    <div :class="$style.wrapper">
      <SideBar
        :class="$style.sideBar"
        :is-menu-open="isMenuOpen"
        @toggle-menu="isMenuOpen = !isMenuOpen"/>
      <div
        ref="main"
        :class="[$style.main, {[$style.hideTop] : hideTopBar}]"
        @click="isMenuOpen = false">
        <div
          v-if="!hideTopBar"
          :class="$style.topBar">
          <BaseSection padded>
            <div :class="$style.topBarInner">
              <BackArrow :class="[$style.arrow, !loggedIn && $style.fixed]"/>
              <div :class="[$style.userLogin, !loggedIn && $style.fixed]">
                <TopBar :class="$style.userLoginInner">
                  <div
                    :class="$style.background"
                    :style="`opacity: ${backgroundOpacity}`"/>
                </TopBar>
              </div>
            </div>
          </BaseSection>
        </div>
        <div
          ref="container"
          :class="[$style.container, hiddenOverflow && $style.hiddenOverflow]">
          <slot/>
        </div>
      </div>
    </div>
    <SignupPromo v-if="!loggedIn"/>
    <MusicPlayer/>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  padding-bottom: $s-xxxl * 2;
}

.main {
  position: relative;
  padding-top: $size-menu-bar-height;
  overflow: hidden;
  &.hideTop {
    padding-top: 0;
  }
}

.topBar {
  position: absolute;
  z-index: $layer-dropdown-z-index;
  display: none;
  width: 100%;
  margin-top: gutter();
  &Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
  }

  @include desktop {
    display: block;
  }
}

.userLogin {
  display: none;
  text-align: right;
  &.fixed {
    position: fixed;
    top: gutter();
    left: $size-side-bar;
    width: calc(100% - #{$size-side-bar});
  }
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  height: 40px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  &::before {
    position: absolute;
    top: -$s-xl;
    left: 0;
    width: 100%;
    height: 40px + $s-xl * 2;
    content: '';
    background: $c-grey-woodsmoke;
  }
}

.arrow {
  display: none;
}

@include desktop {
  .container {
    padding-top: $s-main-desktop-top;
    &.hiddenOverflow {
      overflow: hidden;
    }
  }
  .userLogin {
    display: block;
    &Inner {
      margin-top: 0;
    }
    &.fixed {
      left: $size-large-side-bar;
      width: calc(100% - #{$size-large-side-bar});
      padding-right: $size-scrollbar-width;
    }
  }
  .wrapper {
    display: grid;
    grid-template-columns: $size-large-side-bar calc(100vw - #{$size-large-side-bar});
  }
  .main {
    height: calc(100vh - #{$size-music-player});
    padding-top: 0;
    padding-bottom: $size-music-player * 2;
    margin-bottom: $size-music-player;
    overflow: auto;
    overflow-x: hidden;
  }

  .arrow {
    display: block;
  }
}
</style>
