import retryPromiseCreator from '@utils/retry-promise'

const Promise = require('bluebird')

export default function channelsCreator({ storage, schedule, showSchedule, nowPlaying, api, resources }) {
  let dataPromise = null
  let dataPromiseExtended = []

  function isComplete() {
    return schedule.isComplete() && showSchedule.isComplete() && nowPlaying.isComplete()
  }

  function channelsExtended(channels, schedule, showSchedule = [], nowPlaying = []) {
    const returnChannels = channels.map(el => {
      return {
        ...el,
        schedule: schedule.filter(scheduleEl => scheduleEl.station === el.id),
        showSchedule: showSchedule.filter(scheduleEl => scheduleEl.station === el.id),
        nowPlaying: nowPlaying.filter(nowPlayingEl => nowPlayingEl.station === el.id),
        isComplete: isComplete(),
      }
    })

    return returnChannels
  }

  return {
    getPromise() {
      return dataPromise
    },
    update() {
      if (dataPromise) {
        return dataPromise
      }

      return this.fetch().then(() => {
        this.dataPromise = null
        return channelsExtended(storage.channels.get().array, schedule.grab(), showSchedule.grab(), nowPlaying.grab())
      })
    },
    grab(id = null) {
      return id
        ? {
            ...channelsExtended(
              storage.channels.get().array,
              schedule.grab(),
              showSchedule.grab(),
              nowPlaying.grab()
            ).find(el => el.id === id),
            trendingMixes: resources.mixes.trending.grab(id),
            trendingArtists: resources.artists.trending.grab(id),
          }
        : channelsExtended(storage.channels.get().array, schedule.grab(), showSchedule.grab(), nowPlaying.grab())
    },
    fetch(id = null) {
      return api.channels.get().then(array => {
        storage.channels.update(array)
        dataPromise = null

        return storage.channels.get().array
      })
    },
    get(id = null) {
      dataPromise = this.update()

      return {
        value: this.grab(id), // return channels merged with schedule
        promise: dataPromise,
      }
    },
    isComplete,
    getExtended(id = null) {
      dataPromise = this.update()
      nowPlaying.get()
      if (id) {
        resources.mixes.trending.get(id)
        resources.artists.trending.get(id)
      }

      const retryPromise = retryPromiseCreator({
        conditionFc: () => dataPromiseExtended.length < 2 && !isComplete(),
      })
      const createPromiseExtended = id => {
        if (id) {
          return [
            dataPromise,
            schedule.getPromise(),
            showSchedule.getPromise(),
            nowPlaying.getPromise(),
            resources.shows.trending.getPromise(id),
            resources.mixes.trending.getPromise(id),
            resources.artists.trending.getPromise(id),
            retryPromise(),
          ].filter(el => el !== null)
        }
        return [
          dataPromise,
          schedule.getPromise(),
          showSchedule.getPromise(),
          nowPlaying.getPromise(),
          retryPromise(),
        ].filter(el => el !== null)
      }

      dataPromiseExtended = createPromiseExtended(id)

      const handlePromises = () => {
        dataPromiseExtended = createPromiseExtended(id)

        return {
          value: this.grab(id),
          promise: Promise.any(dataPromiseExtended).then(handlePromises),
        }
      }
      return handlePromises()
    },
  }
}
