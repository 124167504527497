import Api from './../api'
import DataLayer from './../data-layer/index'

DataLayer.$extend('mixes', {
  getById(id) {
    return Api.mixes.get({ id })
  },

  getLatest(limit = 10, offset = 0) {
    return Api.mixes.find({ limit, offset })
  },

  getTrending(limit = 10, offset =0) {
    return Api.mixes.find({ limit, offset, trending: true })
  },

  getPopularByArtist(artistsId, limit = 10, offset = 0) {
    return Api.mixes.find({ artists_id: artistsId, limit, offset, order: '-reach' })
  },

  getLatestByArtist(artistsId, limit = 12, offset = 0) {
    return Api.mixes.find({ artists_id: artistsId, limit, offset, order: '-creation_time' })
  },

  getPopularByShow(id, limit = 10, offset = 0) {
    return Api.mixes.find({ shows_id: id, limit, offset, order: '-reach' })
  },

  getLatestByShow(id, limit = 12, offset = 0) {
    return Api.mixes.find({ shows_id: id, limit, offset, order: '-creation_time' })
  },

  getRelatedByMix(id, limit = 6, offset = 0) {
    return Api.related.get({ mixes_id: id, limit, offset })
  },

  getPopularByGenre(id, limit = 10, offset = 0) {
    return Api.mixes.find({ genre: id, limit, offset, order: '-reach' })
  },

  getPopularByChannel(channelId, limit = 6, offset = 0) {
    return Api.mixes.find({ channel: channelId, limit, offset, trending: true })
  },

  getLatestByGenre(id, limit = 12, offset = 0) {
    return Api.mixes.find({ genre: id, limit, offset, order: '-creation_time' })
  },

  getUrl(mixesId) {
    return Api.mixes.url(mixesId)
  }
})
