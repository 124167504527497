<script>
import DataLayer from '@services/data-layer/index'
import Layout from '@layouts/main'
import ChannelDetailBlock from '@components/channel/channel-detail-block'

export default {
  page() {
    const title = 'Radio'
    const description = 'Radio'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: { Layout, ChannelDetailBlock },
  props: {
    isFavorites: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      channels: Array.from(Array(4), (e,i)=> ({
        id: i
      })),
      playlists: Array.from(Array(4), (e,i)=> ({
        id: i
      })).reduce((prev, curr) => {
        prev[curr.id] = Array.from(Array(4), (e,i)=> ({
          id: i,
          mixes: {

            show: {
              id: i,
              title: '',
              url: '',
              type: 'show',
              image: {},
              genre: [],
              artist_id: [],
              artist: [],
            },

            episode: {
              id: i,
              type: 'episode',
            },
            artist: {
              id: i,
              type: 'artist',
            },
          }
        }))
        return prev
      }, {})
    }
  },
  mounted() {
    const channelsOrder = ['chill', 'classics', 'deep', 'frisky'];

    DataLayer.channels.get()
      .then(response => {
        this.channels = Object.values(response.body).sort((a,b) => channelsOrder.indexOf(a.id) - channelsOrder.indexOf(b.id))
      })
    DataLayer.stationsPlaylists.subscribe(resposne => {
      this.playlists = resposne.body.reduce((playlists, item) => {
        if(!playlists[item.station]) playlists[item.station] = [];
        playlists[item.station].push(item)
        return playlists
      }, {})
    })
  },
}
</script>

<template>
  <Layout
    v-if="channels && channels.length"
    hidden-overflow>
    <h1 :class="$style.mainHeading">Radio</h1>
    <ChannelDetailBlock
      :channels="channels"
      :playlists="playlists"
      :is-favorites="isFavorites"/>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.layout {
  overflow: hidden;
}

.mainHeading {
  display: none;
  margin-top: $s-h;

  @include desktop {
    display: block;

    @include container;

    overflow: visible;
  }

  @include sidePadding;
}
</style>
