<script>
import ShowService from './../services/show-service'

export default {
  props: {
    resource: {
      type: [Object, Array],
      default: () => {},
    },
    icon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    playlist: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    playback() {
      return this.$store.state.playback
    },
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
    playing() {
      return (
        (this.playback.status === 'playing' || this.playback.status === 'loading') &&
        ((this.playback.resource && this.resource &&
        this.playback.resource.type === this.resource.type &&
        this.playback.resource.id === this.resource.id) || this.playlist && this.$store.state.queue.playlistId === this.playlist.id)
      )
    },
    currentIcon() {
      if (this.icon) {
        return this.playing ? 'pause' : 'play_arrow'
      }
      return ''
    },
    isMixLoaded() {
      return this.$store.state.playback.resource && this.resource.id === this.$store.state.playback.resource.id
    },
    buttonTitle() {
      return this.playing ? 'Pause' : 'Play'
    }
  },
  methods: {
    handleClick() {
      if (this.playlist) return

      if (this.isMixLoaded) return this.$queueService.pause()

      if (this.playing) {
        this.$queueService.pause()
      } else if (this.resource && this.resource.type && this.resource.type === 'channel') {
        this.$queueService.playRadioChannel(this.resource.id)
        .catch(e => {
          this.$store.dispatch('appearance/handleNotification', {message: e.message, type: 'warning'})
        })
      } else {
        this.$queueService.playMix(this.resource)
        .then(data => {
          this.increaseReach(this.resource.show_id.id)
        })
        .catch(e => {
          this.$store.dispatch('appearance/handleNotification', {message: e.message, type: 'warning'})
        })
      }
    },
    increaseReach(id) {
      // const id = this.resource.id

      ShowService().increaseSessionReach(id)
      this.$store.dispatch('shows/increaseReach')
    }
  },
}
</script>

<template>
  <BaseButton
    :icon="currentIcon"
    :button-type="type"
    :class="[(resource && resource.id) || (playlist && playlist.id) ? '' : $style.inactive]"
    @click="handleClick">
    <slot>
      <span
        :class="$style.buttonTitle"
        :title="buttonTitle">
        {{ buttonTitle }}
      </span>
    </slot>
  </BaseButton>
</template>

<style lang="scss" module>
@import '@design';

.inactive {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.3;
}
</style>
