const DATALAYER_CACHE_TTL = .5 * 3600 * 1e3;
const DATALAYER_CACHE_VERSION_KEY = 'version';

class DataLayerCache {
  constructor(storeName) {
    this._version = process.env.VUE_APP_STORAGE_VERSION;
    this.storeName = storeName;
    this.store = this.loadFromLocalStorage();
    this.cleanExpiredCacheItems();

    document.addEventListener("visibilitychange",() => this.cleanExpiredCacheItems());
  }

  loadFromLocalStorage() {
    let storeVersion = window.localStorage.getItem(DATALAYER_CACHE_VERSION_KEY)
    if(storeVersion !== this._version) {
      window.localStorage.setItem(this.storeName, '')
      window.localStorage.setItem(DATALAYER_CACHE_VERSION_KEY, this._version)
      return {}
    }

    let store = window.localStorage.getItem(this.storeName);
    if(store) {
      store = JSON.parse(store);
      Object.keys(store).forEach(modelName => {
        Object.keys(store[modelName]).forEach(modelId => {
          store[modelName][modelId] = new CacheItem(store[modelName][modelId]);
        });
      });
      return store;
  } else {
  return {};
}
}

saveToLocalStorage() {
  clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      window.localStorage.setItem(this.storeName, JSON.stringify(this.store));
    }, 700);
  }

  cleanExpiredCacheItems() {
    Object.keys(this.store).forEach(modelName => {
      Object.keys(this.store[modelName]).forEach(modelId => {
        if (this.store[modelName][modelId].isExpired()) {
          delete this.store[modelName][modelId];
        }
      });
    });
    this.saveToLocalStorage();
  }

  set(modelName, model, ttl = DATALAYER_CACHE_TTL) {
    if (!model.id) {
      return;
    }
    if (!this.store[modelName]) {
      this.store[modelName] = {};
    }
    this.store[modelName][model.id] = new CacheItem({data: model, ttl});
    this.saveToLocalStorage();
  }

  get(modelName, modelId) {
    if (!this.store[modelName]
        || !this.store[modelName][modelId]
        || this.store[modelName][modelId].isExpired()) {
      return undefined;
    }
    return this.store[modelName][modelId].data;
  }

}

class CacheItem {
  constructor({data, ttl, time = new Date()}) {
    this.time = typeof time === "string"? new Date(time) : time
    this.data = data
    this.ttl = ttl
  }

  isExpired() {
    return new Date() - this.time > this.ttl
  }
}

export default DataLayerCache;
