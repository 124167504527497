<script>
import Layout from '@layouts/main'
import HomeUnAuth from '@router/views/homepage/homepage-unauth'
import HomeAuth from '@router/views/homepage/homepage-auth'

export default {
  page() {
    const title = 'Home'
    const description = 'Homepage'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: {
    Layout,
    HomeUnAuth,
    HomeAuth,
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },

}
</script>

<template>
  <Layout hidden-overflow>
    <BaseContent :class="$style.content">
      <HomeAuth v-if="loggedIn"/>
      <HomeUnAuth v-else />
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

</style>
