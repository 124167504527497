

export const state = {
  keyword: '',
  previousRoute: () => {}
}

export const getters = {}

export const mutations = {
  UPDATE_KEYWORD(state, keyword) {
    state.keyword = keyword || ''
  },
  UPDATE_PREVIOUS_ROUTE(state, previousRoute) {
    state.previousRoute = previousRoute || {}
  }
}

export const actions = {}
