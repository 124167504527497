import Emitter from 'component-emitter'
import getAndUpdateRecursive from '@utils/get-and-update-recursive'

const stopEvent = new Emitter()

export const state = {
  channels: [],
  activeObject: null,
}

export const getters = {}

export const mutations = {
  SET_CHANNELS(state, channels) {
    state.channels = channels
  },
  SET_ACTIVE(state, channel) {
    state.activeObject = channel
  },
}

export const actions = {
  updateExtended({ commit }, dataLayer) {
    stopEvent.emit('reinit')
    getAndUpdateRecursive(
      input => commit('SET_CHANNELS', input),
      dataLayer.channels.getExtended()
    )
  },
}
