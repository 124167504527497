const Promise = require('bluebird')

export default function getAndUpdateRecursive(
  action,
  returnWithPromise,
  stopEvent
) {
  const actionResult = action(returnWithPromise.value)
  if (returnWithPromise.promise) {
    const stopFn = () => {
      returnWithPromise.promise.resolve(actionResult)
    }
    stopEvent && stopEvent.on('reinit', stopFn)

    return returnWithPromise.promise.then(value => {
      stopEvent && stopEvent.off('reinit', stopFn)
      return getAndUpdateRecursive(action, value, stopEvent)
    })
  }

  return new Promise(resolve => resolve(actionResult))
}
