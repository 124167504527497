<script>
import MixLargeTile from '@components/mix-large-tile'
import GenresList from '@components/genres-list'
import ArtistBlock from '@components/artist/artist-block'
import ShowLargeTile from '@components/show-large-tile'
import extractGenres from '@utils/extract-genres'

export default {
  components: {MixLargeTile, GenresList, ArtistBlock, ShowLargeTile},
  props: {
    bestMatchType: {
      type: String,
      default: ''
    },
    bestMatchModel: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    bestMatchGenre() {
      return this.bestMatchType === 'Genres' && this.bestMatchModel && extractGenres(this.bestMatchModel, 1) || null
    }
  },
}
</script>

<template>

  <BaseSection
    v-if="bestMatchType === 'Mixes'"
    padded>
    <MixLargeTile
      :mix="bestMatchModel"
      has-type-title
      extended/>
  </BaseSection>
  <BaseSection
    v-else-if="bestMatchType === 'Genres'"
    padded>
    <GenresList
      :genres="bestMatchGenre"
      has-type-title/>
  </BaseSection>
  <BaseSection
    v-else-if="bestMatchType === 'Artists'"
    padded>
    <ArtistBlock
      :artists="[bestMatchModel]"
      has-type-title
      extended />
  </BaseSection>
  <BaseSection
    v-else-if="bestMatchType === 'Shows'"
    padded>
    <ShowLargeTile
      :show="bestMatchModel"
      has-type-title
      extended/>
  </BaseSection>

</template>

<style lang="scss" module>
@import '@design';

</style>
