<script>
import PlaylistsGridTile from '@components/playlists/playlists-grid-tile'

export default {
  components: {
    PlaylistsGridTile,
  },
  props: {
    playlists: {
      type: Array,
      default: () => [],
    },
    minTileWidth: {
      type: String,
      default: '220px'
    },
    showMoreLink: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'playlists',
    },
  },
}
</script>

<template>
  <div
    :class="$style.block"
    :style="`gridTemplateColumns: repeat(auto-fill, minmax(${minTileWidth}, 1fr);`">
    <PlaylistsGridTile
      v-for="(playlist, index) in playlists"
      :playlist="playlist"
      :index="index"
      :class="$style.tile"
      :type="type"
      :key="playlist.id"/>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.block {
  display: grid;
  grid-gap: $size-large-grid-padding;
}

.tile {
  margin-bottom: $size-grid-padding * 2;
}
</style>
