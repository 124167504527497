export const state = {
  isNotificationOpen: false,
  notificationMessage: '',
  notificationType: '', // danger, warning, success
  scroll: 0,
  scrollChild: null,
  hasReload: false,
  isPersistentNotification: false,
  forceUpdate: false,
}

export const getters = {}

export const mutations = {
  SET_NOTIFICATION(state, { value, message, type, hasReload, isPersistentNotification, forceUpdate }) {
    state.isNotificationOpen = value
    state.notificationMessage = message
    state.notificationType = type
    state.hasReload = hasReload
    state.isPersistentNotification = isPersistentNotification
    state.forceUpdate = forceUpdate
  },
  SET_SCROLL(state, value) {
    state.scroll = value
  },
  SET_SCROLL_CHILD(state, element) {
    state.scrollChild = element
  },
}

export const actions = {
  handleNotification({ commit }, {message, type, hasReload, isPersistentNotification, forceUpdate }) {
    commit('SET_NOTIFICATION', { value: true, message: message, type: type, hasReload: hasReload, isPersistentNotification: isPersistentNotification, forceUpdate: forceUpdate })
  },
  closeNotification({ commit }) {
    commit('SET_NOTIFICATION', { value: false, message: '', type: '' })
  },
}
