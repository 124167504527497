<script>
import copyToClipBoard from '@utils/copy-to-clipboard'
import SocialShare from '@components/social-share'

export default {
  components: {
    SocialShare
  },
  props: {
    channel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    url() {
      const protocol = `${window.location.protocol}`
      const hostname = `${window.location.hostname}`
      const port = `${window.location.port}`
      return `${protocol}//${hostname}${port ? ':' + port : ''}${this.$router.resolve({name: 'channel', params: { channelId: this.channel.id }}).href}`
    },
  },
  methods: {
    copyLinkToClipBoard() {
      copyToClipBoard(this.url)
      this.$store.dispatch('appearance/handleNotification', { message: this.$m('LINK_COPIED_CLIPBOARD'), type: 'success' })
    },
    handleChannelFavoriteClick(channel) {
      if (this.loggedIn) {
        this.$store.dispatch('settings/toggleToFavorites', channel.id)
      } else {
        this.$router.push({ name: 'login' })
      }
    },
  }
}
</script>

<template>
  <ul :class="$style.dropdownMenu">
    <li
      :class="$style.dropdownItem"
      @click="$router.push({ name: 'channel', params: { channelId: channel.id } })">
      <BaseButton
        icon="eye"
        size="medium"
        button-type="transparent"
        @click="() => {}">&nbsp; View Channel</BaseButton>
    </li>
    <li :class="$style.dropdownItem">
      <BaseFavourites
        v-if="channel"
        :channel="channel"
        size="medium"
        button-type="transparent">
        <span slot="main">Add Channel to Favorites</span>
        <span slot="alt">Remove Channel from Favorites</span>
      </BaseFavourites>
    </li>
    <h6 :class="$style.shareTitle">Share</h6>
    <div :class="$style.separator"/>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="facebook"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          type="facebook" />
      </BaseButton>
    </li>
    <li :class="$style.dropdownItem">
      <BaseButton
        icon="twitter"
        size="medium"
        button-type="transparent">
        <SocialShare
          :url="url"
          :custom-text="`${channel.title} on @friskyradio #feelinfrisky`"
          type="twitter" />
      </BaseButton>
    </li>
    <li
      :class="$style.dropdownItem"
      @click="copyLinkToClipBoard">
      <BaseButton
        icon="link"
        size="medium"
        button-type="transparent"
        @click="() => {}">&nbsp;Copy Channel link</BaseButton>
    </li>

  </ul>
</template>

<style lang="scss" module>
@import '@design';

.dropdown{
  &Menu {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &Item {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 $s-m;
  }
}

.separator {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  background: $c-mine-shaft;
}

.shareTitle {
  @extend %contentTitleM;

  margin: $s-s 0 $s-s $s-xl;
}
</style>
