<script>
import DataLayer from '@services/data-layer/index'

export default {
  props: {
    action: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: 'Learn more...'
    }
  },
  data() {
    return {
      playlist: {},
      playlistMixes: [],
      playlistLoaded: false,
      totalMixes: null,
      limit: 20
    }
  },
  computed: {
    isCurrentPlaylistPlaying() {
      if(this.action && this.action.type && this.action.type === 'playPlaylist')
       return Number(this.action.value) === this.$store.state.queue.playlistId
      return false
    },
  },
  mounted() {
    this.action && this.action.type && this.action.type === 'playPlaylist' && this.getPlaylist(this.action.value)
  },
  methods: {
    getPlaylist(playlistId) {
      DataLayer.playlists.getById(playlistId)
        .then(playlist => {
          this.playlist = playlist.body
        })
    },
    getPlaylistMixesAndPlay({ playlistId = this.action.value, limit = this.limit, offset = 0 }) {
      if(this.totalMixes === this.playlistMixes.length) {
        this.playPlaylist()
        return
      }
      DataLayer.playlists.getRevisionsMixes(playlistId, limit, offset)
        .then(mixes => {
          this.totalMixes = Number(mixes.headers['x-items-total'])
          this.playlistMixes = mixes.body.map(revisionsMixes => revisionsMixes.mixes)
          this.getPlaylistMixesAndPlay({ limit: this.totalMixes })
        })
    },
    playPlaylist() {
      if(this.isCurrentPlaylistPlaying) {
        this.$queueService.pause()
      }else {
        this.$queueService.playMixMultiple({mixes: [...this.playlistMixes], id: this.playlist.id, position: 0})
      }
    }
  }
}
</script>

<template>
  <div v-if="action">
    <BaseButtonPlay
      v-if="action.type === 'playChannel'"
      :resource="({type: 'channel', id: action.value})"
      :type="type"
      icon/>
    <BaseButtonPlay
      v-if="action.type === 'playMix'"
      :resource="({type: 'mix', id: action.value})"
      :type="type"
      icon/>
    <div
      v-if="action.type === 'playPlaylist'"
      @click="getPlaylistMixesAndPlay({})">
      <BaseButtonPlay
        :icon="true"
        :playlist="playlist"
        :type="type"
        :loading-playlist-mixes="true"
      />
    </div>

    <BaseLink
      v-if="action.type === 'navigateInternal'"
      :title="title"
      :aria-label="title"
      :to="{ path: action.value }"
    >
      <BaseButton
        :button-type="type"
        :title="title"
        :arial-label="title"
        @click="() => {}">Learn More</BaseButton>
    </BaseLink>
    <BaseLink
      v-if="action.type === 'navigateExternal'"
      :href="action.value"
    >
      <BaseButton
        :button-type="type"
        @click="() => {}">Learn More</BaseButton>
    </BaseLink>
  </div>
</template>

<style lang="scss" module>
@import '@design';

</style>
