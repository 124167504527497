<script>
import ShowScheduleTile from '@components/show-schedule-tile'

export default {
  components: {
    ShowScheduleTile,
  },
  props: {
    resources: {
      type: Array,
      default: () => [],
    },
    error: {
      type: [Object, Boolean],
      default: false
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <ShowScheduleTile
      v-for="resource in resources"
      :key="`show-schedule-item-${resource.air_start + resource.id || resource.id }`"
      :schedule-item="resource"
      artwork
    />
    <BaseEmptyList
      v-if="resources.length === 0 && error"
      :error="error"
    />
  </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.container {
  min-height: 14rem;
  padding-bottom: math.div($s-h, 2);
}
</style>
