import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('bfhs', {

  getAll({ limit = 10, offset = 0, order = '-modification_time' }) {
    return Api.bfhs.get({ limit, offset, order })
  },

});
