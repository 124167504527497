<script>
import MixTableBlock from '@components/mix-table-block'
import BackArrow from '@components/back-arrow'
import DataLayer from '@services/data-layer/index'

export default {
  components: { MixTableBlock, BackArrow },
  data() {
    return {
      currentQueue: [],
      queueHistory: []
    }
  },

  computed: {
    queueLength() {
      return this.$store.state.queue && this.$store.state.queue.mixes && this.$store.state.queue.mixes.length || null
    },
    isQueuePlayling() {
      return !!this.queueLength
    },
    queue() {
      return this.$store.state.queue.mixes && this.$store.state.queue.mixes.length ? this.$store.state.queue.mixes : this.$store.state.playback.resource
        ? [this.$store.state.playback.mix]
        : []
    },
    upNextQueue() {
      return this.$store.state.playback.resource &&
        this.$store.state.playback.resource.type === 'channel'
        ? this.$store.state.playback.resource.nowPlaying
            .map(mix => mix.mix)
            .slice(1)
        : []
    },
    isQueueActive() {
      return !!this.$store.state.playback.resource || (this.$store.state.queue && this.$store.state.queue.mixes && this.$store.state.queue.mixes.length) || true
    },
    history() {
      return this.$store.state.playback && this.$store.state.playback.history && this.$store.state.playback.history.slice(1) || []
    },
    activePlaylistQueue() {
      return this.$store.state.queue
    }
  },

  watch: {
    history() {
      this.getHistory()
    },
    queue() {
      this.getCurrentQueue()
    }
  },

  mounted() {
    this.getCurrentQueue()
    this.getHistory()
  },

  methods: {
    closeDialog() {
      const { name, params, query } = this.$route
      const { open, ...newQuery } = query
      this.$router.push({ name, params, newQuery })
    },
    getHistory() {
      this.queueHistory = []
      const promises = this.history && this.history.length && this.history
        .map(id => {
          return DataLayer.mixes.getById(id)
        })
      promises && promises.length && Promise.all(promises).then(values => {
        values.map(res => this.queueHistory.push(res.body))
      })
    },
    getCurrentQueue() {
      this.currentQueue = []
      const promises = this.queue && this.queue.length && this.queue
        .map((mix, i) => {
          return !!mix && DataLayer.mixes.getById(mix.id)
        })
      promises && promises.length && Promise.all(promises).then(values => {
        values.map(res => this.currentQueue.push(res.body))
      })
    }
  },
}
</script>

<template>
  <div
    v-if="isQueueActive"
    :class="[$style.queue, {[$style.open]: $route.query.open && $route.query.open === 'queue'}]">
    <div :class="$style.queueContent">
      <BaseContent>
        <BaseSection padded>
          <BackArrow :class="$style.arrow"/>
          <h1 :class="$style.mainHeading">Play Queue</h1>
        </BaseSection>
        <BaseTabList :is-underlined="true">
          <BaseTab
            label="Queue"
            active>
            <BaseSection
              padded>
              <h6 :class="$style.heading">Now Playing</h6>
              <MixTableBlock
                :mixes="currentQueue"
                :is-queue-mix="isQueuePlayling"/>
            </BaseSection>
            <BaseSection
              v-if="upNextQueue.length > 0"
              padded>
              <h6 :class="$style.heading">Up Next</h6>
              <MixTableBlock :mixes="upNextQueue"/>
            </BaseSection>
          </BaseTab>
          <BaseTab label="History">
            <BaseSection
              v-if="queueHistory.length > 0"
              padded>
              <h6 :class="$style.heading">Recently Played</h6>
              <MixTableBlock :mixes="queueHistory"/>
            </BaseSection>
          </BaseTab>
        </BaseTabList>
      </BaseContent>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading {
  margin-top: $s-xl;
  margin-bottom: $s-xl;
}

.queue {
  position: fixed;
  top: 0;
  left: $size-side-bar;
  z-index: $layer-modal-z-index;
  display: none;
  width: calc(100% - #{$size-side-bar});
  height: 100%;
  background: $c-body-bg;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  &.open {
    transform: translateY(0);
  }

  @include desktop {
    display: block;
  }
}

.queueContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: $s-h;
  overflow-y: auto;
}

.iconContainer {
  position: relative;
  min-height: 1px;
}

.arrow {
  display: block;
  margin-top: $s-xxl;
}
</style>
