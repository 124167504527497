<script>
export default {
  props: {
    unpadded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <div :class="[$style.dots, unpadded ? $style.dotsUnpadded : '']">...</div>
</template>

<style lang="scss" module>
@import '@design';

.dots {
  display: inline-block;
  padding-right: $s-l;
  font-family: Times, Serif;
  font-size: 24px;
  font-weight: bold;
  color: $c-white;
  vertical-align: top;

  &Unpadded {
    padding-right: 0;
  }
}
</style>
