<script>
import MixTableTile from '@components/mix-table-tile.v2'

export default {
  components: {
    MixTableTile,
  },
  props: {
    mixes: {
      type: Array,
      default: () => [],
    },
    hasLinkToArtistPage: {
      type: Boolean,
      default: false,
    },
    genres: {
      type: Boolean,
      default: false,
    },
    isIndexedList: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Object,
      default: () => {}
    },
    error: {
      type: [Object, Boolean],
      default: false
    },
    shouldTransition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prevMixLength: 0
    }
  },
  watch: {
    mixes(next, prev) {
      this.prevMixLength = prev.length
    },
  },
}
</script>

<template>
  <div>

    <transition-group
      :name="shouldTransition ? 'slowfade' : 'no-transition'"
      tag="div">
      <MixTableTile
        v-for="(mix, index) in mixes"
        :mix="mix"
        :index="index"
        :key="'mixTable' + $route.name + mix.id + index"
        :has-link-to-artist-page="hasLinkToArtistPage"
        :genres="genres"
        :buttons="buttons"
        :is-indexed-list="isIndexedList"/>
    </transition-group>

    <BaseEmptyList
      v-if="mixes.length === 0 && error"
      :error="error"/>
  </div>
</template>
