<script>
import Bfhs from '@views/homepage/homepage-modules/homepage-bfhs'
import FeaturedPlaylists from '@views/homepage/homepage-modules/homepage-featured-playlists'
import FeaturedCharts from '@views/homepage/homepage-modules/homepage-featured-charts'
import LatestMixes from '@views/homepage/homepage-modules/homepage-latest-mixes'
import ChartBox from '@views/homepage/homepage-modules/homepage-chart-box'
import TrendingMixes from '@views/homepage/homepage-modules/homepage-trending-mixes'
import HomeRadio from '@views/homepage/homepage-modules/homepage-radio'
import TrendingDjs from '@views/homepage/homepage-modules/homepage-trending-djs'

export default {

  name: "UnAuthHomepage",

  components: {
    Bfhs,
    FeaturedPlaylists,
    FeaturedCharts,
    LatestMixes,
    ChartBox,
    TrendingMixes,
    HomeRadio,
    TrendingDjs,
  },

}
</script>

<template>
  <div>
    <Bfhs />
    <FeaturedPlaylists />
    <FeaturedCharts />
    <LatestMixes />
    <ChartBox />
    <TrendingMixes />
    <HomeRadio />
    <TrendingDjs />
  </div>
</template>

<style lang="scss" module>
@import '@design';

</style>
