import createGettableListCreator from '@services/data-layer/create-gettable-list'
import retryPromiseCreator from '@utils/retry-promise'

const Promise = require('bluebird')

export default function genresCreator({ api, resources }) {
  const createGettableList = createGettableListCreator({ resources })
  const popularMixesList = createGettableList(api.mixes.getGenreTrending, 5, 0)
  const allMixesList = createGettableList(api.mixes.getGenreLatest, 12, 0)
  const artistsList = createGettableList(
    api.artists.getGenreRandom,
    12,
    0,
    item => resources.artists.getById(item.id).value || item
  )

  const dataPromise = []

  const retryPromise = retryPromiseCreator({
    conditionFc: id => !(allMixesList.isComplete(id) && artistsList.isComplete(id) && popularMixesList.isComplete(id)),
  })

  return {
    popularMixesList,
    allMixesList,
    artistsList,
    getPromise(id) {
      dataPromise[id] = [
        resources.artists.getPromise(),
        resources.mixes.getPromise(),
        popularMixesList.promise[id] || null,
        allMixesList.promise[id] || null,
        artistsList.promise[id],
        retryPromise(id),
      ].filter(el => {
        return el !== null
      })

      return dataPromise[id]
    },
    get(genreTitle) {
      const handlePromises = () => {
        const value = {
          popularMixes: popularMixesList.getExtended(genreTitle),
          allMixes: allMixesList.getExtended(genreTitle),
          artists: artistsList.getExtended(genreTitle),
        }

        const promises = this.getPromise(genreTitle)
        return {
          value,
          promise: promises.length > 0 ? Promise.any(promises).then(handlePromises) : null,
        }
      }
      return handlePromises()
    },
  }
}
