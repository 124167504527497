<script>
export default {
  name: 'SocialShare',
  props: {
    url: {
      type: String,
      default: '',
      required: true,
    },
    customText: {
      type: String,
      default: 'Hello World',
    },
    type: {
      type: String,
      default: '',
      required: true,
    }
  },
  computed: {
    facebookSharer() {
      return `https://www.facebook.com/dialog/share?app_id=${126928420652882}&href=${this.url}`
    },
    twitterSharer() {
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.customText)} ${this.url}`
    },
  },

  methods: {
    share() {
      const shareType = this.type === 'facebook' ? this.facebookSharer : this.twitterSharer
      window.open(shareType, `${this.type} Share Window`, 'width=400, height=300')
    },
  }
}
</script>

<template>
  <span
    :class="$style.link"
    @click="share">{{ type === 'facebook' ? 'Facebook' : 'Twitter' }}</span>
</template>

<style lang="scss" module>
@import '@design';

.link{
  display: block;
  pointer-events: auto;
  cursor: pointer;
}
</style>
