import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import store from './../state/store'
import routes from './routes'
import localSave from './../utils/local-save'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page',
  tagIDKeyName: 'vmid',
})

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = () => {
    return routeTo.matched.some(route => route.meta.authRequired)
  }

  const redirectToLogin = () => {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }

  const saveRedirectUrl = () => {
    const exceptions = ['login', 'signup', 'forgot-password', 'reset-password']
    // removed 'home' exception due to redirecting issue from various pages
    if(exceptions.indexOf(routeTo.name) === -1) {
      localSave.save('redirectURL', routeTo.path)
    }
  }

  const saveHistory = () => {
    store.commit(
      'status/SET_HISTORY_LENGTH',
      store.state.status.historyLength + 1
    )
  }

  saveHistory()

  saveRedirectUrl()

  if (store.getters['auth/loggedIn']) {
    return store.dispatch('auth/userExists')
      .then(existedUser => {
        existedUser && next()
      })
  }

  if(authRequired()){
    return store.dispatch('auth/validate')
      .then(validUser => {
        validUser ? next() : redirectToLogin()
      })
  }

  next()
})

export default router
