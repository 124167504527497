import Api from './../api';
import DataLayer from './../data-layer/index';

DataLayer.$extend('auth', {

  login(data) {
    return Api.auth.login({}, data)
  },

});
