const setCookie = (name, value, days = 7, path = '/', domain = '*') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}; domain=${domain}`
}

const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

const deleteCookie = ({ name, path = '/', domain }) => {
  setCookie(name, '', -1, path, domain)
}

export { setCookie, getCookie, deleteCookie }
