<script>
export default {
  props: {
    mix: {
      type: Object,
      default: () => {},
    },
    artist: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Object,
      default: () => {},
    },
    channel: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: 'small', // none, small, iconOnly, large
    },
    buttonType: {
      type: String,
      default: 'tag', // tag, transparent, primary
    },
    buttonTypeActive: {
      type: [String, Boolean],
      default: false,
    },
    displayCounter: {
      type: Boolean,
      default: true,
    },
    iconMain: {
      type: String,
      default: 'heart-outlined',
    },
    iconAlt: {
      type: String,
      default: 'heart-outlined',
    }
  },

  data() {
    return {
      countCache: 0,
      isReady: true,
    }
  },

  computed: {
    favCount() {
      return this.mix && this.mix.favorite_count
    },
    totalCount() {
      return this.mix && this.favCount + this.countCache
    },
    isLoggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
    isActive() {
      if(this.mix) {
        return !!this.$store.state.favorites.mixesList.find(el => el.id === this.mix.id)
      }else if(this.artist) {
        return !!this.$store.state.favorites.artistsList.find(el => el.id === this.artist.id)
      }else if(this.show) {
        return !!this.$store.state.favorites.showsList.find(el => el.id === this.show.id)
      }else if(this.channel) {
        return !!this.$store.state.settings.favorites.find(el => el === this.channel.id)
      }else {
        return false
      }
    },
    add() {
      return this.mix ? this.addMix : this.artist ? this.addArtist : this.show ? this.addShow : this.addChannel
    },
    remove() {
      return this.mix ? this.removeMix : this.artist ? this.removeArtist : this.show ? this.removeShow : this.removeChannel
    },
    buttonTypeActiveEvaluated() {
      return this.buttonTypeActive || this.buttonType
    }
  },

  watch: {
    isActive: function() {
      this.isActive ? this.countCache++ : this.countCache--
      this.isReady = true
    },
  },

  methods: {
    addRemoveFav() {
      if (this.isLoggedIn) {
        if (this.isReady) {
          this.isReady = false
          this.isActive ? this.remove() : this.add()
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    addMix() {
      this.$dataLayer.favMixes.add(this.mix.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_MIX_ADDED'), type: 'success' })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    removeMix() {
      this.$dataLayer.favMixes.remove(this.mix.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_MIX_REMOVED'), type: 'success' })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    addArtist() {
      this.$dataLayer.favArtists.add(this.artist.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_ARTIST_ADDED'), type: 'success' })
        })
        .catch(_err => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    removeArtist() {
      this.$dataLayer.favArtists.remove(this.artist.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_ARTIST_REMOVED'), type: 'success' })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    addShow() {
      this.$dataLayer.favShows.add(this.show.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_SHOW_ADDED'), type: 'success' })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    removeShow() {
      this.$dataLayer.favShows.remove(this.show.id)
        .then(_res => {
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('FAV_SHOW_REMOVED'), type: 'success' })
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'warning' })
        })
    },
    addChannel() {
      this.$store.dispatch('settings/toggleToFavorites', this.channel.id)
    },
    removeChannel() {
      this.$store.dispatch('settings/toggleToFavorites', this.channel.id)
    }
  },
}
</script>

<template>
  <BaseButton
    :active="isLoggedIn && isActive"
    :button-type="isActive ? buttonType : buttonTypeActiveEvaluated"
    :size="size"
    :class="[$style.button, buttonType === 'transparent' ? $style.buttonTransparent : '', isActive ? $style.buttonActive : '', (mix && mix.id) || (artist && artist.id) || (show && show.id) || (channel && channel.id) ? '' : $style.inactive]"
    :icon="size === 'large' ? false : isActive ? iconMain : iconAlt"
    @click="addRemoveFav"
  >
    <slot v-if="!$slots['main'] && displayCounter && size !== 'none' ">{{ size !== 'icon-only' ? Number( totalCount > 0 ? totalCount : isActive ? 1 : 0 ).toLocaleString() : '' }}</slot>
    &nbsp;
    <slot
      v-if="!isActive"
      name="main"/>
    <slot
      v-else
      name="alt"/>
  </BaseButton>
</template>

<style lang="scss" module>
@import '@design';

.button {
  color: $c-white;

  &Transparent {
    &:hover svg, &.buttonActive svg {
      fill: $c-cerise-red;
    }
  }
}

.inactive {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.3;
}
</style>
