<script>
import InfoLayout from '@layouts/info'

export default {
    components: { InfoLayout },
}
</script>

<template>
  <InfoLayout>
    <div :class="$style.privacyPolicy">
      <h1 :class="$style.title">FRISKY Privacy Policy</h1>
      <p :class="$style.updated">Last Updated: April 2, 2023</p>
      <h2 :class="$style.heading">Contents</h2>
      <ol>
        <li>
          <p>About this Policy / Who We Are</p>
        </li>
        <li>
          <p>Data Collection</p>
        </li>
        <li>
          <p>Data Usage</p>
        </li>
        <li>
          <p>Data Deletion&nbsp;</p>
        </li>
        <li>
          <p>Disclosure Information</p>
        </li>
        <li>
          <p>Information Security</p>
        </li>
        <li>
          <p>Communications</p>
        </li>
        <li>
          <p>Children&rsquo;s Privacy</p>
        </li>
        <li>
          <p>Effective Date</p>
        </li>
        <li>
          <p>Contact Us</p>
        </li>
      </ol>
      <h2 :class="$style.heading">1. About this Policy / Who We Are</h2>
      <p>FRISKY&trade; is operated by Frisky Group, Inc., a New York Corporation (the &quot;Company&quot;). This Privacy Policy describes how we process your personal data at FRISKY. From now on, lets keep it simple and call it the &ldquo;Policy.&rdquo;&nbsp;</p>
      <p>The following discloses our information gathering and dissemination practices relating to the websites located at frisky.fm, friskyradio.com, friskyne.ws and related mobile applications (FRISKY for iOS and Android) and other services that we may make available from time to time to users (together, lets call it &quot;Services&quot;).&nbsp;By using the Services, you consent to the collection and use by us of any personal and other information you provide while using the Services in accordance with our Privacy Policy (this &quot;Policy&quot;). If you do not agree to the Privacy Policy, please do not use the Services.</p>
      <p>If you have any questions about how we collect, store and use your personal information and data please contact us as follows or in order to access or correct your personal information:</p>
      <p>Email us:&nbsp;privacy@friskyradio.com</p>
      <p>This Privacy Policy is supplemental to our<a href="https://www.friskyradio.com/about/tos/">&nbsp;Terms and Conditions</a>.&nbsp;</p>
      <h2 :class="$style.heading">2. Data Collection</h2>
      <p>Data collected automatically when accessing the Services:</p>
      <ul>
        <li>
          <p>Which type/version of web browser and device you are accessing the Services</p>
        </li>
        <li>
          <p>Geo-location based on (1) information you manually provide, (2) the IP address of your computer, (3) Global Positioning System (GPS) information sent to us by your GPS-enabled device, and (4) the proximity of your computer, tablet, mobile wireless device, personal digital assistant, personal communication system, or other communications device (your &quot;Personal Communication Device&quot;) to the nearest radio tower or cell site. &nbsp;</p>
        </li>
        <li>
          <p>The time, frequency and duration of your visits</p>
        </li>
        <li>
          <p>Specific actions taken</p>
        </li>
      </ul>
      <p>Data provided by you:</p>
      <ul>
        <li>
          <p>For all users: any search terms you&rsquo;ve entered</p>
        </li>
        <li>
          <p>For any registered user: When signing up for an account, your email address, username, and email notification preferences, your full name, gender and date of birth. We collect information regarding your interaction with email messages, for example, whether you opened, clicked on, or forwarded any email message.</p>
        </li>
        <li>
          <p>Payment Data:&nbsp;Payment method type (Paypal, credit or debit card). if using a debit or credit card, the card type, expiration date, and certain digits of your card number. &nbsp;Note: For security, we never store your full card number.&nbsp;If paying by PayPal, your PayPal address.&nbsp;</p>
        </li>
      </ul>
      <p>Data collected automatically from registered users when listening to music on our website:</p>
      <ul>
        <li>
          <p>Your listening behavior: what you played, downloaded and favorited, &nbsp;when you played it, and how you played it -- including any skips or stops. &nbsp;When you added a show or artist to your favorites list.&nbsp;</p>
        </li>
      </ul>
      <h4>Site Visitorship Data</h4>
      <p>Site visitorship data is information about the way a website is used that is not associated with an individual&rsquo;s identity. We use anonymous data regarding online behavior to better understand how people use our Services. To that end, we may use aggregated, anonymous online traffic behavior along with information from third parties to track usage trends and thereby improve the Services. We may also use this aggregated, anonymous information to develop reports that we may share with third party marketing partners and affiliates. The kind of information we may collect includes:</p>
      <ul>
        <li>
          <p>the date and time you access the Services;</p>
        </li>
        <li>
          <p>your internet service provider, mobile carrier, or data services provider;</p>
        </li>
        <li>
          <p>your Internet Protocol (IP) address;</p>
        </li>
        <li>
          <p>the pages you visit;</p>
        </li>
        <li>
          <p>the links you click;</p>
        </li>
        <li>
          <p>the features used;</p>
        </li>
        <li>
          <p>how and when you interact with the services;</p>
        </li>
        <li>
          <p>the content, music and advertisements you select; and</p>
        </li>
        <li>
          <p>how you arrived at the Services and where you go when you leave.</p>
        </li>
      </ul>
      <p>Most site visitorship data is collected using data collection tools such as web beacons, cookies, embedded web links and other software (together, &quot;Data Collection Tools&quot;). Data Collection Tools are typically small data files or software that are sent to your browser and stored on your computer&apos;s or mobile device&apos;s hard drive. Most browsers and display software can be set to inform you when Data Collection Tools are being used or other tracking software is being sent to your computer or Personal Communication Device. They also provide you with the option of refusing such Data Collection Tools. However, refusing to allow the use of Data Collection Tools may, in some cases, preclude you from using, or negatively impact the display or function of, certain areas or features of the Services, including preventing you from purchasing products.</p>
      <p>Data Collection Tools may store the information in your shopping cart and allow us to relate your use of the Services to other information about you, including your Personal Information. We may do this to improve and personalize your experience with the Services and help resolve problems you experience through your use of the Services.</p>
      <h2 :class="$style.heading">3. Data Usage</h2>
      <ul>
        <li>
          <p>To provide you with access to the Services: your username and password are used to identify you when signing into the Services, in order to:<br><br></p>
          <ul>
            <li>
              <p>On FRISKY: To keep track of what you listen to or what you add to favorites, to calculate statistics, like reach and listener counts.&nbsp;</p>
            </li>
            <li>
              <p>On the DJBooth: To identify you as the artist / DJ of the show, mixes, and other content that you upload or are associated with.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>If you purchase a subscription: your name, address and other payment verification/identification information will be used to process your payments and grant the access and benefits that are provided by your subscription. All payment verification information is transmitted using industry-standard SSL (Secure Socket Layer) encryption between FRISKY and a PCI compliant payment gateway such as PayPal or Stripe.</p>
        </li>
        <li>
          <p>In order to improve and customize our platform, FRISKY uses the data we collect:<br><br></p>
          <ul>
            <li>
              <p>To keep track of activity, such listening to music, to generate statistics over time</p>
            </li>
            <li>
              <p>To analyze the use of the Platform to help improve it:</p>
              <ul>
                <li>
                  <p>To help trace issues using 3rd party tools like BugSnag and PaperTrail</p>
                </li>
                <li>
                  <p>We use local storage in your web browser to identify which version of our website you&rsquo;re running, so we can notify you whenever a new version of the website available. Local storage is also used for caching of website data to increase speed and performance while browsing our websites.</p>
                </li>
                <li>
                  <p>To customize your experience on the Platform and/or the content of any email newsletter or other material that we may send to you from time to time.</p>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2 :class="$style.heading">4. Data Deletion</h2>
      <p>Users can request their account and data be deleted by emailing&nbsp;support@friskyradio.com..</p>
      <h2 :class="$style.heading">5. Disclosure of Information</h2>
      <p>We will not release any personally identifiable information to third parties without your consent. We will not share your personal information to third parties - ever. We will not spam you - ever. &nbsp;</p>
      <h2 :class="$style.heading">6. Information Security</h2>
      <p>Whenever you enter an area of the site that carries or requires you to enter sensitive information such as your credit card details, a padlock icon will appear in the address bar of your browser, indicating that the page is secure. FriskyRadio uses Secure Sockets Layer (SSL) and Private Communications Technology security standards that are supported by most modern browsers. SSL encodes your personal information by encrypting credit card number, name, address, phone number, identification number and your password so that they cannot be read by unauthorized parties.&nbsp;As a reminder, we do not store any credit card or Paypal information.&nbsp;<br><br></p>
      <h2 :class="$style.heading">7. Communications</h2>
      <p>From time to time, FriskyRadio and its affiliates may communicate with you regarding your transactions and the administration of your account, current projects, special offers, invitations to events, offers for services, new product previews and updates to the Services, press releases and announcements concerning the services we provide, contests and sweepstakes, as well as important changes in our<a href="https://www.friskyradio.com/about/tos/">&nbsp;Terms of Use</a> and this Privacy Policy. By providing us with your e-mail address and/or your mobile phone number, you consent to receiving such communications. If at any time you decide for any reason that you no longer wish to receive such communications, you may &quot;opt out&quot; of receiving them by using one of the following methods:</p>
      <ul>
        <li>
          <p>Select the &quot;opt out&quot; or &quot;unsubscribe&quot; link, or follow the opt-out instructions that may be included in such communication.</p>
        </li>
        <li>
          <p>Where applicable, return to the web page(s) or service where you originally registered your preferences and follow the opt-out instructions.</p>
        </li>
        <li>
          <p>E-mail us at&nbsp;privacy@friskyradio.com. Be sure to provide your name, e-mail address and postal address, and specific relevant information about the particular service you no longer wish to receive.</p>
        </li>
      </ul>
      <p>Please be aware that when you opt out of receiving communications, it may affect the services you have chosen to receive from us where accepting the communications is a condition of receiving such services. By registering as a user or entering or participating in challenges&nbsp;through the website, you consent to receiving administration and related communications.</p>
      <h2 :class="$style.heading">8. Children&apos;s Privacy</h2>
      <p>We do not knowingly collect or distribute personally identifiable information from children under the age of 13. If you are the parent or guardian of a child under the age of 13 and believe that we have collected personally identifiable information contrary to this policy or, in the United States, the Children&rsquo;s Online Privacy Protection Act (COPPA), please contact us by e-mail so that we may delete such information. You can learn more about COPPA at the United States Federal Trade Commission website:<br><br><a href="http://www.business.ftc.gov/privacy-and-security/children%E2%80%99s-privacy">&nbsp;http://www.business.ftc.gov/privacy-and-security/children%E2%80%99s-privacy</a></p>
      <h2 :class="$style.heading">9. Effective Date</h2>
      <p>This Privacy Policy is effective as of May 1, 2012. We reserve the right to change this Privacy Policy at any time. If we change it, we will post the then-current Privacy Policy on this page. Any changes to this Privacy Policy will be effective as of the day they are posted.</p>
      <h2 :class="$style.heading">10. Contact Us</h2>
      <p>To contact us with your questions or comments please email us at privacy@friskyradio.com. &nbsp;</p>
    </div>
  </InfoLayout>
</template>

<style lang="scss" module>
@import '@design';

.title {
    line-height: 1;
    margin-bottom: 0;
}

.updated {
    margin-bottom: 2.5rem;
}

.heading {
    @include h4;
}

@include tablet {
    .title {
        font-size: 3.5rem;
    }
}
</style>
