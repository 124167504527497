<script>
import DataLayer from '@services/data-layer/index'
import Layout from '@layouts/main'
import PlaylistsGridBlock from '@components/playlists/playlists-grid-block'

export default {
  page() {
    const title = 'Charts'
    const description = 'Charts'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: { Layout, PlaylistsGridBlock },
  data() {
    return {
      charts: [],
      featuredCharts: []
    }
  },
  mounted() {
    DataLayer.playlists.getByType({ type: 'chart' })
      .then(charts => {
        charts.body.filter(playlist => playlist.revisions_id !== null && playlist.revisions_album_art && playlist.revisions_album_art.filename).map(playlist => {
          playlist.featured === 1 ? this.featuredCharts.push(playlist) : this.charts.push(playlist)
        })
    })
  },
}
</script>

<template>
  <Layout>
    <div :class="$style.mainHeading">Charts</div>
    <BaseContent>
      <BaseSection
        v-if="featuredCharts && featuredCharts.length"
        title="Featured Charts"
        padded>
        <PlaylistsGridBlock
          :playlists="featuredCharts"
          type="charts"
        />
      </BaseSection>
      <BaseSection
        padded>
        <PlaylistsGridBlock
          :playlists="charts"
          type="charts"
        />
      </BaseSection>
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading {
  @include h3;

  margin-top: $s-h;
  margin-bottom: $s-xxl;
  letter-spacing: 0.25px;
  &::after {
    display: block;
    min-height: 1px;
    margin-top: $s-xl;
    content: '';
    background: $c-dusty-gray;
    opacity: 0.1;
  }

  @include desktop {
    @include container;
    @include h1;

    margin-bottom: $s-xxl;
  }

  @include sidePadding;
}
</style>
