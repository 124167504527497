import DataLayerCache from './cache'
import NProgress from 'nprogress/nprogress'
NProgress.configure({ showSpinner: false, trickleSpeed: 150, minimum: 0.3, speed: 500 });


const DataLayer = {

  $activeEndpoints: 0,
  $store: new DataLayerCache('friskyDataLayer'),

  $extend(moduleName, declarations) {
    if(this[moduleName]) {
      throw new Error(`Module '${moduleName}' already declared.`)
    }

    this[moduleName] = {}
    Object.entries(declarations).forEach(([methodName, apiCall]) => {
      this[moduleName][methodName] = createEndpoint.call(this, apiCall)
    })
  },

  $wrap(apiCall) {
    return function() {
      return apiCall.apply(null, Array.from(arguments))
        .then(cacheModels)
        .then(populateModels)
    }
  }
}

export default DataLayer;


function createEndpoint(apiCall) {
  return function() {
    this.$activeEndpoints++
    NProgress.start()
    return this.$wrap(apiCall).apply(null, Array.from(arguments))
      .then(response => {
        this.$activeEndpoints--
        if(this.$activeEndpoints === 0) {
          NProgress.set(1).done()
        }
        return response
      })
      .catch(error => {
        this.$activeEndpoints = 0
        NProgress.done()
        throw error;
      })
  }.bind(this)
}

function cacheModels(response) {
  if(response.meta.preventModelsCaching) {
    return response
  }

  if(response.meta.isModelsCollection) {
    Object.entries(response.data).forEach(([modelName, models]) => {
      models.forEach(model => DataLayer.$store.set(modelName, model))
    });
  } else if(response.meta.model) {
    if(Array.isArray(response.data)) {
      response.data.forEach(model => DataLayer.$store.set(response.meta.model, model))
    } else {
      DataLayer.$store.set(response.meta.model, response.data)
    }
  }
  return response
}

function populateModels(response) {
  if(response.meta.preventPopulation) {
    return Promise.resolve({headers: response.headers, body: response.data})
  }

  let missingModels = [];

  if(response.meta.isModelsCollection) {
    Object.entries(response.data).forEach(([modelName, models]) => {
      models.forEach(model => missingModels.push.apply(missingModels, populateModel(model)))
    });
  } else if(response.meta.model) {
    if(Array.isArray(response.data)) {
      response.data.forEach(model => missingModels.push.apply(missingModels, populateModel(model)));
    } else {
      missingModels.push.apply(missingModels, populateModel(response.data))
    }
  }

  if(missingModels.length) {
    missingModels = missingModels.filter((model, index, self) => index === self.findIndex(mdl => mdl.link === model.link))
    return DataLayer.models.dispatch(JSON.stringify(missingModels.map(model => ({link: model.link}))))
      .then(() => populateModels(response))

  } else {
    return Promise.resolve({headers: response.headers, body: response.data})
  }
}

function populateModel(model, depth = 4) {
  const missingModels = []
  Object.entries(model).forEach(([key, prop]) => {
    if(prop && prop.model) {
      const cachedModel = DataLayer.$store.get(prop.model, prop.id);
      const propName = key.substring(0,key.length - 3);
      if (cachedModel) {
        if (!model[propName]) {
          Object.defineProperty(model, propName, {
            get: function() {
              return cachedModel;
            }
          });
        }
        depth && missingModels.push.apply(missingModels, populateModel(model[propName], --depth))
      } else {
        missingModels.push(prop);
      }
    }
  })
  return missingModels;
}
