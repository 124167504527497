<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<template>
  <BaseSection
    padded>
    <div
      :class="$style.message">
      <BaseIcon
        :class="$style.icon"
        name="flag"/>
      <h4
        v-if="error.title"
        :class="$style.messageTitle"
        v-text="error.title"/>
      <p
        v-if="error.paragraph"
        :class="$style.messageParagraph"
        v-text="error.paragraph"/>
    </div>
  </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

.icon {
  width: 5rem;
  fill: $c-white;
}

.message {
  max-width: 40rem;
  margin: 0 auto $s-xl;
  text-align: center;

  &Paragraph {
    max-width: 25rem;
    margin-top: -1rem;
    margin-right: auto;
    margin-bottom: $s-xxl;
    margin-left: auto;
    font-size: 16px;
    color: $c-text-faded;
  }

  &Title + &Paragraph {
    @extend %contentSubtitleL;

    color: $c-text-faded;
    margin-top: 0;
  }

  &Title {
    margin-top: 0;
    margin-bottom: $s-l;
  }
}
</style>
