<script>
import ShowOptions from '@components/show/show-options'

export default {
  components: {
    ShowOptions,
  },
  props: {
    show: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
}
</script>

<template>
  <div :class="$style.container">
    <div :class="$style.imageWrap">
      <div :class="$style.imageContainer">
        <BaseLink
          :to="{name: 'show', params: { id: show.id }}">
          <BaseResourceImage
            :resource="show"
            :class="$style.image"/>
        </BaseLink>
      </div>
      <div
        :class="$style.options"
        @click.prevent="()=>{}">
        <BaseDropdown :padded="false">
          <BaseButton
            slot="trigger"
            :modifiers="{ autoWidth: true, transparent: true, dots: true}"
            aria-label="Options"
            button-type="tag"
            size="small"/>
          <div slot="dropdown">
            <ShowOptions :show="show" />
          </div>
        </BaseDropdown>
      </div>
    </div>
    <BaseLink
      v-if="show && show.title"
      :class="['title', $style.title, $style.showLink]"
      :to="{name: 'show', params: { id: show.id }}">
      <div :class="$style.title">
        {{ show.title }}
      </div>
    </BaseLink>
    <div
      v-if="show.artist"
      :class="$style.artist">
      <BaseLink :to="{path: `/artist/${show.artist.url}/${show.artist.id}`}">
        {{ show.artist.title }}
      </BaseLink>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.options {
  z-index: $layer-dropdown-z-index;
  opacity: 0;
  transition: opacity .3s ease-in-out;

  @include center;
}

.imageWrap {
  position: relative;

  &::after {
    @include fill;

    content: '';
    background: $c-purple-red-gradient;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover {
    .options {
      opacity: 1;
    }
    &::after {
      opacity: 0.5;
    }
  }
}

.imageContainer {
  position: relative;
  height: 200px;
  overflow: hidden;
}
.image {
  @include center;

  width: 100%;
}

.title {
  @extend %contentTitleM;

  margin-top: $size-grid-padding;
  line-height: 24px;
  text-align: center;
}

.artist {
  @extend %contentSubtitleM;

  text-align: center;
}
</style>
