<script>

export default {
  props: {
    genre: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    alias() {
      return this.genre.name.replace(/\s+/g, '_').toLowerCase()
    }
  }
}
</script>

<template>
  <div :class="$style.container">
    <BaseLink
      v-if="genre"
      :to="{name: 'genre', params: { id: alias }}"
    >
      <BaseResourceImage
        :resource="genre"
        :class="$style.image"
        type="artwork"/>
      <div :class="$style.title">
        {{ genre.name }}
      </div>
    </BaseLink>
    <BasePlaceholder
      v-else
      :class="$style.title"
      :text="genre && genre.name"
      type="genre"
      tag="div"/>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.title {
  @extend %contentTitleM;

  margin-top: $size-grid-padding;
  line-height: 24px;
  text-align: center;
}
</style>
