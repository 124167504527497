<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: '',
    },
    active: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    setActive(option) {
      this.$emit('change', option)
      this.open = false
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <div
      :class="$style.toggle"
      @click="open = !open"
    >
      <div :class="$style.toggleMain">
        <div
          v-if="prefix"
          :class="$style.prefix"
        >{{ prefix }}</div>
        <div :class="$style.title">{{ active }}</div>
      </div>
      <div :class="$style.arrow">
        <BaseIcon
          v-if="open"
          :class="[$style.icon, $style.iconLess]"
          name="expand_less"
        />
        <BaseIcon
          v-else
          :class="[$style.icon, $style.iconMore]"
          name="expand_more"
        />
      </div>
    </div>
    <div
      v-show="open"
      :class="$style.options"
    >
      <div
        v-for="option in options"
        :key="option"
        :class="$style.option"
        @click="setActive(option)"
      >{{ option }}</div>
    </div>
  </div>
</template>

<style lang="scss" module>
@use "sass:math";
@import '@design';

.container {
  position: relative;

  @extend %contentSubtitleM;

  z-index: $layer-dropdown-z-index;
  background-color: $c-black;
}

.toggle {
  display: flex;
  align-items: stretch;
  height: math.div($s-h, 2);
  cursor: pointer;
  border: 1px solid $c-shark;
}

.toggleMain {
  display: flex;
  align-items: center;
  padding: $s-s;
}

.prefix {
  padding-right: $s-xs;
}

.title {
  font-size: 14px;
  color: $c-white;
}

.arrow {
  display: flex;
  align-items: center;
  padding: 0 $s-xs;
  border-left: 1px solid $c-shark;
}

.icon {
  @include square($s-l);

  fill: $c-highlight;
}

.options {
  position: absolute;
  bottom: 1px;
  width: 100%;
  padding: $s-s;
  font-size: 14px;
  color: $c-white;
  background-color: $c-black;
  border: 1px solid $c-shark;
  transform: translateY(100%);
}

.option {
  padding-bottom: $s-xs;
  cursor: pointer;

  &:last-child {
    padding-bottom: $s-xxs;
  }
}
</style>
