<script>
import DataLayer from '@services/data-layer/index'
import MixGridBlock from '@components/mix-grid-block'

export default {
  components: { MixGridBlock },
  data() {
    return {
      mixes: []
    }
  },
  mounted() {
    DataLayer.mixes.getTrending(12)
      .then(mixes => {
        this.mixes = mixes.body
    })
  },
}
</script>

<template>
  <div
    v-if="mixes && mixes.length"
    :class="$style.section">
    <BaseSection
      :show-all-link="{ name: 'browse/mixes/trending' }"
      title="Trending Mixes"
      show-all-title="Browse All"
      padded>
      <MixGridBlock :mixes="mixes" />
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
  margin-top: $s-xxl;
}
</style>
