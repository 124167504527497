<script>
import GenresList from '@components/genres-list'

export default {
  components: {GenresList},
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<template>
  <div :class="$style.list">
    <div
      v-for="(item, index) in items"
      :key="`recent-${item.id && item.id}${index}`"
      :class="[$style.listItem, item.type === 'genre' && $style.listItemGenre, item.type === 'artist' && $style.listItemArtist]">
      <div
        v-if="item.type !== 'genre'"
        :class="$style.listTile">
        <BaseLink :to="{name: item.type, params: {id: item.id}}">
          <BaseResourceImage
            :resource="item.type === 'mix' ? item.episode : item"
            :class="$style.image"
            :type="item.type === 'artist' ? 'photo' : 'album_art'"
          />
        </BaseLink>
        <div>
          <BaseLink :to="{name: item.type, params: {id: item.id}}">
            <div :class="$style.title">
              {{ item.type === 'mix' ? item.show.title : item.title }}
            </div>
          </BaseLink>
          <div :class="$style.subtitle">
            {{ item.type }}
          </div>
        </div>
      </div>
      <div
        v-else
        :class="$style.listTile">
        <GenresList :genres="[item.title]"/>
        <div :class="$style.subtitle">
          {{ item.type }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.list {
  margin-bottom: $s-m * 4;
}
.listTile {
  display: grid;
  grid-template-columns: 64px 100%;
  align-items: center;
  column-gap: $s-m;
}
.image {
  @include square(64px);

  overflow: hidden;
}
.subtitle {
  @extend %contentSubtitleM;

  text-transform: capitalize;
}
.listItem {
  &Artist {
    .image {
      border-radius: 50%;
    }
  }
  &Genre {
    .listTile {
      grid-template-columns: max-content auto;
      column-gap: 0;
      text-transform: capitalize;
    }
    .subtitle {
      margin-bottom: $s-m;
    }
  }
}
.listItem~.listItem {
  margin-top: $s-m * 2;
}
.title {
  @extend %contentTitleM;
}
</style>
