<script>
import Layout from '@layouts/login'
import localSave from './../../../utils/local-save'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'SetNewPassword',
  page() {
    const title = 'Set new password'
    const description = 'Set new password'
    return {
      title,
      meta: [
        { name: 'title', vmid: 'title', content: title },
        { name: 'description', vmid: 'description', content: description },

        { property: 'og:title', vmid: 'og:title', content: title },
        { property: 'og:description', vmid: 'og:description', content: description },

        { property: 'twitter:title', vmid: 'twitter:title', content: title },
        { property: 'twitter:description', vmid: 'twitter:description', content: description },
      ],
    }
  },
  components: { Layout },
  mixins: [validationMixin],
  data() {
    return {
      member: {
        id: '',
        password: '',
      },
      confirmPassword: '',
      checking: false,
    }
  },
  validations: {
    member: {
      password: {
        required
      },
    },
    confirmPassword: {
        required,
        sameAsPassword: sameAs(function() { return this.member.password }),
      }
  },
  computed: {
    membersId() {
      return Number(this.$route.query.members_id)
    },
    token() {
      return this.$route.query.token
    },
    memberEmail() {
      return this.$store.state.member.currentMember && this.$store.state.member.currentMember.email || null
    }
  },
  methods: {
    setNewPassword() {
      if(!this.$v.$invalid) {
        this.checking = true
        this.member.id = this.membersId
        return this.$dataLayer.api.resetPassword({
            member: this.member,
            token: this.token
        })
          .then(token => this._successHandler(token))
          .catch(_error => this._errorHandler())
      }else{
        this.checking = false
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('FIELDS_REQUIRED'), type: 'warning' })
        this.$v.$reset()
      }
    },
    _successHandler(_token) {
      this.checking = false
      this.$store.dispatch('appearance/handleNotification', { message: this.$m('NEW_PASSWORD_SUCCESS'), type: 'success' })
      this._redirectHandler()
    },
    _errorHandler() {
      this.checking = false
      this.$store.dispatch('appearance/handleNotification', { message: this.$m('SOMETHING_WENT_WRONG'), type: 'danger' })
    },
    _redirectHandler() {
      const redirectURL = localSave.get('redirectURL') || '/'
      this.$router.push({ path: redirectURL })
    }
  },
}
</script>

<template>
  <Layout>
    <BaseContent>
      <BaseSection padded>
        <div :class="$style.formContainer">
          <h4 :class="$style.title">Reset Password</h4>
          <h6 :class="$style.subtitle">Reset your password below</h6>
          <form
            :class="$style.form"
            @submit.prevent="setNewPassword"
          >
            <div
              v-if="memberEmail"
              :class="$style.fieldContainer"
            >
              <BaseInput
                v-model="memberEmail"
                :class="[$style.field, $style.email]"
                type="email"
                disabled
              />
            </div>
            <div :class="$style.fieldContainer">
              <BaseInput
                v-model="member.password"
                :class="[$style.field, $v.member.password.$error && $style.error]"
                type="password"
                placeholder="password*"
                @blur.native="$v.member.password.$touch()"
              />
              <label
                v-if="$v.member.password.$error"
                :class="[$style.label, $style.error]"
              >This field is required</label>
            </div>
            <div :class="$style.fieldContainer">
              <BaseInput
                v-model="confirmPassword"
                :class="[$style.field, $v.confirmPassword.$error && $style.error]"
                type="password"
                placeholder="Confirm password*"
                @blur.native="$v.confirmPassword.$touch()"
              />
              <label
                v-if="$v.confirmPassword.$error"
                :class="[$style.label, $style.error]"
              >This field is required and should match password above</label>
            </div>
            <BaseButton
              :class="$style.button"
              :disabled="checking"
              :modifiers="{ fill: true }"
              button-type="highlight"
              type="submit"
              @click="() => {}"
            >RESET PASSWORD</BaseButton>
          </form>
        </div>
      </BaseSection>
    </BaseContent>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.formContainer {
  position: relative;
  margin-bottom: $s-h;
  .field {
    &.error {
      margin-bottom: $s-xl;
      border-color: $c-punch-red;
      border-width: 2px;
    }
  }
}
.fieldContainer {
  position: relative;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label {
  @extend %contentSubtitleM;

  display: block;
  font-weight: 300;
  text-align: left;

  &.error {
    position: absolute;
    bottom: $s-xs;
    color: $c-punch-red;
  }
}
.title {
  text-align: left;
}
.subtitle {
  @extend %contentTinyHeading;

  font-weight: 400;
  text-align: left;
}

.button {
  margin-top: $s-xl;
}

.email {
  color: $c-maroon-flush;
}
</style>
