import axios from 'axios'

function buildApi(declarations) {
  Object.entries(declarations).forEach(([apiName, declaration]) => {
    this[apiName] = {}
    Object.entries(declaration).forEach(([method, execParams]) => {
      if (method[0] === '_') return

      if (!execParams.url || execParams.url[0] !== '/') {
        throw new Error(`Endpoint url should be set and should start with '/', given '${execParams.url}'.`)
      }
      this[apiName][method] = createEndpoint(
        execParams,
        execParams.method ? execParams.method : ['get', 'post', 'put', 'delete'].includes(method) ? method : 'get',
        {
          meta: {
            model: declaration._model,
            isModelsCollection: !!declaration._collection,
            preventModelsCaching: !!declaration._preventModelsCaching,
            preventPopulation: !!declaration._preventPopulation,
          },
        }
      ).bind(this)
    })
  })
}

function createEndpoint(execParams, httpMethod, meta) {
  return function(options, payload = null) {
    return this._axios[httpMethod](buildUrl(this._baseUrl, execParams, options), payload).then(response =>
      Object.assign(response, meta)
    )
  }
}

function buildUrl(baseUrl, execParams, options) {
  const url = execParams.url.replace(/\{(\w+)\}/g, (m, key, offset, url) => {
    if (!options.hasOwnProperty(key)) {
      throw new Error(`Missing required parameter '${key}' in url '${url}'.`)
    }
    let option = options[key]
    delete options[key]
    return option
  })

  const queryOptions = execParams.options
    ? Object.entries(options).reduce((queryOptions, [key, value]) => {
        if (execParams.options.includes(key)) {
          if(value || value === 0) {
            queryOptions.push(`${key}=${encodeURIComponent(value)}`);
          }
        } else {
          throw new Error(`Passed query string key '${key}' does not exists in declaration for '${execParams.url}'.`);
        }
        return queryOptions;
      }, [])
    : []

  return baseUrl + url + (queryOptions.length ? `?${queryOptions.join('&')}` : '');
}

export default class ApiFactory {
  constructor(baseUrl, declarations, token) {
    if (!baseUrl || baseUrl.substring(-1) === '/') {
      throw new Error(`Base url should be set and should not have '/' in the end, given '${baseUrl}'.`)
    }

    this._baseUrl = baseUrl
    this._axios = axios.create({
      baseURL: this._baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
      data: {},
    })

    buildApi.call(this, declarations)
  }
}
