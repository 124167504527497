<script>
import DataLayer from '@services/data-layer/index'
import PlaylistsGridBlock from '@components/playlists/playlists-grid-block'

export default {
  components: { PlaylistsGridBlock },
  data() {
    return {
      featuredPlaylists: [],
      showMoreData: {
        link: 'featured-playlists',
        title: 'Browse All'
      }
    }
  },
  mounted() {
    DataLayer.playlists.getByType({ type: 'editorial', featured: 1 })
      .then(playlists => {
        const featured = playlists.body.filter(playlist => playlist.revisions_id !== null)
        this.featuredPlaylists = featured
    })
  },
}
</script>

<template>
  <div
    v-if="featuredPlaylists && featuredPlaylists.length"
    :class="$style.section">
    <BaseSection
      title="Featured Playlists"
      padded>
      <PlaylistsGridBlock
        :playlists="featuredPlaylists"
        type="playlists"
        min-tile-width="160px"
      />
    </BaseSection>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
  margin-top: $s-xxl;
}
</style>
