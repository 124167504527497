<script>
import PlaylistsTableTile from '@components/playlists/playlists-table-tile'

export default {
  components: {
    PlaylistsTableTile,
  },
  props: {
    mixes: {
      type: Array,
      default: () => [],
    },
    genres: {
      type: Boolean,
      default: false,
    },
    isIndexedList: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Object,
      default: () => {}
    },
    error: {
      type: [Object, Boolean],
      default: false
    },
    shouldTransition: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      prevMixLength: 0
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
  },
  watch: {
    mixes(next, prev) {
      this.prevMixLength = prev.length
    },
  },
  methods: {
    playPlaylistMix(index) {
      if(!this.loggedIn) {
        this.$store.dispatch('appearance/handleNotification', { message: this.$m('PLAYER_LOGIN_SIGNUP'), type: 'warning' })
        return
      }
      this.$emit('playPlaylistMix', index)
    }
  }
}
</script>

<template>
  <div>
    <transition-group
      :name="shouldTransition ? 'slowfade' : 'no-transition'"
      tag="div">
      <PlaylistsTableTile
        v-for="(mix, index) in mixes"
        :mix="mix"
        :index="index"
        :key="'playlist' + mix.id + index"
        :genres="genres"
        :buttons="buttons"
        :is-indexed-list="isIndexedList"
        is-playlist-mix
        @play-playlist-mix="playPlaylistMix(index)"
      />
    </transition-group>

    <BaseEmptyList
      v-if="mixes.length === 0 && error"
      :error="error"/>
  </div>
</template>
